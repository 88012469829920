import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTeamSoftwareAction,
  deleteTeamSoftwareForMultipleTeamsAction,
  getDynamicTrackedSoftwareDataByTeamAction,
  updateLicencesAction,
  updateLicencesForMultipleTeamsAction,
} from '../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';

export const useSoftwareDropdown = (softwareData, teamId) => {
  const dispatch = useDispatch();
  
  const prevState = useSelector((state) => state.software);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => setIsModalOpen(false));
  const [initialDetails, setInitialDetails] = useState({});

  const toggleModal = useCallback((value) => {
    setIsModalOpen(value);
  }, []);

  const onDeleteSoftware = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage(`Are you sure you want to delete ${softwareData.name}?`);
      setValue('delete software');

      const onConfirm = () => {
        if (teamId) {
          dispatch(
            
            deleteTeamSoftwareAction({
              teamSoftwareId: softwareData.teamSoftwareId,
              softwareId: softwareData._id,
              teamId,
              prevState,
              
            })
            
          ).finally(() => {
            
            dispatch(getManagedTeamsAndMonthlyCostsAction());
          });
        } else {
          dispatch(
            
            deleteTeamSoftwareForMultipleTeamsAction({
              softwareId: softwareData._id,
              prevState,
              
            })
            
          ).finally(() => {
            
            dispatch(getManagedTeamsAndMonthlyCostsAction());
          });
        }
        toggleModal(false);
      };
      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, softwareData, teamId, prevState, toggleModal]
  );

  const onEditSoftware = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage(`Edit ${softwareData.name}`);
      setValue('edit software');
      setInitialDetails({
        tier: '0',
        paymentType: softwareData?.paymentData?.[0]?.paymentType,
      });

      const onConfirm = (updatedDetails) => {
        if (teamId) {
          dispatch(
            
            updateLicencesAction({
              teamSoftwareId: softwareData.teamSoftwareId,
              updatedDetails,
              softwareId: softwareData._id,
              teamId,
              prevState,
            })
            
          ).finally(() => {
            
            dispatch(getDynamicTrackedSoftwareDataByTeamAction());
          });
        } else {
          dispatch(
            
            updateLicencesForMultipleTeamsAction({
              updatedDetails,
              softwareId: softwareData._id,
              prevState,
            })
            
          ).finally(() => {
            
            dispatch(getDynamicTrackedSoftwareDataByTeamAction());
          });
        }
        toggleModal(false);
      };

      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, softwareData, teamId, prevState, toggleModal]
  );

  const dropdownOptions = [
    {
      value: 'delete software',
      onClick: onDeleteSoftware,
      icon: 'bx bx-trash',
    },
    {
      value: 'edit software',
      onClick: onEditSoftware,
      icon: 'bx bx-edit',
    },
  ];

  const pricesData = useMemo(
    () => ({
      prices: prevState.allDynamicSoftwareData?.[softwareData._id]?.prices,
      tierToName: softwareData?.tierToName,
    }),
    [prevState.allDynamicSoftwareData, softwareData]
  );

  return {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    initialDetails,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
    pricesData,
  };
};
