import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getMemberElements } from '../../services/teams/format';
import { getMetric } from '../../util/UtilFunctions';
import AddUsersModal from '../Modals/Add User/AddUsersModal';
import TableContainer from '../Tracked Software/TableContainer';
import AddMemberCard from '../UI/AddMemberCard';
import MemberCard from '../UI/MemberCard';

export default function TeamOverview(props) {
  const { team, onNextTeam, onPrevTeam } = props;

  
  const { user: manager, userData } = useSelector((state) => state.user);
  const trackedSoftwareIdsByTeam = useSelector(
    (state) => state.software.trackedSoftwareIdsByTeam
  );

  const members = team?.users?.map((user) => userData[user?.userId]);

  const [isSelected, setIsSelected] = useState(false);
  const [elementShow, setElementShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleAddUsersModal = (value) => {
    setIsModalOpen(value);
  };

  const renderElements = () => {
    const elements = getMemberElements(members);

    return elements.map((element, index) => {
      return (
        <div
          key={index}
          className={'element' + (element?.isMember ? ' member' : '')}
          style={element?.style}
        >
          {element?.isMember && element?.member && (
            <div
              className='user-image'
              style={{
                backgroundImage: element?.member?.imageUrl
                  ? `url(${element?.member?.imageUrl})`
                  : `url(/avatars/${(index % 7) + 1}.jpg)`,
              }}
            />
          )}
        </div>
      );
    });
  };

  const renderWheel = () => {
    const radius = 65;
    const costsPercentage = team?.costsPercentage || 0;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (costsPercentage / 100) * circumference;

    return (
      <div className='container'>
        <div className='percentage-metric'>
          <div className='title'>
            <h4>All costs</h4>
            <h3>
              {costsPercentage} <span>%</span>
            </h3>
          </div>
          <svg>
            <circle
              id='track'
              cx='75'
              cy='75'
              r={radius}
              strokeDasharray={circumference}
              strokeDashoffset={0}
            ></circle>
            <circle
              id='progress'
              cx='75'
              cy='75'
              r={radius}
              strokeDasharray={circumference}
              strokeDashoffset={offset}
            ></circle>
          </svg>
        </div>
        <div className='explanation'>{team?.name}</div>

        <div className='orbits-container'>
          <div
            className={'orbits' + (isSelected ? ' clicked' : '')}
            onClick={() => setIsSelected(!isSelected)}
          >
            {renderElements()}
          </div>
        </div>
      </div>
    );
  };

  const handleRenderDetails = () => {
    if (elementShow) {
      return renderDetails();
    }

    return renderOverview();
  };

  const renderDetails = () => {
    if (elementShow) {
      return renderMembersList();
    }

    return (
      <>
        <div
          className='team-stats'
          style={{ display: 'block' }}
        >
          <div
            className='stat go-back'
            onClick={() => setElementShow(false)}
          >
            <p className='show-more'>
              <i className='bx bxs-chevron-left' /> Go back
            </p>
          </div>
        </div>
        <div style={{ paddingTop: '2rem' }}>
          <TableContainer
            headerData={[
              'Software',
              'Active Users',
              'Manager',
              'Expenses (last 31 days)',
              'Billing',
            ]}
          />
        </div>
      </>
    );
  };

  const renderMembersList = () => {
    return (
      <>
        <div
          className='team-stats'
          style={{ display: 'block' }}
        >
          <div
            className='stat go-back'
            onClick={() => setElementShow(false)}
          >
            <p className='show-more'>
              <i className='bx bxs-chevron-left' /> Go back
            </p>
          </div>
        </div>
        <div className='members-cards-container scroll'>
          <AddMemberCard onClick={() => toggleAddUsersModal(true)} />
          {members.map((member) => {
            return (
              <MemberCard
                key={member?.userId}
                member={member}
              />
            );
          })}
        </div>
      </>
    );
  };

  const renderNextPrevious = (isNext) => {
    const callback = isNext ? onPrevTeam : onNextTeam;

    return (
      <div
        className='previous'
        onClick={callback}
      >
        <div className={`button-container ${isNext ? 'reversed' : ''}`}>
          <i className={`bx bxs-chevron-${isNext ? 'right' : 'left'}`} />
          <p>{isNext ? 'Next' : 'Previous'}</p>
          <p className='dot'>•</p>
        </div>
      </div>
    );
  };

  const renderOverview = () => {
    return (
      <div className='team-stats'>
        <div className='stat'>
          <h3>
            <span className='currency'>€</span>
            <span>{getMetric(team?.monthlyCosts)?.first}</span>
            <span className='secondary'>
              {getMetric(team?.monthlyCosts)?.second}
            </span>
          </h3>
          <p>
            All monthly software subscriptions <span>costs</span> for the team.
          </p>
          <p
            className='show-more'
            onClick={() => setElementShow(true)}
          >
            Show more <i className='bx bxs-chevron-right' />
          </p>
        </div>

        <div className='stat'>
          <h3>
            <span>{team?.costsPercentage || 0}</span>
            <span className='secondary'>%</span>
          </h3>
          <p>
            Software <span>monthly costs percentage</span> from all software
            costs.
          </p>
          <p
            className='show-more'
            onClick={() => setElementShow(true)}
          >
            Show more <i className='bx bxs-chevron-right' />
          </p>
        </div>

        <div className='stat'>
          <h3>
            <span>{trackedSoftwareIdsByTeam[team?._id]?.length || 0}</span>
          </h3>
          <p>
            <span>Software</span> used by this team.
          </p>
          <p
            className='show-more'
            onClick={() => setElementShow(true)}
          >
            Show more <i className='bx bxs-chevron-right' />
          </p>
        </div>

        <div className='stat'>
          <h3>
            <span>{members?.length}</span>
          </h3>
          <p>
            <span>Member(s)</span> on this team.
          </p>
          <p
            className='show-more'
            onClick={() => setElementShow(true)}
          >
            Show more <i className='bx bxs-chevron-right' />
          </p>
        </div>
      </div>
    );
  };

  const renderCard = () => {
    return (
      <div className='container team-container'>
        <div className='title-label'>Overview</div>
        <div className='title'>
          {team?.name} <span>Team</span>
        </div>
        <div className='team-row'>
          <div className='manager-container'>
            <div className='manager'>
              <div
                className='user-image'
                style={{
                  backgroundImage: manager?.imageUrl ? `url(${manager?.imageUrl})` : '',
                }}
              />
              <div className='name-titles'>
                <h4>
                  Team Manager <i className='bx bxs-star' />
                </h4>
                <h5>{manager?.name}</h5>
              </div>
            </div>
          </div>

          {handleRenderDetails()}
          <div className='next-team-buttons-container'>
            {onPrevTeam && renderNextPrevious(false)}
            {onNextTeam && renderNextPrevious(true)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className='dashboard-grid team-overview'>
        {renderWheel()}
        {renderCard()}
      </div>
      {isModalOpen && (
        <AddUsersModal
          toggleModal={toggleAddUsersModal}
          teamId={team._id}
        />
      )}
    </section>
  );
}
