import { debounce } from '../../util/debounce';
import { updateLocalStorageState } from '../../util/localStorage';
import { updateUserWidgetArrayAction } from '../features/widgets/widgetThunks';

const updateWidgetOrderDebounceTime =
  Number(process.env.REACT_APP_DEBOUNCE_WIDGET_ORDER_TIME) || 1000;

const debouncedLocalStorageUpdate = debounce((state) => {
  localStorage.setItem('widgets', JSON.stringify(state.widgets.widgets));
}, updateWidgetOrderDebounceTime);

const handleWidget = (state, action) => {
  const actionTypes = ['getAllUserWidgets', 'addWidget', 'deleteWidget'].map(
    (actionType) => `widgets/${actionType}/fulfilled`
  );

  actionTypes.push(...['widgets/setWidgets']);

  if (actionTypes.includes(action.type)) {
    debouncedLocalStorageUpdate(state);
  }

  if (action.type === 'widgets/getWidgetDataByType/fulfilled') {
    const { type } = action.payload;

    const newData = { [type]: action.payload };

    updateLocalStorageState('widgetData', newData);
  }
};

export const widgetLocalStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  handleWidget(state, action);

  return result;
};

const debouncedUpdateWidgetOrder = debounce((dispatch, { newWidgets }) => {
  
  dispatch(updateUserWidgetArrayAction({ newWidgets }));
}, updateWidgetOrderDebounceTime);

const handleUpdateWidgetOrder = (store, action) => {
  const actionTypes = ['widgets/setWidgets'];

  if (actionTypes.includes(action.type)) {
    const newWidgets = action.payload;

    debouncedUpdateWidgetOrder(store.dispatch, { newWidgets });
  }
};

export const updateWidgetOrderMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  handleUpdateWidgetOrder(store, action);

  return result;
};

export default updateWidgetOrderMiddleware;
