import mergeObjects from '../../util/mergeObjects';

const updateTeamStateData = (state, payload) => {
  const { teamData } = state;

  Object.assign(state, payload);

  state.teamData = mergeObjects(teamData, payload.teamData || {});
};

export default updateTeamStateData;
