import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getWidgetDataByTypeAction } from '../../redux/features/widgets/widgetThunks';
import { getSubscriptionData } from '../../util/UtilFunctions';

function SpendingWidget(props) {
  const { widget, onDelete } = props;
  const { type } = widget;

  
  const { widgetData } = useSelector((state) => state.widgets);
  const subscriptionData = widgetData[type] ?? getSubscriptionData();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownOptions = [
    {
      value: 'delete',
      onClick: onDelete,
      icon: 'bx bx-trash',
    },
  ];

  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  useEffect(() => {
    console.log('fetching widget data subscription');
    dispatch(getWidgetDataByTypeAction(type));
  }, [dispatch, type]);

  return (
    <div className='card'>
      <div className='card-header'>
        <div
          className='icon'
          style={{ backgroundImage: `url(${subscriptionData?.icon})` }}
        />
        <div className='text'>
          <div className='title'>{subscriptionData?.title}</div>
          <div className='subtitle'>{subscriptionData?.subtitle}</div>
        </div>
        <i
          ref={iconRef}
          className='bx bx-dots-horizontal options pointer'
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className='dropdown-menu widget'
            ref={dropdownRef}
          >
            {dropdownOptions.map((option) => {
              return (
                <div
                  key={crypto.randomUUID()}
                  className='dropdown-item'
                  onClick={option?.onClick}
                >
                  <i className={option?.icon} />
                  {option?.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className='card-content'>
        <div className='metric'>
          <span>{subscriptionData?.metric?.value}</span>{' '}
          {subscriptionData?.metric?.label}
        </div>
        <div className='label'>
          <div
            className={subscriptionData?.isRateGreen ? 'rate green' : 'rate'}
          >
            <i className='bx bx-trending-down'></i>
            {subscriptionData?.rate}
          </div>
          <div className='average'>{subscriptionData?.average}</div>
        </div>
      </div>
      <div className='card-footer'>
        <Link to={subscriptionData?.linkToReport}>View Report</Link>
        <i className='bx bxs-chevron-right-circle'></i>
      </div>
    </div>
  );
}

export default React.memo(SpendingWidget);
