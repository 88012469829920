import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamsOverviewContainer from '../../containers/TeamsOverviewContainer';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';
import { getMultipleUserDataAction } from '../../redux/features/user/userThunks';
import BudgetAnalysis from '../Sections/BudgetAnalysis';
import TeamOverview from '../Sections/TeamOverview';
import TrackedSoftware from '../Tracked Software/TrackedSoftware';
import UsersTable from './UsersTable';

export default function TeamComponent(props) {
  const dispatch = useDispatch();

  const { teamName } = props;

  const { teamData, teamSlugToId, userIds } = useSelector(
    
    (state) => state.teams
  );

  const [isLoading, setIsLoading] = useState(true);

  const teamId = useMemo(
    () => teamSlugToId[teamName],
    [teamSlugToId, teamName]
  );

  const team = useMemo(() => teamData[teamId], [teamData, teamId]);

  const { userData, allCostsData, costsDataByTeam } = useSelector(
    
    (state) => state.user
  );

  const users = useMemo(() => {
    if (teamName) {
      return team?.users
        .map(({ userId }) => {
          const costData = {};

          console.log('costsDataByTeam?.[teamId]?.[userId]', costsDataByTeam?.[teamId]?.[userId])
          if (costsDataByTeam?.[teamId]?.[userId]) {
            costData.monthlyCosts =
              costsDataByTeam[teamId][userId].monthlyCosts;
            costData.activeSoftwareCount =
              costsDataByTeam[teamId][userId].activeSoftwareCount;
            costData.totalSoftwareCount =
              costsDataByTeam[teamId][userId].totalSoftwareCount;
          } else {
            costData.monthlyCosts = 0;
            costData.activeSoftwareCount = 0;
            costData.totalSoftwareCount = 0;
          }

          return {
            ...userData[userId],
            ...costData,
          };
        })
        .filter(Boolean);
    }
    return userIds
      .map((userId) => {
        const costData = {};

        if (allCostsData[userId]) {
          costData.monthlyCosts = allCostsData[userId].monthlyCosts;
          costData.activeSoftwareCount =
            allCostsData[userId].activeSoftwareCount;
          costData.totalSoftwareCount = allCostsData[userId].totalSoftwareCount;
        } else {
          costData.monthlyCosts = 0;
          costData.activeSoftwareCount = 0;
          costData.totalSoftwareCount = 0;
        }

        return {
          ...userData[userId],
          ...costData,
        };
      })
      .filter(Boolean);
  }, [
    teamName,
    team,
    teamId,
    userIds,
    userData,
    costsDataByTeam,
    allCostsData,
  ]);

  useEffect(() => {
    if (userIds?.length) {
      
      dispatch(getMultipleUserDataAction(userIds));
    }
  }, [dispatch, userIds]);

  useEffect(() => {
    
    dispatch(getManagedTeamsAndMonthlyCostsAction()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  const renderUsers = () => {
    const text = teamId ? `${team?.name} Team Members` : 'All Team Members';

    return (
      <section className='application-page'>
        <div className='titles-container'>
          <h3>{text}</h3>
        </div>

        <UsersTable
          users={users}
          teamId={teamId}
          isLoading={isLoading}
        />
      </section>
    );
  };

  if (teamName && Object.keys(teamSlugToId).length && !teamId) {
    return <h1>No such team here</h1>;
  }

  return (
    <>
      <section>
        {teamName && (
          <div className='titles-container'>
            <h2>
              <span>Team Overview</span>
            </h2>
            <h4>
              Find details about this team and its costs. Onboard team members
              and applications to it.
            </h4>
          </div>
        )}
      </section>
      {teamName ? (
        <TeamOverview team={team} />
      ) : (
        <TeamsOverviewContainer displayTitle={false} />
      )}
      {renderUsers()}

      <div className='titles-container team-component-section'>
        <h3>Tracked Software</h3>
        <TrackedSoftware teamId={teamId} />
      </div>
      <BudgetAnalysis
        title='Team Budget Analysis'
        subtitle='Overview of the application spendings.'
      />
    </>
  );
}
