import React, { useEffect, useState } from 'react'
import { getPreviewInvoice } from '../../../services/invoice/format';
import SoftwareDropdown from '../../Invoices/SoftwareDropDown';
import TeamSelection from '../Add Software/TeamSelectionStep';
import TeamsSelectionComponent from '../../Teams/TeamsSelection';
import { formatDate, formatDateTime } from '../../../util/formatDate';
import { confirmInvoiceAndUpdateAction, getExistingTeamsAction } from '../../../redux/features/invoice/invoiceThunks';
import { useDispatch } from 'react-redux';
import SoftwareDropDown from '../../Invoices/SoftwareDropDown';
import SelectInput from '../../UI/SelectInput';

export default function InvoiceConfirmation(props) {
    const { invoice, toggleModal, showAlert } = props;
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [initialSelectedTeams, setInitialSelectedTeams] = useState([]);
    const [invoiceData, setInvoiceData] = useState({ ...invoice });
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (invoiceData?.software) {
            initTeamsSelection();
            let softwareId = invoiceData?.software;
            if (invoiceData?.software?._id) {
                softwareId = invoiceData?.software?._id
            }

            updateSelectedSoftware(invoiceData?.software)
            handleGetExistingTeams(softwareId)
        }
    }, [])

    const handleTeamSelect = (teamId) => {
        setSelectedTeams((prevSelectedTeams) => {
            if (prevSelectedTeams.includes(teamId)) {
                return prevSelectedTeams.filter((id) => id !== teamId);
            } else {
                return [...prevSelectedTeams, teamId];
            }
        });
    };

    const handleChange = (e) => {
        setInvoiceData((prevInvoiceData) => ({
            ...prevInvoiceData,
            [e?.target?.name || e?.name]: e?.target?.value || e?.value
        }));
    }

    const initTeamsSelection = () => {
        setSelectedTeams([]);
        setInitialSelectedTeams([]);
    }

    const updateSelectedSoftware = (software) => {
        if (!software || !software?._id)
            return;

        initTeamsSelection();
        handleGetExistingTeams(software?._id)

        setInvoiceData((prevInvoiceData) => ({
            ...prevInvoiceData,
            software: software?._id,
            softwareData: software
        }));
    }

    const handleGetExistingTeams = (softwareId) => {
        dispatch(getExistingTeamsAction({ softwareId }))
            .then((result) => {
                if (result?.payload) {
                    const teamsArray = result.payload;

                    if (teamsArray && teamsArray.length) {
                        const teamIds = teamsArray
                            .filter(team => team && team.teamId)
                            .map(team => team.teamId);

                        setSelectedTeams((prevSelectedTeams) => [...prevSelectedTeams, ...teamIds]);
                        setInitialSelectedTeams((prevInitialSelectedTeams) => [...prevInitialSelectedTeams, ...teamIds]);
                    }
                } else {
                    showAlert(result?.message || 'Error finding teams that have the software added', 'error');
                }
            })
            .catch((error) => {
                console.error(error);
                showAlert(error.message || 'An error occurred while looking for teams that have this software', 'error');
            });
    }

    const handleConfirm = () => {
        if (isSaving)
            return;

        if (!invoiceData)
            return showAlert('No invoice data found to update', 'error');

        if (!invoiceData?.software)
            return showAlert('Software is required for the invoice data to be confirmed', 'error');

        if (!selectedTeams?.length)
            return showAlert('You need to select a team to apply this invoice software to', 'error');

        setIsSaving(true)
        let invoiceId = invoice?._id;
        invoiceData.teams = selectedTeams;

        dispatch(confirmInvoiceAndUpdateAction({ invoiceId, invoiceData }))
            .then((result) => {
                setIsSaving(false)
                if (result?.payload?.updatedInvoice) {
                    showAlert('Invoice updated successfully!', 'success');
                    toggleModal()
                } else {
                    showAlert(result?.message || 'Failed to update the invoice', 'error');
                }
            })
            .catch((error) => {
                console.error(error);
                setIsSaving(false)
                showAlert(error.message || 'An error occurred while updating the invoice', 'error');
            });
    };

    const renderInvoiceDetailsForm = () => {
        return (
            <form>
                <div className='form-input-container'>
                    <label htmlFor='name'>Invoice Name</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        className='text-input'
                        placeholder='Your invoice file name'
                        value={invoiceData?.name}
                        onChange={handleChange}
                    />
                </div>
                <div className='double-input'>
                    <div className='form-input-container' style={{ width: '173px' }}>
                        <label htmlFor='name'>Invoice Value</label>
                        <input
                            type='number'
                            name='value'
                            className='text-input'
                            placeholder='Value of the invoice'
                            onChange={handleChange}
                            value={invoiceData?.value || 0}
                            autoFocus
                        />
                    </div>
                    <div className='form-input-container' style={{ width: '66px' }}>
                        <label htmlFor='name'>Currency</label>

                        <SelectInput
                            value={invoiceData?.currency || '€'}
                            placeholder={'currency'}
                            options={['€', '$']}
                            onChange={handleChange}
                            name={'currency'}
                        />

                    </div>
                </div>
                <div className='form-input-container'>
                    <label htmlFor='name'>Detected Software</label>
                    <SoftwareDropDown invoice={invoiceData} fromPreview={true} updateSelectedSoftware={updateSelectedSoftware} />
                </div>

                <div className='form-input-container'>
                    <label htmlFor='name'>Due Date</label>
                    <input
                        type='date'
                        value={formatDateTime(invoiceData?.dueDate)}
                        className='text-input'
                        name='dueDate'
                        onChange={handleChange}
                    />
                </div>

                {/*<div className='form-input-container'>
                    <label htmlFor='name'>Value per licence/seat (optional)</label>
                    <input
                        type='number'
                        id='name'
                        name='licenceValue'
                        className='text-input'
                        placeholder='Value of a single licence or seat'
                        value={invoiceData?.licenceValue}
                        onChange={handleChange}
                    />
                </div>*/}

                <div className='form-input-container'>
                    <label htmlFor='name'>Billing type</label>
                    <SelectInput
                        placeholder={'Select type of billing for the invoice'}
                        options={[
                            {
                                name: 'Monthly',
                                value: '1/1'
                            },
                            {
                                name: 'Quarterly',
                                value: '1/3'
                            },
                            {
                                name: 'Semester',
                                value: '1/6'
                            },
                            {
                                name: 'Annual',
                                value: '1/12'
                            }
                        ]}
                        name={'billingType'}
                        onChange={handleChange}
                        value={invoiceData?.billingType}
                    />
                </div>


                <div className='form-input-container'>
                    <label htmlFor='name'>Select a team the software will be added to</label>
                    <label style={{
                        fontSize: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        opacity: .8,
                        width: '90%'
                    }}><i class='bx bx-info-circle' style={{ fontSize: '13px' }} ></i> Please note that the invoice price value will be divided on the teams members for the individual stats but will remain the same in the application page.</label>
                    <TeamsSelectionComponent selectedTeams={selectedTeams} handleTeamSelect={handleTeamSelect} initialSelectedTeams={initialSelectedTeams} />
                </div>
            </form>
        )
    }

    return (
        <div className='modal-group'>
            <div
                className='modal-overlay'
                onClick={() => toggleModal()}
            />

            <div className='modal-container preview'>
                <div className='modal'>
                    <div className='titles scroll-shadows'>
                        <h3>Confirm your invoice data</h3>
                        <p>We retrieved this data from the invoice. Please confirm or update it so that it can be added.</p>
                    </div>

                    <div
                        className='modal-content add-team-modal'
                        style={{ overflow: 'hidden' }}
                    >
                        <div className='half scrollable h110'>
                            {renderInvoiceDetailsForm()}
                        </div>
                        <div className='half'>
                            <img src={getPreviewInvoice(invoice)} />
                        </div>
                    </div>

                    <div className='next-btn-container'>
                        <button
                            className='next-btn previous'
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </button>
                        <button
                            className={'next-btn' + (isSaving ? ' disabled' : '')}
                            onClick={() => handleConfirm()}
                        >
                            {isSaving ? 'Saving...' : 'Confirm and Save Invoice'}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}
