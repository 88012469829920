import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/widget';

export const getAllUserWidgets = async () => {
  try {
    const response = await axios.get(`${prefix}/all`);

    if (response.data?.length === 0) {
      return [];
    }

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getWidgetById = async (widgetId) => {
  try {
    const response = await axios.get(`${prefix}/${widgetId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getWidgetDataByType = async (type) => {
  try {
    console.log('type', type)
    const response = await axios.get(`${prefix}/getData/${type}`);
    if (type === 'subscription') {
      console.log('getWidgetDataByType', type, response);
    }

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
