import React from 'react';

export default function SignupPopup(props) {
  const { registrationSuccess, animationKey, message } = props;

  return (
    registrationSuccess && (
      <div
        key={animationKey}
        className='success-message'
      >
        {message ||
          'An invite has been sent to your email address. Please check your inbox.'}
      </div>
    )
  );
}
