export const getTimestamp = (timestampISO) => {
  const timestamp = new Date(timestampISO).getTime();
  const now = Date.now();
  const diff = now - timestamp;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  const pluralize = (value, unit) =>
    `${value} ${unit}${value !== 1 ? 's' : ''} ago`;

  let relativeTime;
  let showExactTime = false;
  let showExactDay = false;

  if (seconds < 60) {
    relativeTime = pluralize(seconds, 'second');
    showExactTime = true;
  } else if (minutes < 60) {
    relativeTime = pluralize(minutes, 'minute');
    showExactTime = true;
  } else if (hours < 24) {
    relativeTime = pluralize(hours, 'hour');
    showExactTime = true;
  } else if (days < 7) {
    relativeTime = pluralize(days, 'day');
    showExactDay = true;
  } else if (weeks < 4) {
    relativeTime = pluralize(weeks, 'week');
  } else if (months < 12) {
    relativeTime = pluralize(months, 'month');
  } else {
    relativeTime = pluralize(years, 'year');
  }

  const exactTime = new Date(timestampISO).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  const exactDay = new Date(timestampISO).toLocaleDateString([], {
    weekday: 'long',
  });

  if (showExactTime) {
    return `${relativeTime} • ${exactTime}`;
  } else if (showExactDay) {
    return `${relativeTime} • ${exactDay}, at ${exactTime}`;
  } else {
    return relativeTime;
  }
};

export default getTimestamp;
