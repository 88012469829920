import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
export default function SideMenu(props) {
  const navigate = useNavigate();

  const menuElements = [
    {
      name: 'overview',
      elements: [
        {
          linkTo: '/dashboard',
          icon: 'dashboard',
          name: 'dashboard',
        },
        {
          linkTo: '/dashboard/analytics',
          icon: 'analytics',
          name: 'analytics',
        },
        {
          linkTo: '/dashboard/applications',
          icon: 'bx bxs-collection',
          name: 'applications',
        },
      ],
    },
    {
      name: 'management',
      elements: [
        {
          linkTo: '/dashboard/invoices',
          icon: 'invoices',
          name: 'Invoices and documents',
        },
        {
          linkTo: '/dashboard/teams',
          icon: 'team-on-boarding',
          name: 'Team onboarding',
        },
        {
          linkTo: '/dashboard/connect',
          icon: 'connect-providers',
          name: 'connect providers',
        },
        {
          linkTo: '/dashboard/financial-data',
          icon: 'bx bx-credit-card-alt',
          name: 'financial data',
        },
      ],
    },
  ];

  
  const { user } = useSelector((state) => state.user);
  
  const { company } = useSelector((state) => state.company);

  const location = useLocation();

  // Function to determine if the route is active
  const isActive = (pathname) => {
    return location.pathname === pathname ? 'active' : '';
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const renderUser = (user) => {
    if (!user) return;

    const handleUserClick = () => {
      navigate('/dashboard/profile/' + user?._id);
    };

    return (
      <>
        <div
          className='user-image menu'
          style={{
            backgroundImage: user?.imageUrl ? `url(${user?.imageUrl})` : '',
          }}
          onClick={handleUserClick}
        >
          <i className='icon bx bx-chevron-down'></i>
        </div>
        <div className='user-info'>
          <div className='user-name'>{user?.name}</div>
          <div className='user-position'>
            {user?.position ?? user?.email}
            <br />
          </div>
        </div>
        <i className='icon options'></i>
        <div className='user-cursor' />
      </>
    );
  };

  const renderMenuSections = (menuElements) => {
    if (!menuElements.length) return;
    return menuElements.map((section, index) => {
      return (
        <div
          key={index}
          className='section'
        >
          <div className='section-title'>{section.name}</div>
          {renderMenuElements(section.elements)}
        </div>
      );
    });
  };

  const renderMenuElements = (elements) => {
    if (!elements.length) return;
    return elements.map((element, index) => {
      return (
        <Link
          to={element.linkTo}
          className={`${isActive(`${element.linkTo}`)} menu-element`}
          key={index}
          onClick={() => props.setMenuOpen(false)}
        >
          <i className={`icon ${element.icon}`}></i>
          <div className='text'>{element.name}</div>
          <span className='notification-dot'></span>
        </Link>
      );
    });
  };

  return (
    <nav className={'side-menu ' + (props.isMenuOpen ? 'open' : '')}>
      <div className='logo'>
        <div className='logo-image'></div>
        <p className='logo-name'>Reknah</p>
      </div>
      <div className='user'>{renderUser(user)}</div>
      <div className='elements-menu-container'>
        {renderMenuSections(menuElements)}
        <div className='section menu-footer'>
          <Link
            to='settings'
            className={`${isActive('settings')} menu-element`}
          >
            <i className='icon settings'></i>
            <div className='text'>Settings</div>
            <span className='notification-dot'></span>
          </Link>
          <Link
            to='settings'
            className={`${isActive('help')} menu-element`}
            onClick={logout}
          >
            <i className='icon question-mark'></i>
            <div className='text'>Get Help</div>
            <span className='notification-dot'></span>
          </Link>
        </div>
      </div>
    </nav>
  );
}
