import { createSlice } from '@reduxjs/toolkit';
import { addUsersToTeamAction } from '../teams/teamsThunks';
import {
  addMultipleSoftwareToMultipleTeamsAction,
  addTeamAction,
} from './responseThunks';

const initialState = {
  info: {},
  warning: {},
  error: {},
};

const responseSlice = createSlice({
  name: 'response',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        addMultipleSoftwareToMultipleTeamsAction.fulfilled,
        (state, action) => {
          const { info, warning } = action.payload;
          state.info['addMultipleSoftwareToMultipleTeams'] = info;
          state.warning['addMultipleSoftwareToMultipleTeams'] = warning;
        }
      )
      .addCase(
        addMultipleSoftwareToMultipleTeamsAction.rejected,
        (state, action) => {
          state.error['addMultipleSoftwareToMultipleTeams'] = action.payload;
        }
      )
      .addCase(addTeamAction.fulfilled, (state, action) => {
        const { info } = action.payload;
        state.info['addTeam'] = info;
      })
      .addCase(addTeamAction.rejected, (state, action) => {
        state.error['addTeam'] = action.payload;
      })
      .addCase(addUsersToTeamAction.fulfilled, (state, action) => {
        const { info, warning } = action.payload;
        state.info['addUsersToTeam'] = info;
        state.warning['addUsersToTeam'] = warning;
      })
      .addCase(addUsersToTeamAction.rejected, (state, action) => {
        state.error['addUsersToTeam'] = action.payload;
      }),
});

export default responseSlice.reducer;
