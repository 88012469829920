import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getAllFoldersAction } from '../../redux/features/folder/folderThunks';
import {
  addInvoiceAction,
  deleteInvoiceAction,
  getAllInvoicesAction,
  getRecentInvoicesAction,
  updateInvoiceNameAction,
} from '../../redux/features/invoice/invoiceThunks';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
} from '../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';
import { debounce } from '../../util/debounce';
import AddFolderComponent from './AddFolderComponent';
import FolderComponent from './FolderComponent';
import InvoiceGrid from './InvoiceGrid';
import InvoiceTable from './InvoiceTable';
import PreviewFile from '../Modals/Invoices/PreviewFile';
import { useAlert } from '../../providers/AlertProvider';
import { useActionHandler } from '../../hooks/useActionHandler';

const itemsPerPage = 8;

const fakeFiles = Array.from({ length: itemsPerPage }, (_, index) => ({
  _id: index,
  name: '',
  licences: [],
  value: null,
  createdAt: '',
  software: { _id: '' },
}));

const fakeFolders = Array.from({ length: 3 }, (_, index) => ({
  _id: index,
  name: '',
  files: [],
  createdAt: '',
}));

export default function FolderPageComponent() {
  const dispatch = useDispatch();

  const { folderId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const {
    invoices: files,
    currentPage,
    totalPages,
    
  } = useSelector((state) => state.invoices);

  
  const { folders, folderIdToName } = useSelector((state) => state.folders);

  const displayedFiles = useMemo(() => {
    return isLoading ? fakeFiles : files;
  }, [files, isLoading]);

  const folderName = useMemo(() => {
    return folderIdToName[folderId] || '';
  }, [folderId, folderIdToName]);

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const { showAlert } = useAlert();
  const handleAction = useActionHandler(); 

  const displayedFolders = useMemo(() => {
    return folders.length > 0 ? folders : fakeFolders;
  }, [folders]);

  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    
    dispatch(getAllFoldersAction());
    
    dispatch(getRecentInvoicesAction({ limit: 20 }));
    
    dispatch(
      
      getAllInvoicesAction({ page, limit: itemsPerPage, folder: folderId })
      
    ).finally(() => {
      setIsLoading(false);
    });
    
    dispatch(getTrackedSoftwareDataAction());
    
    dispatch(getDynamicTrackedSoftwareDataByTeamAction());
    
    dispatch(getManagedTeamsAndMonthlyCostsAction());
  }, [page, dispatch, folderId]);

  const [isFocused, setIsFocused] = useState(false);
  const [isGrid, setIsGrid] = useState(true);

  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [editValue, setEditValue] = useState('');

  const toggleDropdown = (id) => {
    setVisibleDropdown(visibleDropdown === id ? null : id);
  };

  const handleDelete = (id) => {
    const action = deleteInvoiceAction({
      invoiceId: id,
      prevState: {
        invoices: files,
      },
    });
  
    handleAction(
      action,
      'Invoice successfully deleted',
      'Error deleting invoice'
    );
  
    setVisibleDropdown(null);
  };

  const handleEdit = (id, name) => {
    setIsEditing(id);
    setEditValue(name);
    setVisibleDropdown(null);
  };

  const handleSave = (id) => {
    
    dispatch(updateInvoiceNameAction({ invoiceId: id, name: editValue }));
    setIsEditing(null);
    setVisibleDropdown(null);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const renderAddInvoice = () => {
    return (
      <div className='option'>
        <div>
          <i className='bx bx-plus' />
          <p>New Invoice</p>
        </div>
        <p>Create your own invoices to send to your clients.</p>
      </div>
    );
  };

  const debouncedDispatch = useCallback(
    debounce((searchValue) => {
      dispatch(
        
        getAllInvoicesAction({
          page: 1,
          limit: itemsPerPage,
          search: searchValue,
          folder: folderId,
        })
      );
    }, 1000),
    [dispatch, folderId]
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
    debouncedDispatch(e.target.value);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          let fileType;
          if (file.type === 'application/pdf') {
            fileType = 'pdf';
          } else if (file.type.startsWith('image/')) {
            fileType = 'image';
          }
          const formData = new FormData();
          formData.append('file', file); // Add the file itself
          formData.append('name', file.name);
          formData.append('type', fileType);
          formData.append('size', file.size);
          formData.append('folder', folderId);

          const invoice = {
            _id: crypto.randomUUID(),
            name: file.name,
            type: fileType,
            size: file.size,
            folder: folderId,
          };

          handleAddInvoice(invoice, formData)
        } catch (error) {
          console.error('Error uploading invoice:', error);
        }
      }
    },
    [dispatch, files, folderId]
  );

  const { getRootProps: dragAndClickRootProps, getInputProps } = useDropzone({
    noClick: false,
    onDrop,
    
    accept: 'application/pdf,image/*',
  });

  const { getRootProps: dragOnlyRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    
    accept: 'application/pdf,image/*',
  });

  const handleAddInvoice = (invoice, formData) => {
    showAlert(`Uploading invoice, reading data...`);

    dispatch(
      addInvoiceAction({
        invoice,
        formData,
        prevState: { invoices: files },
      })
    ).then((result) => {
      if (result.meta.rejectedWithValue) {
        showAlert(`Error: ${result.payload.message}`, 'error');
      } else {
        showAlert(`Invoice successfully uploaded`, 'success');

        dispatch(
          getAllInvoicesAction({ page, limit: itemsPerPage })
        )
      }
    })
  }

  const renderUploadInvoice = () => {
    return (
      <div
        {...dragAndClickRootProps()}
        className='option upload'
      >
        <input {...getInputProps()} />
        <div>
          <i className='bx bx-upload' />
          <p>Upload Invoices</p>
        </div>
        <p>
          Upload your invoices in your directory so that we can handle them.
        </p>
      </div>
    );
  };

  const renderFilesGallery = () => {
    return (
      <div
        className={`titles-container padding-top ${
          isDragActive ? 'drag-active' : ''
        }`}
        {...dragOnlyRootProps()}
      >
        <div className='options-bar-container'>
          <div className='breadcrumb'>
            <Link to={'/dashboard/invoices'}>
              <i class='bx bx-chevron-left'></i>
              <h3>Back • </h3>
            </Link>
            <h3>{ folderName || '' } Folder Files</h3>
          </div>
          {renderFilters()}
        </div>
        {handleGridOrList()}
      </div>
    );
  };

  const handleGridOrList = () => {
    if (!displayedFiles?.length) 
      return (
        <div className='empty-data-message'>
          <i class='bx bx-file-blank' style={{marginBottom: "10px"}} />
          <p>No Invoice files uploaded here yet...</p>
        </div>
      )

    if (isGrid) {
      return (
        <InvoiceGrid
          files={displayedFiles}
          currentPage={currentPage}
          totalPages={totalPages}
          isEditing={isEditing}
          editValue={editValue}
          visibleDropdown={visibleDropdown}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          onEditValueChange={setEditValue}
          handleSave={handleSave}
          onToggleDropdown={toggleDropdown}
          onPageClick={handlePageClick}
          isLoading={isLoading}
          setSelectedFile={setSelectedFile}
        />
      );
    }
    return (
      <div className='files-list'>
        <InvoiceTable
          invoices={displayedFiles}
          limit={8}
          folder={folderId}
          isLoading={isLoading}
        />
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className='options-bar filters'>
        <div
          className={'filter alternative' + (isGrid ? ' selected' : '')}
          onClick={() => setIsGrid(true)}
        >
          <i
            className='bx bxs-grid'
            style={{ fontSize: '17px' }}
          />
        </div>

        <div
          className={'filter alternative' + (isGrid ? '' : ' selected')}
          onClick={() => setIsGrid(false)}
        >
          <i className='bx bx-list-ul' />
        </div>

        <div className={'search-bar alternative' + (isFocused ? ' focus' : '')}>
          <i className='bx bx-search' />
          <input
            type='text'
            placeholder='Search for document...'
            value={searchTerm}
            onChange={handleSearch}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderFolders = () => {
    return (
      <div className='titles-container'>
        <h3>Folders</h3>

        <div className='scrollable-container'>
          <div className='scroll'>
            <AddFolderComponent />
            {displayedFolders.map((folder) => {
              return (
                <FolderComponent
                  key={folder?._id}
                  folder={folder}
                  isEditing={isEditing}
                  editValue={editValue}
                  handleEdit={handleEdit}
                  handleSave={handleSave}
                  toggleDropdown={toggleDropdown}
                  handleDelete={handleDelete}
                  visibleDropdown={visibleDropdown}
                  setEditValue={setEditValue}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className='invoices-container'>
      <div className='titles-container'>
        <h2>
          <span>Invoices</span>
        </h2>
        <h4>
          Explore your full invoices history, and create your company's new invoices.
        </h4>
      </div>
      <div className='options-bar'>
        {renderAddInvoice()}
        {renderUploadInvoice()}
      </div>

      {renderFolders()}
      {renderFilesGallery()}

      {
        selectedFile &&
        <PreviewFile selectedFile={selectedFile} toggleModal={setSelectedFile} />
      }
    </section>
  );
}
