import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/teamSoftware';

export const updateTeamSoftware = async (teamSoftwareId, { isActive }) => {
  try {
    const response = await axios.put(`${prefix}/${teamSoftwareId}`, {
      isActive,
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateTeamSoftwareLicences = async (teamSoftwareId) => {
  try {
    const response = await axios.post(
      `${prefix}/${teamSoftwareId}/deactivateLicences`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const renewTeamSoftwareLicences = async (teamSoftwareId) => {
  try {
    const response = await axios.post(
      `${prefix}/${teamSoftwareId}/renewLicences`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateLicences = async (teamSoftwareId, { tier, paymentType }) => {
  try {
    const response = await axios.put(
      `${prefix}/${teamSoftwareId}/updateLicences`,
      { tier, paymentType }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateLicencesForMultipleTeams = async (
  softwareId,
  { tier, paymentType }
) => {
  try {
    const response = await axios.put(
      `${prefix}/multiple/${softwareId}/updateLicences`,
      { tier, paymentType }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteTeamSoftware = async (teamSoftwareId) => {
  try {
    const response = await axios.delete(`${prefix}/${teamSoftwareId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteTeamSoftwareForMultipleTeams = async (softwareId) => {
  try {
    const response = await axios.delete(`${prefix}/multiple/${softwareId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
