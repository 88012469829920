import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AllSaasCostsChart from '../components/Analytics/Charts/AllSaasCostsChart';
import ComparisonChart from '../components/Analytics/Charts/ComparisonChart';
import CostDifferencesChart from '../components/Analytics/Charts/CostDifferencesChart';
import CostHistoryChart from '../components/Analytics/Charts/CostHistoryChart';
import NBLicencesChart from '../components/Analytics/Charts/NBLicencesChart';
import SaasCostsPerTeam from '../components/Analytics/Charts/SaasCostsPerTeam';
import SeatsLicencesGlobalChart from '../components/Analytics/Charts/SeatsLicencesGlobalChart';
import SeatsLicencesPerTeam from '../components/Analytics/Charts/SeatsLicencesPerTeam';
import {
  getAllTrackedSoftwareYearlyCostsAndLicenceCountAction,
  getTeamCostsAndLicenceCountByYearAction,
  getTeamYearlyCostsAndLicenceCountAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
} from '../redux/features/analytics/analyticsThunks';
import { getTrackedSoftwareDataAction } from '../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../redux/features/teams/teamsThunks';

function useCostHistoryData() {
  const dispatch = useDispatch();
  
  const { costHistorySeries } = useSelector((state) => state.analytics);

  useEffect(() => {
    const year = new Date().getFullYear();
    const lastXYears = Number(process.env.REACT_APP_LAST_X_YEARS) || 2;
    
    dispatch(getAllTrackedSoftwareYearlyCostsAndLicenceCountAction(lastXYears));
    
    dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction(year));
    
    dispatch(getTrackedSoftwareDataAction());
  }, [dispatch]);

  return { costHistorySeries };
}

function useNBLicencesData() {
  
  const { nbLicencesSeries } = useSelector((state) => state.analytics);

  return { nbLicencesSeries };
}

function useAllSaasCostsData() {
  
  const { allSaasCostsSeries } = useSelector((state) => state.analytics);

  return { allSaasCostsSeries };
}

function useSeatsLicencesGlobalData() {
  
  const { seatsLicencesGlobalSeries } = useSelector((state) => state.analytics);

  return { seatsLicencesGlobalSeries };
}

function useSaasCostsPerTeamData() {
  const dispatch = useDispatch();
  
  const { saasCostsPerTeamSeries } = useSelector((state) => state.analytics);

  useEffect(() => {
    const year = new Date().getFullYear();
    const lastXYears = Number(process.env.REACT_APP_LAST_X_YEARS) || 2;
    
    dispatch(getTeamYearlyCostsAndLicenceCountAction(lastXYears));
    
    dispatch(getTeamCostsAndLicenceCountByYearAction(year));
    
    dispatch(getManagedTeamsAndMonthlyCostsAction());
  }, [dispatch]);

  return { saasCostsPerTeamSeries };
}

function useSeatsLicencesPerTeamData() {
  
  const { seatsLicencesPerTeamSeries } = useSelector(
    
    (state) => state.analytics
  );

  return { seatsLicencesPerTeamSeries };
}

function useCostDifferencesData() {
  
  const { costDifferenceSeries } = useSelector((state) => state.analytics);

  return { costDifferenceSeries };
}

function useSoftwareList() {
  const { trackedSoftwareIds, staticSoftwareData } = useSelector(
    
    (state) => state.software
  );

  const softwareList = useMemo(
    () =>
      trackedSoftwareIds.map((softwareId) => staticSoftwareData[softwareId]),
    [trackedSoftwareIds, staticSoftwareData]
  );

  return { softwareList };
}

function useTeams() {
  
  const { teamIds, teamData } = useSelector((state) => state.teams);
  const teams = teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware')
  .filter((team) => team.name !== '_providerSoftware'); // preferably isGhost, but should be selected from everywhere in the back

  return { teams };
}

export default function useHandleChartRender() {
  const { costHistorySeries } = useCostHistoryData();
  const { nbLicencesSeries } = useNBLicencesData();
  const { allSaasCostsSeries } = useAllSaasCostsData();
  const { seatsLicencesGlobalSeries } = useSeatsLicencesGlobalData();
  const { saasCostsPerTeamSeries } = useSaasCostsPerTeamData();
  const { seatsLicencesPerTeamSeries } = useSeatsLicencesPerTeamData();
  const { costDifferenceSeries } = useCostDifferencesData();
  const { softwareList } = useSoftwareList();
  const { teams } = useTeams();

  const handleChartRender = (type, selectedView, height) => {
    const renderCostHistoryChart = () => (
      <CostHistoryChart
        series={costHistorySeries}
        selectedView={selectedView}
        softwareList={softwareList}
        height={height}
      />
    );

    switch (type) {
      case 'analytics-cost-history':
        return renderCostHistoryChart();
      case 'analytics-nb-licences':
        return (
          <NBLicencesChart
            series={nbLicencesSeries}
            selectedView={selectedView}
            softwareList={softwareList}
            height={height}
          />
        );
      case 'analytics-all-saas-costs':
        return (
          <AllSaasCostsChart
            series={allSaasCostsSeries}
            selectedView={selectedView}
            height={height}
          />
        );
      case 'analytics-seats-licences-global':
        return (
          <SeatsLicencesGlobalChart
            series={seatsLicencesGlobalSeries}
            selectedView={selectedView}
            height={height}
          />
        );
      case 'analytics-saas-costs-per-team':
        return (
          <SaasCostsPerTeam
            series={saasCostsPerTeamSeries}
            teams={teams}
            selectedView={selectedView}
            height={height}
          />
        );
      case 'analytics-seats-licences-per-team':
        return (
          <SeatsLicencesPerTeam
            series={seatsLicencesPerTeamSeries}
            teams={teams}
            selectedView={selectedView}
            height={height}
          />
        );
      case 'analytics-cost-difference':
        return (
          <CostDifferencesChart
            series={costDifferenceSeries}
            selectedView={selectedView}
            height={height}
          />
        );
      case 'analytics-comparison':
        return <ComparisonChart height={height} />;
      default:
        return renderCostHistoryChart();
    }
  };

  return {
    handleChartRender,
  };
}
