import React, { useState } from 'react';
import { ListManager } from 'react-beautiful-dnd-grid';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the CSS for the skeleton
import { useDispatch, useSelector } from 'react-redux';
import { updateUserWidgetArrayAction } from '../../redux/features/widgets/widgetThunks';
import AchievementMenu from '../UI/AchievementMenu';
import AddWidgetModal from './AddWidgetModal';
import LoadingWidgets from './LoadingWidgets';
import WidgetRenderer from './WidgetRenderer';

export default function WidgetsList({ achievementMenuData }) {
  const dispatch = useDispatch();
  
  const { widgets } = useSelector((state) => state.widgets);

  const [modalState, setModalState] = useState({
    isOpen: false,
    emptyCardIndex: -1,
  });

  const toggleAddWidgetModal = (value, id) => {
    setModalState({ isOpen: value, emptyCardIndex: id });
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return null;
    }

    const newWidgets = [...widgets];
    const [movedWidget] = newWidgets.splice(sourceIndex, 1);
    newWidgets.splice(destinationIndex, 0, movedWidget);

    
    dispatch(updateUserWidgetArrayAction({ newWidgets, oldWidgets: widgets }));
  };

  return widgets?.length ? (
    <div className='dashboard-grid'>
      <div className='widgets'>
        <ListManager
          items={widgets}
          direction='horizontal'
          maxItems={2}
          render={(widget) => (
            <div
              key={widget._id}
              className='container'
            >
              <WidgetRenderer
                widget={widget}
                toggleAddWidgetModal={toggleAddWidgetModal}
              />
            </div>
          )}
          onDragEnd={reorderList}
        />
      </div>
      <AchievementMenu data={achievementMenuData} />
      {modalState.isOpen && (
        <AddWidgetModal
          toggleModal={toggleAddWidgetModal}
          emptyCardIndex={modalState.emptyCardIndex}
        />
      )}
    </div>
  ) : (
    <LoadingWidgets />
  );
}
