export const rehydrateTrackedSoftwareIds = () => {
  return JSON.parse(localStorage.getItem('trackedSoftwareIds')) || [];
};

export const rehydrateAvailableSoftwareIds = () => {
  return JSON.parse(localStorage.getItem('availableSoftwareIds')) || [];
};

export const rehydrateExcludedSoftwareIds = () => {
  return JSON.parse(localStorage.getItem('excludedSoftwareIds')) || [];
};

export const rehydrateMostPopularSoftwareIds = () => {
  return JSON.parse(localStorage.getItem('mostPopularSoftwareIds')) || [];
};

export const rehydrateCompanySoftwareIds = () => {
  return JSON.parse(localStorage.getItem('companySoftwareIds')) || [];
};

export const rehydrateSoftwareSlugToId = () => {
  return JSON.parse(localStorage.getItem('softwareSlugToId')) || {};
};

export const rehydrateStaticSoftwareData = () => {
  return JSON.parse(localStorage.getItem('staticSoftwareData')) || {};
};
