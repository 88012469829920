import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanySoftwareAction,
  getMostPopularSoftwareByLicenceCountAction,
} from '../../redux/features/software/softwareThunks';

const maxPopularSoftware =
  Number(process.env.REACT_APP_MAX_POPULAR_SOFTWARE) || 5;

export default function MostUsedSoftware() {
  
  const { mostPopularSoftwareIds, companySoftwareIds, staticSoftwareData } =
    useSelector(
      
      (state) => state.software
    );

  
  const { user } = useSelector((state) => state.user);

  const [mostPopularSoftware, setMostPopularSoftware] = useState([]);

  useEffect(() => {
    const mostPopularSoftwareTemp = mostPopularSoftwareIds.map(
      (softwareId) => ({
        ...staticSoftwareData[softwareId],
        isOwned: companySoftwareIds.includes(softwareId),
      })
    );
    setMostPopularSoftware(mostPopularSoftwareTemp);
  }, [mostPopularSoftwareIds, companySoftwareIds, staticSoftwareData]);

  const dispatch = useDispatch();

  useEffect(() => {
    
    dispatch(getMostPopularSoftwareByLicenceCountAction());
  }, [dispatch]);

  useEffect(() => {
    const companyId = user?.company;

    
    dispatch(getCompanySoftwareAction(companyId));
  }, [dispatch, user?.company]);

  const renderGridElementsHorizontal = (topClass) => {
    const renderEmptyElements = () =>
      Array(maxPopularSoftware)
        .fill()
        .map(() => (
          <div
            key={crypto.randomUUID()}
            className='saas-element empty-card'
          />
        ));

    return (
      <div className={'row grid ' + topClass}>{renderEmptyElements()}</div>
    );
  };

  const renderMiddleGridElements = () => {
    return (
      <div className='row'>
        <div className='saas-element empty-card grid' />

        <div className='saas-element empty-card'>
          <div
            className='icon'
            style={{
              backgroundImage: `url(${mostPopularSoftware[0]?.imageUrl})`,
            }}
          />
        </div>

        <div className='saas-element empty-card reknah'>
          <div
            className='icon'
            style={{ backgroundImage: `url('/logo_reknah_temp.png')` }}
          />
        </div>

        <div className='saas-element empty-card'>
          <div
            className='icon'
            style={{
              backgroundImage: `url(${mostPopularSoftware[1]?.imageUrl})`,
            }}
          />
        </div>

        <div className='saas-element empty-card grid' />
      </div>
    );
  };

  const renderBottomGridElements = () => {
    const renderRestOfSoftwareElements = () =>
      mostPopularSoftware.slice(2).map((software) => {
        return (
          <div
            key={software._id}
            className='saas-element empty-card'
          >
            <div
              className='icon'
              style={{
                backgroundImage: `url(${software?.imageUrl})`,
              }}
            />
          </div>
        );
      });

    return (
      <div className='row'>
        <div className='saas-element empty-card grid' />
        {renderRestOfSoftwareElements()}
        <div className='saas-element empty-card grid' />
      </div>
    );
  };

  const renderGrid = () => {
    return (
      <div className='saas-elements-container'>
        {renderGridElementsHorizontal()}
        {renderMiddleGridElements()}
        {renderBottomGridElements()}
        {renderGridElementsHorizontal('bottom')}
      </div>
    );
  };

  const isOwned = (softwareId) => {
    return companySoftwareIds.includes(softwareId);
  };

  const renderDetails = () => {
    const renderElements = () =>
      mostPopularSoftware.map((software) => {
        return (
          <div
            key={software._id}
            className='row'
          >
            <span className='label'>
              <div
                className='icon'
                style={{
                  backgroundImage: `url(${software?.imageUrl})`,
                }}
              />
              {software?.name}
            </span>
            <span>Corporate</span>
            <span>·</span>
            {isOwned(software._id) && <span className='owner'>Owned</span>}
          </div>
        );
      });

    return (
      <div className='table-rankings-preview-container'>
        <div className='table-rankings-preview before' />
        <div className='table-rankings-preview before third' />
        <div className='table-rankings-preview'>
          <div className='titles-container'>
            <div className='title'>Top Picks</div>
            <div className='subtitle'>Browse all the most popular software</div>
          </div>

          <div className='table-content'>{renderElements()}</div>
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className='titles-container'>
        <h3>Most popular Software</h3>
        <h4>
          Discover software subscriptions that are most used by other
          organizations.
        </h4>
      </div>
      <div className='dashboard-grid section-content'>
        {renderGrid()}
        {renderDetails()}
      </div>
    </section>
  );
}
