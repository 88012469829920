import React from 'react';
import { useSelector } from 'react-redux';

export default function AddSoftwareResultStep() {
  
  const { info, warning, error } = useSelector((state) => state.response);

  
  const { staticSoftwareData } = useSelector((state) => state.software);

  
  const { userData } = useSelector((state) => state.user);

  const { addMultipleSoftwareToMultipleTeams: infoMessages } = info;
  const { addMultipleSoftwareToMultipleTeams: warningMessages } = warning;
  const { addMultipleSoftwareToMultipleTeams: errorMessages } = error;

  const renderInfos = (infos) => {
    return infos.map((info) => (
      <div key={crypto.randomUUID()}>
        <h3>Info: {info.type}</h3>
        <p>Software Name: {staticSoftwareData[info.softwareId].name}</p>
        <p>Users:</p>
        <ul>
          {info.userIds?.map((userId) => (
            <li key={userId}>{userData[userId].name}</li>
          ))}
        </ul>
        <p>LicenceIds:</p>
        <ul>
          {info.licenceIds?.map((licenceId) => (
            <li key={licenceId}>{licenceId}</li>
          ))}
        </ul>
      </div>
    ));
  };

  const renderWarnings = (warnings) => {
    return warnings.map((warning) => (
      <div key={crypto.randomUUID()}>
        <h3>Warning: {warning.type}</h3>
        <p>Software Name: {staticSoftwareData[warning.softwareId].name}</p>
        <p>Users:</p>
        <ul>
          {warning.userIds?.map((userId) => (
            <li key={userId}>{userData[userId].name}</li>
          ))}
        </ul>
      </div>
    ));
  };

  if (errorMessages) {
    return <h2>Error</h2>;
  }

  return (
    <>
      <h2>Result</h2>
      {infoMessages && (
        <div>
          <h3>Info</h3>
          {renderInfos(infoMessages)}
        </div>
      )}
      {warningMessages && (
        <div>
          <h3>Warnings</h3>
          {renderWarnings(warningMessages)}
        </div>
      )}
    </>
  );
}
