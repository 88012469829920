import React, { useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ProfileSoftwareRow from './ProfileSoftwareRow';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 10;

const fakeSoftware = Array.from({ length: itemsPerPage }, (_, index) => ({
  _id: index,
  imageUrl: '',
  name: '',
  monthlyCosts: null,
  paymentType: '',
}));

export default function ProfileSoftwareTable(props) {
  const { software, targetRef } = props;
  const [page, setPage] = useState(0);
  const startIndex = page * itemsPerPage;

  const handlePageClick = (pageIndex) => {
    setPage(pageIndex.selected);
  };

  const displayedSoftware = useMemo(() => {
    const paginatedSoftware = software.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    return paginatedSoftware.length > 0 ? paginatedSoftware : fakeSoftware;
  }, [startIndex, software]);

  const renderSoftwareTable = () => {
    return (
      <div className='table-container' ref={targetRef}>
        <div className='table'>
          <div className='table-header'>
            <div>
              Software Name{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Team{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Activity{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Last Activity{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Monthly Costs{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Total Costs{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Payment Type{''}
              <i className='bx bx-sort-alt-2' />
            </div>
          </div>
          <div className='body'>
            {displayedSoftware.map((software) => (
              <ProfileSoftwareRow
                key={software._id}
                software={software}
              />
            ))}
          </div>
        </div>

        <div className='pagination-container'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            pageRangeDisplayed={2}
            pageCount={Math.ceil(
              (software?.length || itemsPerPage) / itemsPerPage
            )}
            previousLabel='<'
            onPageChange={handlePageClick}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    );
  };

  return renderSoftwareTable();
}
