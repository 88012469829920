import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function TeamSelection(props) {
  const { teams, selectedTeamsTracker, setSelectedTeamsTracker } = props;
  
  const { userData } = useSelector((state) => state.user);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const toggleSelectAllTeams = (value) => {
    setIsAllSelected(value);
    const selectedTeamsTrackerTemp = teams.reduce((acc, team) => {
      acc[team._id] = value;

      return acc;
    }, {});
    setSelectedTeamsTracker(selectedTeamsTrackerTemp);
  };

  const setSelectedTeam = (clickedTeam) => {
    const selectedTeamsTrackerTemp = {
      ...selectedTeamsTracker,
      [clickedTeam._id]: !selectedTeamsTracker[clickedTeam._id],
    };

    setSelectedTeamsTracker(selectedTeamsTrackerTemp);
  };

  const renderTeams = () => {
    if (!teams?.length)
        return 'No teams to display...'
  
    return teams.map((team, index) => {
      const members = team?.users.map((user) => userData[user.userId]);
      const maxVisibleMembers = 2;

      return (
        <div
          key={team?._id}
          className={
            'team-card ' + (selectedTeamsTracker[team._id] ? 'selected' : '')
          }
          onClick={() => setSelectedTeam(team)}
        >
          <div
            className='overlay'
            style={{
              backgroundImage: `url(/images/0${(index % 7) + 1}.jpg)`,
            }}
          />

          <div className='team-card-header'>
            <div className='title'>
              <p>Team department</p>
              <h3>{team?.name}</h3>
            </div>
          </div>

          <div className='team-card-body'>
            {selectedTeamsTracker[team._id] && (
              <div className='selected'>
                <i className='bx bxs-check-circle' />
                <div>Selected</div>
              </div>
            )}
            <div className='users-list'>
              {members.slice(0, maxVisibleMembers).map((member) => (
                <div
                  key={member?._id}
                  className='user-image'
                  style={{ backgroundImage: member?.imageUrl ? `url(${member?.imageUrl})` : '',
                    transform: members?.length === 1 && 'unset'
                }}
                ></div>
              ))}
              {members.length > maxVisibleMembers && (
                <div className='user-image nbr'>
                  <div>+{members.length - maxVisibleMembers}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className='search-bar-container'>
        <p>Assign software to your teams</p>
        <div
          className='select-all-container'
          onClick={() => toggleSelectAllTeams(!isAllSelected)}
        >
          {isAllSelected ? (
            <i className='bx bxs-checkbox-checked' />
          ) : (
            <i className='bx bxs-checkbox' />
          )}
          <p>
            {isAllSelected ? 'Deselect All' : 'Select All'} ({teams?.length})
          </p>
        </div>
      </div>
      <div className='teams-content'>{renderTeams()}</div>
    </>
  );
}
