import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSoftwareByIdListAction } from '../../redux/features/software/softwareThunks';
import { getUserProfileDataAction } from '../../redux/features/user/userThunks';
import BudgetAnalysis from '../Sections/BudgetAnalysis';
import ProfileCard from '../Sections/ProfileCard';
import ProfileSoftwareTable from './ProfileSoftwareTable';

export default function ProfileComponent() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { profileData } = useSelector((state) => state.user);
  const { staticSoftwareData } = useSelector((state) => state.software);
  const user = useMemo(() => profileData[userId], [profileData, userId]);
  const targetRef = useRef(null);

  const softwareIds = useMemo(() => {
    if (!user?.licences) {
      return [];
    }
    return user.licences.map((licence) => licence.software?._id);
  }, [user?.licences]);

  const software = useMemo(() => {
    if (!user?.licences) {
      return [];
    }

    return user?.licences.map((licence) =>  {
      return {
        ...licence,
        ...staticSoftwareData[licence?.software?._id],
        monthlyCosts: user?.costsBySoftware[licence?.software?._id],
        totalCosts: user?.totalCostsBySoftware?.[licence?.software?._id] || 0,
      }
    });
  }, [user, staticSoftwareData]);

  useEffect(() => {
    
    dispatch(getUserProfileDataAction(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (softwareIds.length > 0) {
      
      dispatch(getSoftwareByIdListAction(softwareIds));
    }
  }, [dispatch, softwareIds]);

  return (
    <section className='profile_page applications-container'>
      <div className='titles-container'>
        <h2>
          <span>Profile Details</span>
        </h2>
        <h4>Details about this user, his teams, his licences and stats.</h4>
      </div>
      <ProfileCard user={user} targetRef={targetRef}/>

      <div
        className='titles-container'
        style={{ paddingTop: '3rem' }}
      >
        <h3 style={{ marginBottom: 2 }}>Software Licences </h3>
        <h4 style={{ paddingBottom: '20px' }}>
          Software Licences used by {user?.name}
        </h4>
      </div>
      <ProfileSoftwareTable software={software} targetRef={targetRef}/>
      <BudgetAnalysis
        title='Team Budget Analysis'
        subtitle='Overview of the application spendings.'
      />
    </section>
  );
}
