import { createSlice } from '@reduxjs/toolkit';
import { getAllInvoicesAction } from '../invoice/invoiceThunks';
import {
  addFolderAction,
  deleteFolderAction,
  getAllFoldersAction,
  updateFolderNameAction,
} from './folderThunks';

const initialState = {
  folders: [],
  folderIdToName: {},
};

const folderSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getAllFoldersAction.fulfilled, (state, action) => {
        state.folders = action.payload.folders;
      })
      .addCase(addFolderAction.fulfilled, (state, action) => {
        state.folders.push(action.payload);
      })
      .addCase(updateFolderNameAction.fulfilled, (state, action) => {
        const { updatedFolder } = action.payload;

        const index = state.folders.findIndex(
          (folder) => folder._id === updatedFolder?._id
        );

        if (index !== -1) {
          state.folders[index] = updatedFolder;
        }
      })
      .addCase(deleteFolderAction.fulfilled, (state, action) => {
        const { ok } = action.payload;

        if (ok) {
          state.folders = state.folders.filter(
            
            (folder) => folder._id !== action.meta.arg.folderId
          );
        }
      })
      .addCase(getAllInvoicesAction.fulfilled, (state, action) => {
        const { folderDetails } = action.payload;

        if (!folderDetails) {
          return;
        }

        state.folderIdToName[folderDetails._id] = folderDetails.name;
      }),
});

export default folderSlice.reducer;
