import React, { useState } from 'react';
import { getMetric } from '../../util/UtilFunctions';

export default function BudgetAnalysis(props) {
  const [budget, setBudget] = useState({
    totalMonth: 25640.5,
    totalYear: 25250 * 12,
  });

  const [stats, setStat] = useState([
    {
      title: 'HR',
      percentage: 85,
      value: 213044,
    },
    {
      title: 'SAAS',
      percentage: 10,
      value: 25000,
    },
  ]);

  const renderStat = (stat) => {
    return (
      <div className='container'>
        <div className='percentage-metric'>
          <div className='title'>
            <h4>{stat.title}</h4>
            <h3>
              {stat.percentage}
              <span>%</span>
            </h3>
          </div>
          <svg>
            <circle
              id='track'
              cx='75'
              cy='75'
              r='65'
            ></circle>
            <circle
              id='progress'
              cx='75'
              cy='75'
              r='65'
            ></circle>
          </svg>
        </div>
        <div className='stat'>
          <h3
            style={{
              width: 'fit-content',
              margin: 'auto',
              fontSize: '25px',
            }}
          >
            <span className='currency'>€</span>
            <span>{getMetric(stat.value).first}</span>
          </h3>
          <p
            style={{
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Your {stat.title} costs<span></span> for <span>10 employees</span>.
          </p>
        </div>
      </div>
    );
  };

  return (
    <section className='budget-analysis'>
      <div className='titles-container'>
        <h3>{props.title || "Company's Budget Analysis"}</h3>
        <h4>
          {props.subtitle ||
            'Overview of your current budget and your spendings.'}
        </h4>
      </div>
      <div
        className='dashboard-grid budget'
        style={{
          gridTemplateRows: 'repeat(1, 1fr)',
          gridTemplateColumns: 'repeat(2, 1fr)',
          height: 'fit-content',
          paddingBottom: '3rem',
        }}
      >
        <div
          className='container'
          style={{
            height: 'fit-content',
            width: 'fit-content',
            margin: 'auto',
          }}
        >
          <div className='stat'>
            <h3
              style={{
                width: 'fit-content',
                margin: 'auto',
              }}
            >
              <span className='currency'>€</span>
              <span>{getMetric(budget.totalMonth).first}</span>
              <span className='secondary'>
                {getMetric(budget.totalMonth).second}
              </span>
            </h3>
            <p
              style={{
                width: 'fit-content',
                margin: 'auto',
              }}
            >
              Your total <span>monthly budget</span> for the company.
            </p>
          </div>
        </div>

        {stats.map((stat) => renderStat(stat))}
      </div>
    </section>
  );
}
