import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/widget';

export const addWidget = async (widget) => {
  try {
    const response = await axios.post(`${prefix}`, widget);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteWidget = async (widgetId) => {
  try {
    const response = await axios.delete(`${prefix}/${widgetId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
