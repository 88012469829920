import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addMultipleSoftwareToMultipleTeams,
  addTeam,
} from '../../../services/api/team/modify';

export const addMultipleSoftwareToMultipleTeamsAction = createAsyncThunk(
  'teams/addMultipleSoftwareToMultipleTeams',
  async (body, { rejectWithValue }) => {
    try {
      const data = await addMultipleSoftwareToMultipleTeams(body);

      const { info, warning } = data;

      return { info, warning };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTeamAction = createAsyncThunk(
  'teams/addTeam',
  async (body, { rejectWithValue }) => {
    try {
      const data = await addTeam(body);

      const info = data;

      return { info };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
