import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDate } from '../../util/formatDate';
import { useInvoiceDropdown } from '../../hooks/Dropdown/useInvoiceDropdown';
import UpdateInvoiceModal from '../Modals/Update/UpdateInvoiceModal';

export default function FileComponent({
  file,
  isEditing,
  editValue,
  visibleDropdown,
  handleEdit,
  handleDelete,
  onEditValueChange,
  handleSave,
  onToggleDropdown,
  setSelectedFile,
  setInvoiceConfirmation
}) {
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  const {
    isModalOpen,
    message,
    onConfirm,
    toggleModal,
    dropdownOptions,
    value
  } = useInvoiceDropdown(file, setInvoiceConfirmation);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        onToggleDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onToggleDropdown]);

  const handleDropDown = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div className='file-container'>
        <div className='file-preview' onClick={() => setSelectedFile(file)}>
          {file?.preview ? (
            <div
              className='document'
              style={{ backgroundImage: `url(${file?.preview})` }}
            />
          ) : (
            <div className='pdf' />
          )}
        </div>
        <div className='file-details'>
          <div>
            {file?.type ? (
              <i
                className={
                  file?.type === 'pdf'
                    ? 'icon bx bx-file'
                    : 'icon bx bxs-file-image'
                }
              />
            ) : (
              <Skeleton
                circle={true}
                height={20}
                width={20}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
          <div className='file-titles'>
            {!isEditing || isEditing !== file?._id ? (
              file?.name ? (
                <h3>{file.name}</h3>
              ) : (
                <Skeleton
                  width={150}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )
            ) : (
              <input
                type='text'
                className='text-input folder-input'
                value={editValue}
                onChange={(e) => onEditValueChange(e.target.value)}
                onBlur={() => handleSave(file?._id, true)}
                autoFocus
              />
            )}
            {file?.createdAt ? (
              <p>
                {file?.files?.length} Files • {formatDate(file?.createdAt)}
              </p>
            ) : (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
          <div>
            {file ? (
              <i
                ref={iconRef}
                className='bx bx-dots-vertical-rounded'
                onClick={() => onToggleDropdown(file?._id)}
              />
            ) : (
              <Skeleton
                width={20}
                height={20}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
          {file && visibleDropdown === file?._id && (
            <div
              className='dropdown-menu'
              ref={dropdownRef}
            >
              <ul>
                {
                  !file?.isConfirmed &&
                  <>
                  <li onClick={() => setInvoiceConfirmation(file)}>
                    <i className='bx bx-edit' /> Confirm data
                  </li>
                  </>
                }
                
                {/*<li onClick={() => handleEdit(file._id, file.name)}>
                  <i className='bx bx-edit' /> Edit
                </li>*/}
                <li onClick={(e) => dropdownOptions[1]?.onClick(e)}>
                  <i className='bx bx-trash' /> Delete
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <UpdateInvoiceModal
          toggleModal={toggleModal}
          invoiceId={file?._id}
          message={message}
          onConfirm={onConfirm}
          value={value}
        />
      )}
    </>
  );
}
