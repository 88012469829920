import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  verifyUserAction,
  verifyUserFromProviderAction,
  verifyUserFromTeamInviteAction,
} from '../../redux/features/user/userThunks';

export default function Confirm() {
  const dispatch = useDispatch();

  const { token } = useParams();
  
  const location = useLocation();

  useEffect(() => {
    if (token) {
      if (location.pathname.includes('confirm_provider')) {
        
        dispatch(verifyUserFromProviderAction({ token }));
      } else if (location.pathname.includes('confirm_team_invite')) {
        
        dispatch(verifyUserFromTeamInviteAction({ token }));
      } else if (location.pathname.includes('confirm')) {
        
        dispatch(verifyUserAction({ token }));
      }
    }
  }, [dispatch, token, location.pathname]);

  return (
    <div className='auth-text-container'>
      <h3 className='welcome-text'>Welcome!</h3>
      <h4 className='signup-text'>Thank you for confirming your account.</h4>
    </div>
  );
}
