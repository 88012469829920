import React, { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInvoicesAction } from '../../redux/features/invoice/invoiceThunks';
import InvoiceRow from './InvoiceRow';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 10;

const fakeInvoices = Array.from({ length: itemsPerPage }, (_, index) => ({
  _id: index,
  name: '',
  licences: [],
  value: null,
  createdAt: '',
  software: { _id: '' },
}));

export default function InvoiceTable(props) {
  const dispatch = useDispatch();

  const { fromApplication, softwareId, limit, folder, isLoading, setInvoiceConfirmation } = props;

  const { invoices, currentPage, totalPages } = useSelector(
    
    (state) => state.invoices
  );

  const [page, setPage] = useState(1);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const handlePageClick = (pageIndex) => {
    setPage(pageIndex.selected + 1);
  };

  const handleRowClick = (invoiceId) => {
    setSelectedInvoiceId((prevSelectedId) =>
      prevSelectedId === invoiceId ? null : invoiceId
    );
  };

  const displayedInvoices = useMemo(() => {
    if (fromApplication) {
      return invoices;
    }

    return isLoading ? fakeInvoices : invoices;
  }, [fromApplication, isLoading, invoices]);

  useEffect(() => {
    dispatch(
      
      getAllInvoicesAction({
        page,
        limit: limit || itemsPerPage,
        software: softwareId,
        folder,
      })
    );
  }, [dispatch, page, limit, softwareId, folder]);

  const renderInvoiceTable = () => {
    return (
      <div className='table-container'>
        <div className='table'>
          <div className='table-header'>
            <div>
              Invoice Name{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Uploaded by <i className='bx bx-sort-alt-2' />
            </div>
            {!fromApplication && (
              <div>
                Software <i className='bx bx-sort-alt-2' />
              </div>
            )}
            <div>
              Subscription{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Nb Licences{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Amount{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Due Date{''}
              <i className='bx bx-sort-alt-2' />
            </div>
          </div>
          <div className='body'>
            {displayedInvoices.map((invoice) => (
              <InvoiceRow
                key={invoice._id}
                invoice={invoice}
                isSelected={invoice._id === selectedInvoiceId}
                onClick={() => handleRowClick(invoice._id)}
                fromApplication={fromApplication}
                setInvoiceConfirmation={setInvoiceConfirmation}
              />
            ))}
          </div>
        </div>

        <div className='pagination-container'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            pageRangeDisplayed={2}
            pageCount={totalPages}
            previousLabel='<'
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
          />
        </div>
      </div>
    );
  };

  return (
    <section className='application-page'>
      <div className='titles-container'></div>
      {renderInvoiceTable()}
    </section>
  );
}
