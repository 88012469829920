export const getWidgetTypes = () => [
  'spending',
  'subscription',
  'analytics-cost-history',
  'analytics-nb-licences',
  'analytics-all-saas-costs',
  'analytics-seats-licences-global',
  'analytics-saas-costs-per-team',
  'analytics-seats-licences-per-team',
  'analytics-cost-difference',
  'analytics-comparison',
];

const maxWidgets = Number(process.env.REACT_APP_MAX_WIDGETS) || 4;

export const getLastXWidgetsOrFill = (widgets, x) => {
  widgets ||= [];
  x ||= maxWidgets;

  const fillCount = x - widgets.length;

  for (let i = 0; i < fillCount; i++) {
    widgets.push({ _id: crypto.randomUUID(), type: 'empty', data: {} });
  }

  return widgets.slice(-x);
};

export const getNewWidgetsAfterDelete = (deletedWidgetId, widgets) => {
  return widgets.map((widget) =>
    widget._id !== deletedWidgetId
      ? widget
      : { _id: crypto.randomUUID(), type: 'empty' }
  );
};
