const handleErrorResponse = (error) => {
  if (error.response) {
    const { status, data } = error.response;

    if (status === 404) {
      throw {
        type: 'NotFound',
        message: data.message || 'Resource not found.',
      };
    }

    if (status === 400) {
      throw {
        type: 'UnexpectedError',
        message: data?.error || 'Bad request. Please check your input.',
        status,
        details: data,
      };
    }

    else {
      throw {
        type: 'UnexpectedError',
        message: data?.error || 'Bad request. Please check your input.',
        status,
        details: data,
      };
    }
  }

  throw {
    type: 'UnexpectedError',
    message: error.message || 'An unexpected error occurred.',
    details: error, 
  };
};

export default handleErrorResponse;
