import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getWidgetDataByTypeAction } from '../../redux/features/widgets/widgetThunks';
import { getMetric, getSpendingData } from '../../util/UtilFunctions';

function SpendingWidget(props) {
  const { widget, onDelete } = props;
  const { type } = widget;

  
  const { widgetData } = useSelector((state) => state.widgets);

  const spendingData = widgetData[type] ?? getSpendingData();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownOptions = [
    {
      value: 'delete',
      onClick: onDelete,
      icon: 'bx bx-trash',
    },
  ];

  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  useEffect(() => {
    
    dispatch(getWidgetDataByTypeAction(type));
  }, [dispatch, type]);

  return (
    <div className='card'>
      <div className='card-header'>
        <div
          className='icon'
          style={{ backgroundImage: `url(${spendingData?.icon})` }}
        />
        <div className='text'>
          <div className='title'>{spendingData?.title}</div>
          <div className='subtitle'>{spendingData?.subtitle}</div>
        </div>
        <i
          ref={iconRef}
          className='bx bx-dots-horizontal options pointer'
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className='dropdown-menu widget'
            ref={dropdownRef}
          >
            {dropdownOptions.map((option) => {
              return (
                <div
                  key={crypto.randomUUID()}
                  className='dropdown-item'
                  onClick={option?.onClick}
                >
                  <i className={option?.icon} />
                  {option?.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className='card-content'>
        <div className='metric'>
          <span className='currency'>€</span>
          <span> {getMetric(spendingData?.metric)?.first}</span>{' '}
          <span className='decimal'>
            {getMetric(spendingData?.metric)?.second}
          </span>
        </div>
        <div className='label'>
          <div className={spendingData?.isRateGreen ? 'rate green' : 'rate'}>
            <i className='bx bx-trending-down' />
            {spendingData?.rate}
          </div>
          <div className='average'>{spendingData?.average} monthly</div>
        </div>
      </div>
      <div className='card-footer'>
        <Link to={spendingData?.linkToReport}>View Report</Link>
        <i className='bx bxs-chevron-right-circle' />
      </div>
    </div>
  );
}

export default React.memo(SpendingWidget);
