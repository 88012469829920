import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/event';

//? options: { page, limit, afterDate, beforeDate }
export const getNotifications = async (excludedTypes, options) => {
  try {
    const body = {
      excludedTypes,
    };

    const response = await axios.post(`${prefix}/notifications`, body, {
      params: {
        ...options,
      },
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
