import { getTeamCostsAndLicenceCountByYear } from '../api/team/fetch';

const getMonthValue = (monthIndex) => {
  const monthConverter = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return monthConverter[monthIndex];
};

const getAllSaasCostsMonthlySeries = (costHistoryMonthlySeries) => {
  const allSaasCostsMonthlyData = {};

  for (const softwareCosts of Object.values(costHistoryMonthlySeries)) {
    for (const [month, costs] of Object.entries(softwareCosts[0].data)) {
      if (!allSaasCostsMonthlyData[month]) {
        allSaasCostsMonthlyData[month] = {
          x: getMonthValue(month),
          y: 0,
          tooltip: 0,
        };
      }

      allSaasCostsMonthlyData[month].y += costs.y;
      allSaasCostsMonthlyData[month].tooltip += costs.tooltip;
    }
  }

  return [{ data: Object.values(allSaasCostsMonthlyData) }];
};

export const getSeatsLicencesGlobalMonthlySeries = (
  nbLicencesMonthlySeries
) => {
  const seatsLicencesGlobalMonthlyData = {};

  for (const softwareCosts of Object.values(nbLicencesMonthlySeries)) {
    for (const [month, costs] of Object.entries(softwareCosts[0].data)) {
      if (!seatsLicencesGlobalMonthlyData[month]) {
        seatsLicencesGlobalMonthlyData[month] = {
          x: getMonthValue(month),
          y: 0,
          tooltip: 0,
        };
      }

      seatsLicencesGlobalMonthlyData[month].y += costs.y;
      seatsLicencesGlobalMonthlyData[month].tooltip += costs.tooltip;
    }
  }

  return [{ data: Object.values(seatsLicencesGlobalMonthlyData) }];
};

export const getTrackedSoftwareCostsAndLicenceCountByYearSeries = async (
  trackedSoftwareCostsAndLicenceCountByYear,
  year
) => {
  const costHistoryMonthlySeries = {};
  const nbLicencesMonthlySeries = {};
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (const [softwareId, softwareData] of Object.entries(
    trackedSoftwareCostsAndLicenceCountByYear
  )) {
    const costHistoryMonthlyData = [];
    const nbLicencesMonthlyData = [];

    for (const [month, { monthlyCosts, licences }] of Object.entries(
      softwareData
    )) {
      const monthIndex = Number(month);

      if (year !== currentYear || monthIndex <= currentMonth) {
        costHistoryMonthlyData.push({
          x: getMonthValue(monthIndex),
          y: Math.round(monthlyCosts),
          tooltip: licences,
        });
        nbLicencesMonthlyData.push({
          x: getMonthValue(monthIndex),
          y: licences,
          tooltip: Math.round(monthlyCosts),
        });
      }
    }

    costHistoryMonthlySeries[softwareId] = [{ data: costHistoryMonthlyData }];
    nbLicencesMonthlySeries[softwareId] = [{ data: nbLicencesMonthlyData }];
  }

  const [allSaasCostsMonthlySeries, seatsLicencesGlobalMonthlySeries] =
    await Promise.all([
      getAllSaasCostsMonthlySeries(costHistoryMonthlySeries),
      getSeatsLicencesGlobalMonthlySeries(nbLicencesMonthlySeries),
    ]);

  return {
    costHistoryMonthlySeries,
    nbLicencesMonthlySeries,
    allSaasCostsMonthlySeries,
    seatsLicencesGlobalMonthlySeries,
  };
};

const getAllSaasCostsYearlySeries = (costHistoryYearlySeries) => {
  const allSaasCostsYearlyData = {};

  for (const softwareCosts of Object.values(costHistoryYearlySeries)) {
    for (const { x, y, tooltip } of Object.values(softwareCosts[0].data)) {
      if (!allSaasCostsYearlyData[x]) {
        allSaasCostsYearlyData[x] = {
          x: x,
          y: 0,
          tooltip: 0,
        };
      }

      allSaasCostsYearlyData[x].y += y;
      allSaasCostsYearlyData[x].tooltip += tooltip;
    }
  }

  return [{ data: Object.values(allSaasCostsYearlyData) }];
};

export const getSeatsLicencesGlobalYearlySeries = (nbLicencesYearlySeries) => {
  const seatsLicencesGlobalYearlyData = {};

  for (const softwareCosts of Object.values(nbLicencesYearlySeries)) {
    for (const { x, y, tooltip } of Object.values(softwareCosts[0].data)) {
      if (!seatsLicencesGlobalYearlyData[x]) {
        seatsLicencesGlobalYearlyData[x] = {
          x: x,
          y: 0,
          tooltip: 0,
        };
      }

      seatsLicencesGlobalYearlyData[x].y += y;
      seatsLicencesGlobalYearlyData[x].tooltip += tooltip;
    }
  }

  return [{ data: Object.values(seatsLicencesGlobalYearlyData) }];
};

export const getTrackedSoftwareYearlyCostsAndLicenceCountSeries = async (
  trackedSoftwareYearlyCostsAndLicenceCount
) => {
  const costHistoryYearlySeries = {};
  const nbLicencesYearlySeries = {};

  for (const [softwareId, softwareData] of Object.entries(
    trackedSoftwareYearlyCostsAndLicenceCount
  )) {
    const costHistoryYearlyData = [];
    const nbLicencesYearlyData = [];

    for (const [year, { yearlyCosts, licences }] of Object.entries(
      softwareData
    )) {
      costHistoryYearlyData.push({
        x: year,
        y: Math.round(yearlyCosts),
        tooltip: licences,
      });
      nbLicencesYearlyData.push({
        x: year,
        y: licences,
        tooltip: Math.round(yearlyCosts),
      });
    }

    costHistoryYearlySeries[softwareId] = [{ data: costHistoryYearlyData }];
    nbLicencesYearlySeries[softwareId] = [{ data: nbLicencesYearlyData }];
  }

  const [allSaasCostsYearlySeries, seatsLicencesGlobalYearlySeries] =
    await Promise.all([
      getAllSaasCostsYearlySeries(costHistoryYearlySeries),
      getSeatsLicencesGlobalYearlySeries(nbLicencesYearlySeries),
    ]);

  return {
    costHistoryYearlySeries,
    nbLicencesYearlySeries,
    allSaasCostsYearlySeries,
    seatsLicencesGlobalYearlySeries,
  };
};

export const getTeamCostsAndLicenceCountByYearSeries = (
  teamCostsAndLicenceCountByYear,
  year
) => {
  const saasCostsPerTeamMonthlySeries = {};
  const seatsLicencesPerTeamMonthlySeries = {};
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (const [teamId, teamData] of Object.entries(
    teamCostsAndLicenceCountByYear
  )) {
    const saasCostsPerTeamMonthlyData = [];
    const seatsLicencesPerTeamMonthlyData = [];

    for (const [month, { monthlyCosts, licences }] of Object.entries(
      teamData
    )) {
      const monthIndex = Number(month);

      if (year !== currentYear || monthIndex <= currentMonth) {
        saasCostsPerTeamMonthlyData.push({
          x: getMonthValue(monthIndex),
          y: Math.round(monthlyCosts),
          tooltip: licences,
        });
        seatsLicencesPerTeamMonthlyData.push({
          x: getMonthValue(monthIndex),
          y: licences,
          tooltip: Math.round(monthlyCosts),
        });
      }
    }

    saasCostsPerTeamMonthlySeries[teamId] = [
      { data: saasCostsPerTeamMonthlyData },
    ];
    seatsLicencesPerTeamMonthlySeries[teamId] = [
      { data: seatsLicencesPerTeamMonthlyData },
    ];
  }

  return {
    saasCostsPerTeamMonthlySeries,
    seatsLicencesPerTeamMonthlySeries,
  };
};

export const getTeamYearlyCostsAndLicenceCountSeries = (
  teamYearlyCostsAndLicenceCount
) => {
  const saasCostsPerTeamYearlySeries = {};
  const seatsLicencesPerTeamYearlySeries = {};

  for (const [teamId, teamData] of Object.entries(
    teamYearlyCostsAndLicenceCount
  )) {
    const saasCostsPerTeamYearlyData = [];
    const seatsLicencesPerTeamYearlyData = [];

    for (const [year, { yearlyCosts, licences }] of Object.entries(teamData)) {
      saasCostsPerTeamYearlyData.push({
        x: year,
        y: Math.round(yearlyCosts),
        tooltip: licences,
      });
      seatsLicencesPerTeamYearlyData.push({
        x: year,
        y: licences,
        tooltip: Math.round(yearlyCosts),
      });
    }

    saasCostsPerTeamYearlySeries[teamId] = [
      { data: saasCostsPerTeamYearlyData },
    ];
    seatsLicencesPerTeamYearlySeries[teamId] = [
      { data: seatsLicencesPerTeamYearlyData },
    ];
  }

  return {
    saasCostsPerTeamYearlySeries,
    seatsLicencesPerTeamYearlySeries,
  };
};

export const getCostDifferenceMonthlySeries = (allSaasCostsMonthlySeries) => {
  const costDifferenceMonthlyData = allSaasCostsMonthlySeries.map((series) => {
    const newData = series.data.map((point, index, array) => {
      if (index === 0) {
        return { ...point, y: 0, tooltip: 0 };
      }

      const prevPoint = array[index - 1];
      const yDifference = point.y - prevPoint.y;
      let percentageIncrease;

      if (prevPoint.y === 0 && point.y > 0) {
        percentageIncrease = 100;
      } else {
        percentageIncrease =
          prevPoint.y === 0
            ? 0
            : ((yDifference / prevPoint.y) * 100).toFixed(2);
      }

      return { ...point, y: yDifference, tooltip: percentageIncrease };
    });
    return { ...series, data: newData };
  });

  return costDifferenceMonthlyData;
};

export const getCostDifferenceYearlySeries = (allSaasCostsYearlySeries) => {
  const costDifferenceYearlyData = allSaasCostsYearlySeries.map((series) => {
    const newData = series.data.map((point, index, array) => {
      if (index === 0) {
        return { ...point, y: 0, tooltip: 0 };
      }

      const prevPoint = array[index - 1];
      const yDifference = point.y - prevPoint.y;
      let percentageIncrease;

      if (prevPoint.y === 0 && point.y > 0) {
        percentageIncrease = 100;
      } else {
        percentageIncrease =
          prevPoint.y === 0
            ? 0
            : ((yDifference / prevPoint.y) * 100).toFixed(2);
      }

      return { ...point, y: yDifference, tooltip: percentageIncrease };
    });
    return { ...series, data: newData };
  });

  return costDifferenceYearlyData;
};

const calculateRate = (currentMonthCosts, lastMonthCosts) => {
  if (lastMonthCosts === 0 && currentMonthCosts === 0) {
    return 0;
  }

  return lastMonthCosts === 0
    ? 100
    : Math.round(
        Math.abs(((currentMonthCosts - lastMonthCosts) / lastMonthCosts) * 100)
      );
};

export const getTeamCostsEvolution = async (teamCostsAndLicenceCountByYear) => {
  const teamEvolutionData = {};
  const currentMonth = new Date().getMonth();

  if (currentMonth > 0) {
    Object.keys(teamCostsAndLicenceCountByYear).forEach((teamId) => {
      const teamData = teamCostsAndLicenceCountByYear[teamId];
      const currentMonthData = teamData[currentMonth];
      const lastMonthData = teamData[currentMonth - 1];

      const isUp = currentMonthData.monthlyCosts > lastMonthData.monthlyCosts;
      const rate = calculateRate(
        currentMonthData.monthlyCosts,
        lastMonthData.monthlyCosts
      );

      teamEvolutionData[teamId] = {
        isUp,
        rate,
      };
    });
  } else {
    const lastYear = new Date().getFullYear() - 1;
    const lastYearData = await getTeamCostsAndLicenceCountByYear(lastYear);

    Object.keys(teamCostsAndLicenceCountByYear).forEach((teamId) => {
      const teamData = teamCostsAndLicenceCountByYear[teamId];
      const currentMonthData = teamData[currentMonth];
      const lastMonthData = lastYearData[teamId][11];

      const isUp = currentMonthData.monthlyCosts > lastMonthData.monthlyCosts;
      const rate = calculateRate(
        currentMonthData.monthlyCosts,
        lastMonthData.monthlyCosts
      );

      teamEvolutionData[teamId] = {
        isUp,
        rate,
      };
    });
  }

  return teamEvolutionData;
};
