const getWidgetIcon = () =>
  'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png';

const getMonthName = (month) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return monthNames[month];
};

const getSubtitle = (widgetData) => {
  const { month } = widgetData;
  const monthName = getMonthName(month);

  return `Since 01 ${monthName}`;
};

const getMetric = (widgetData) => {
  const { metric } = widgetData;

  return metric.toFixed(3);
};

const getAverage = (widgetData) => {
  const { average } = widgetData;
  const roundedAverage = Math.round(average);

  if (roundedAverage < 0) {
    return `-€${Math.abs(roundedAverage)}`;
  } else {
    return `+€${roundedAverage}`;
  }
};

const getRate = (widgetData) => {
  const { rate } = widgetData;
  const roundedRate = Math.round(rate);

  return `${roundedRate}%`;
};

export const formatSpendingWidgetData = (widgetData) => {
  const spendingWidgetData = {
    ...widgetData,
    title: 'Total Spending',
    subtitle: getSubtitle(widgetData),
    icon: getWidgetIcon(),
    metric: getMetric(widgetData),
    average: getAverage(widgetData),
    rate: getRate(widgetData),
    linkToReport: '',
  };

  return spendingWidgetData;
};

export default formatSpendingWidgetData;
