import React, { useState } from 'react';

export default function UpdateTeamModal(props) {
  const { message, value, onConfirm, toggleModal, initialDetails } = props;
  const [details, setDetails] = useState(initialDetails);

  const onCancel = () => {
    toggleModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const renderContent = () => {
    switch (value) {
      case 'delete team':
        return <p className='titles'>{message}</p>;

      case 'edit team':
        return (
          <form>
            <div className='titles'>
              <h3>Fill Team Details</h3>
              <p>You can edit the team info here</p>
            </div>
            <div className='modal-content'>
              <div className='form-input-container'>
                <label htmlFor='name'>Name</label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className='text-input'
                  value={details.name}
                  onChange={handleChange}
                  placeholder='Edit team name...'
                />
              </div>
              <div className='form-input-container'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='text-input'
                  value={details.email}
                  onChange={handleChange}
                  placeholder='Edit team email...'
                />
              </div>
              <div className='form-input-container'>
                <label htmlFor='description'>Description</label>
                <textarea
                  id='description'
                  name='description'
                  className='text-area-input'
                  value={details.description}
                  onChange={handleChange}
                   placeholder='Edit team description...'
                   style={{height: '100px'}}
                />
              </div>
            </div>
          </form>
        );

      case 'delete software':
        return <p className='titles'>{message}</p>;

      default:
        return;
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container confirmation'>
        <div className='modal'>
          {renderContent()}
          <div className='next-btn-container'>
            <button
              className='next-btn previous'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='next-btn'
              onClick={() => onConfirm(details)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
