import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import {
  addCompanyAction,
  getCompanyDataAction,
} from '../../redux/features/company/companyThunks';
import { AuthService } from '../../services/api/AuthService';
import { API_URLS } from '../../util/Constants';
import { getCompanyNameFromEmail } from '../../util/getCompanyNameFromMail';
import isValidToken from '../../util/isValidToken';
import { useAlert } from '../../providers/AlertProvider';

const azureEndpoint = `${process.env.REACT_APP_BACK_API_URL}${API_URLS.AZURE.SIGN_IN}`;

export default function Company() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuthRedirect('/dashboard');
  const { showAlert } = useAlert();

  const { user, token } = useSelector((state) => state.user);

  const [companyName, setCompanyName] = useState(
    getCompanyNameFromEmail(user?.email)
  );

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!companyName)
      return showAlert('Please provide a company name before submitting', 'error')

    dispatch(addCompanyAction({ name: companyName })).then(() =>
      navigate('/dashboard')
    );
  };

  const handleConnect = async () => {
    await AuthService.connect(azureEndpoint);
  };

  useEffect(() => {
    
    dispatch(getCompanyDataAction(user?.company));
  }, [dispatch, navigate, user?.company]);

  if (isValidToken(token) && !user?.company) {
    return (
      <div className='start-container'>
        <div className='auth-card-container'>
          <div className='logo-container'>
            <img
              alt='Reknah logo'
              src='https://reknah.com/reknah_logo.png'
            />
          </div>
          <div className='auth-text-container'>
            <h3 className='welcome-text'>Welcome to Reknah!</h3>
            <h4 className='signup-text'>
              Please provide your company details to get started.
            </h4>
          </div>
          <div className='input-container'>
            <div className='form-group'>
              <input
                type='text'
                id='companyName'
                placeholder='Enter your company name'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                autoFocus
              />
            </div>
            {/*<div className='form-group'>
              <label htmlFor='companyImage'>Upload Company Image</label>
              <input
                type='file'
                id='companyImage'
                accept='image/*'
                required
              />
            </div>*/}
            {<button className="button" onClick={handleConnect}>Connect to Azure Provider</button>}
            <form onSubmit={handleSubmit}>
              <button type='submit' className="button">Create Company</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
