//? This function merges two objects: If object2 has a key that object1 doesn't have, it will be added to the result object. If object2 has a key that object1 also has, the value of the key in object1 will be replaced by the value of the key in object2.
const mergeObjects = (obj1, obj2) => {
  const result = {};

  Object.keys(obj1).forEach((key) => {
    result[key] = obj1[key];
  });

  Object.keys(obj2).forEach((key) => {
    result[key] = { ...result[key], ...obj2[key] };
  });

  return result;
};

export default mergeObjects;
