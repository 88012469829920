import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTeamDropdown } from '../../hooks/Dropdown/useTeamDropdown';
import { getMetric } from '../../util/UtilFunctions';
import formatToSlug from '../../util/formatToSlug';
import AddSoftwareApplication from '../Modals/Update/Add Software Application/AddSoftwareApplication';
import UpdateTeamModal from '../Modals/Update/UpdateTeamModal';

export default function TeamCard(props) {
  const { team, index, isOwned, softwareData, isFromApplicationComponent } =
    props;

  const backgroundImage = `url(/images/0${(index % 7) + 1}.jpg)`;

  const users = useMemo(() => team?.users, [team]);

  const activeUsersLength = useMemo(
    () => users?.filter((user) => user.isActive).length,
    [users]
  );

  const { userData } = useSelector(
    
    (state) => state.user
  );

  const {
    isModalOpen,
    dropdownOpen,
    message,
    value,
    onConfirm,
    initialTeamDetails,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
  } = useTeamDropdown(team, softwareData, isFromApplicationComponent);

  const [
    isAddSoftwareApplicationModalOpen,
    setIsAddSoftwareApplicationModalOpen,
  ] = useState(false);

  const metric = getMetric(team?.monthlyCosts);

  const toggleAddSoftwareModal = (value) => {
    setIsAddSoftwareApplicationModalOpen(value);
  };

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  const renderUsers = (users) => {
    if (!users?.length) {
      return null;
    }

    const usersLimit = users?.length > 2 ? users.slice(0, 2) : users,
    isOneUser = usersLimit.length === 1;

    return usersLimit.map((user, index) => {
      let imageUrl = userData[user.userId]?.imageUrl
      return (
        <div
          key={user.userId}
          className='user-image'
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : `url(/avatars/${Math.floor(Math.random() * 7) + 1}.jpg)`,
            transform: isOneUser && 'unset'
          }}
        />
      );
    });
  };

  return (
    <>
      <Link
        to={`/dashboard/team/${formatToSlug(team?.name)}`}
        className={
          'team-card ' + (isOwned !== undefined && !isOwned ? ' not-added' : '')
        }
      >
        <div
          className='overlay'
          style={{ backgroundImage }}
        />
        <div className='team-card-header'>
          <div className='title'>
            <p>Team department</p>
            <h3>{team?.name} Team</h3>
          </div>
          <div className='team-card-header-options'>
            <i
              className={
                isFromApplicationComponent && !isOwned
                  ? 'bx bx-plus'
                  : 'bx bx-chevron-right'
              }
              onClick={(e) => {
                if (isFromApplicationComponent && !isOwned) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleAddSoftwareModal(true);
                }
              }}
            ></i>
            {(!isFromApplicationComponent || isOwned) && (
              <>
                <i
                  ref={iconRef}
                  className='icon options pointer'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDropdownOpen(!dropdownOpen);
                  }}
                />
                {dropdownOpen && (
                  <div
                    className='dropdown-menu'
                    ref={dropdownRef}
                  >
                    {dropdownOptions.map((option) => {
                      return (
                        <div
                          key={crypto.randomUUID()}
                          className='dropdown-item'
                          onClick={option?.onClick}
                        >
                          <i className={option?.icon} />
                          {option?.value}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='team-card-body'>
          <div className='info-section'>
            <div className='stats'>
              <label>Software Costs</label>
              <h4>
                € {metric.first}
                <span>{metric.second}</span>
                <i className={`bx bx-trending-${team?.isUp ? 'up' : 'down'}`} />
                <span className='trending'>{team?.rate}%</span>
              </h4>
            </div>
            <div className='stats'>
              <label>Active users</label>
              <h5>
                <span>{users?.length}</span> / {activeUsersLength}
              </h5>
            </div>
          </div>
          <div className='users-list'>
            {users && (
              <>
                {renderUsers(users)}
                {users?.length > 2 && (
                  <div className='user-image nbr'>
                    <div>+{users?.length - 2}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Link>
      {isModalOpen && (
        <UpdateTeamModal
          message={message}
          value={value}
          onConfirm={onConfirm}
          toggleModal={toggleModal}
          initialDetails={initialTeamDetails}
        />
      )}
      {isAddSoftwareApplicationModalOpen && (
        <AddSoftwareApplication
          team={team}
          softwareData={softwareData}
          toggleModal={toggleAddSoftwareModal}
        />
      )}
    </>
  );
}
