export class TokenService {
  static storeAuthData(id, authData) {
    localStorage.setItem(`authData_${id}`, JSON.stringify(authData));
  }

  static getAuthData(id) {
    return JSON.parse(localStorage.getItem(`authData_${id}`));
  }

  static storeToken(id, token) {
    localStorage.setItem(`token_${id}`, JSON.stringify(token));
  }

  static getToken(id) {
    return JSON.parse(localStorage.getItem(`token_${id}`));
  }

  static clearAuthData(id) {
    localStorage.removeItem(`authData_${id}`);
  }

  static clearToken(id) {
    localStorage.removeItem(`token_${id}`);
  }
}
