const wellKnownProviders = [
  'gmail',
  'yahoo',
  'hotmail',
  'outlook',
  'aol',
  'icloud',
  'mail',
  'zoho',
  'protonmail',
  'yandex',
];

export const getCompanyNameFromEmail = (email) => {
  if (!email) {
    return '';
  }

  const domain = email.split('@')[1].split('.')[0];

  if (!wellKnownProviders.includes(domain)) {
    return domain;
  }

  return '';
};
