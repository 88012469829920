import { transformNBLicencesSeries } from './ChartsUtils';

export const getMetric = (metric) => {
  if (isNaN(metric)) {
    return {
      first: 0 + '.',
      second: '00',
    };
  }

  const roundedMetric = Math.round(metric * 1000) / 1000;

  // Split into integer and fractional parts
  const integerPart = Math.floor(roundedMetric);
  const fractionalPart = Math.round((roundedMetric - integerPart) * 1000)
    .toString()
    .padStart(3, '0');
    
  return {
    first: integerPart + '.',
    second: fractionalPart,
  };
};

const members = [
  {
    userName: 'John Doe',
    amount: 1400,
  },
  {
    userName: 'Fedi Bn',
    avatar:
      'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    isActive: true,
    amount: 120,
  },
  {
    userName: 'Fedi Bn 2',
    avatar:
      'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    isActive: true,
    amount: 220,
  },
  {
    avatar:
      'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
    userName: 'Zouhair Morchedi',
    isConnectedUser: true,
    isActive: true,
    amount: 120,
  },
];

export const getSoftwareList = () => {
  return [
    {
      _id: 1,
      imageUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png',
      name: 'Slack',
      description: `Slack is the productivity platform that empowers everyone with no-code automation and AI, makes search and knowledge sharing seamless, and keeps teams connected and engaged. Around the world, Slack is the platform that companies trust and people love to use.`,
      monthlyCosts: 123.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '28 Days',
      paymentType: 'Monthly',
      categories: ['Operations', 'Communication'],
      similar: [
        {
          _id: 4,
        },
      ],
      issuer: {
        name: 'Fedi Ben Nejma',
        imageUrl:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      users: [
        {
          name: 'John Doe',
          monthlyCosts: 1400,
          email: 'joh.doe@gmail.com',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
          isActive: true,
          monthlyCosts: 120,
          email: 'fedibn@gmail.com',
        },
        {
          name: 'Fedi Bn 2',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
          isActive: true,
          monthlyCosts: 220,
          email: 'fedibn@gmail.com',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
          isActive: true,
          monthlyCosts: 120,
          email: 'zouhair.morchedi@gmail.com',
        },
      ],
    },
    {
      _id: 2,
      imageUrl:
        'https://companieslogo.com/img/orig/TEAM-ddb0dd07.png?t=1683437134',
      name: 'Jira - Atlassian',
      description: `Jira est un système de suivi de bugs, de gestion des incidents et de gestion de projets développé par Atlassian et publié pour la première fois en 2002.`,
      monthlyCosts: 223.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '10/10/2024',
      categories: ['Operations', 'Communication'],
      issuer: {
        imageUrl:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
        name: 'Zouhair Morchedi',
        isConnectedUser: true,
      },
      users: [
        {
          name: '',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
      ],
    },
    {
      _id: 3,
      imageUrl: 'https://cdn-icons-png.flaticon.com/512/5968/5968705.png',
      name: 'Figma',
      description: `Figma est un éditeur de graphiques vectoriels et un outil de prototypage. Il est principalement basé sur le web, avec des fonctionnalités hors ligne supplémentaires activées par des applications de bureau pour macOS et Windows.`,
      monthlyCosts: 223.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '10/10/2024',
      categories: ['Design', 'Marketing'],
      issuer: {
        name: 'Fedi Ben Nejma',
        imageUrl:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      users: [
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
        {
          name: '',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
      ],
    },
    {
      _id: 1,
      imageUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png',
      name: 'Slack',
      description: `Slack is the productivity platform that empowers everyone with no-code automation and AI, makes search and knowledge sharing seamless, and keeps teams connected and engaged. Around the world, Slack is the platform that companies trust and people love to use.`,
      monthlyCosts: 123.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '28 Days',
      paymentType: 'Monthly',
      categories: ['Operations', 'Communication'],
      issuer: {
        name: 'Fedi Ben Nejma',
        imageUrl:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      users: [
        {
          name: '',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
      ],
    },
    {
      _id: 2,
      imageUrl:
        'https://companieslogo.com/img/orig/TEAM-ddb0dd07.png?t=1683437134',
      name: 'Jira - Atlassian',
      description: `Jira est un système de suivi de bugs, de gestion des incidents et de gestion de projets développé par Atlassian et publié pour la première fois en 2002.`,
      monthlyCosts: 223.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '10/10/2024',
      categories: ['Operations', 'Communication'],
      issuer: {
        imageUrl:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
        name: 'Zouhair Morchedi',
        isConnectedUser: true,
      },
      users: [
        {
          name: '',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
      ],
    },
    {
      _id: 3,
      imageUrl: 'https://cdn-icons-png.flaticon.com/512/5968/5968705.png',
      name: 'Figma',
      description: `Figma est un éditeur de graphiques vectoriels et un outil de prototypage. Il est principalement basé sur le web, avec des fonctionnalités hors ligne supplémentaires activées par des applications de bureau pour macOS et Windows.`,
      monthlyCosts: 223.75,
      link: 'https://slack.com/intl/en-gb/',
      renewal: '10/10/2024',
      categories: ['Design', 'Marketing'],
      issuer: {
        name: 'Fedi Ben Nejma',
        imageUrl:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      users: [
        {
          name: '',
        },
        {
          name: 'Fedi Bn',
          imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
        },
        {
          imageUrl:
            'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
          name: 'Zouhair Morchedi',
          isConnectedUser: true,
        },
      ],
    },
  ];
};

export const getFullSoftwareList = () => {
  return [
    {
      _id: 1,
      imageUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png',
      name: 'Slack',
      description: `Slack is the productivity platform that empowers everyone with no-code automation and AI, makes search and knowledge sharing seamless, and keeps teams connected and engaged. Around the world, Slack is the platform that companies trust and people love to use.`,
      expenses: 123.75,
      link: 'https://slack.com/intl/en-gb/',
      paymentType: 'Monthly',
      categories: ['Operations', 'Communication'],
      similar: [
        {
          _id: 4,
        },
      ],
    },
    {
      _id: 4,
      imageUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Notion-logo.svg/1200px-Notion-logo.svg.png',
      name: 'Notion',
      description: `Notion is the productivity platform that empowers everyone with no-code automation and AI, makes search and knowledge sharing seamless, and keeps teams connected and engaged. Around the world, Slack is the platform that companies trust and people love to use.`,
      expenses: 123.75,
      link: 'https://slack.com/intl/en-gb/',
      paymentType: 'Monthly',
      categories: ['Operations', 'Communication'],
      similar: [
        {
          _id: 1,
        },
        {
          _id: 5,
        },
      ],
    },
    {
      _id: 5,
      imageUrl: 'https://cdn.worldvectorlogo.com/logos/intercom-2.svg',
      name: 'Intercom',
      description: `Intercom is the productivity platform that empowers everyone with no-code automation and AI, makes search and knowledge sharing seamless, and keeps teams connected and engaged. Around the world, Slack is the platform that companies trust and people love to use.`,
      expenses: 123.75,
      link: 'https://slack.com/intl/en-gb/',
      paymentType: 'Monthly',
      categories: ['Operations', 'Communication'],
      similar: [
        {
          _id: 4,
        },
      ],
    },
    {
      _id: 2,
      imageUrl:
        'https://companieslogo.com/img/orig/TEAM-ddb0dd07.png?t=1683437134',
      name: 'Jira - Atlassian',
      description: `Jira est un système de suivi de bugs, de gestion des incidents et de gestion de projets développé par Atlassian et publié pour la première fois en 2002.`,
      link: 'https://slack.com/intl/en-gb/',
      categories: ['Operations', 'Communication'],
    },
    {
      _id: 3,
      imageUrl: 'https://cdn-icons-png.flaticon.com/512/5968/5968705.png',
      name: 'Figma',
      description: `Figma est un éditeur de graphiques vectoriels et un outil de prototypage. Il est principalement basé sur le web, avec des fonctionnalités hors ligne supplémentaires activées par des applications de bureau pour macOS et Windows.`,
      link: 'https://slack.com/intl/en-gb/',
      categories: ['Design', 'Marketing'],
    },
  ];
};

export const getTeams = () => {
  return [
    {
      _id: 1,
      name: 'Marketing',
      members: members,
      cost: 1520.5,
      owner: {
        avatar:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
        userName: 'Zouhair Morchedi',
        role: 'Tech Lead',
      },
      licences: [...getSoftwareList()],
      image: '/images/01.jpg',
      isAdded: true,
    },
    {
      _id: 2,
      name: 'Design',
      members: members,
      cost: 5520.5,
      owner: {
        userName: 'Fedi Bn',
        avatar:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      licences: [...getSoftwareList()],
      image: '/images/02.jpg',
      isAdded: true,
      manager: 123,
    },
    {
      _id: 3,
      name: 'IT',
      members: members,
      cost: 3520.5,
      owner: {
        userName: 'Fedi Bn',
        avatar:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      licences: [...getSoftwareList()],
      image: '/images/05.jpg',
      isAdded: true,
      manager: 123,
    },
    {
      _id: 4,
      name: 'Finance',
      members: members,
      cost: 520.5,
      owner: {
        userName: 'Fedi Bn',
        avatar:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      licences: [...getSoftwareList()],
      image: '/images/04.jpg',
      isAdded: true,
    },
    {
      _id: 5,
      name: 'Sales',
      members: members,
      cost: 0,
      owner: {
        userName: 'Fedi Bn',
        avatar:
          'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
      },
      licences: [...getSoftwareList()],
      image: '/images/03.jpg',
    },
  ];
};

export const CostHistory = [
  {
    application: 'Slack',
    licences: [
      {
        creationDate: '1-Feb-2022',
        seats: 3,
        avgCostSeat: 20,
        cost: 40,
      },
      {
        creationDate: '1-Mar-2022',
        seats: 4,
        avgCostSeat: 20,
        cost: 20,
      },
      {
        creationDate: '1-Apr-2022',
        seats: 5,
        avgCostSeat: 20,
        cost: 44,
      },
      {
        creationDate: '1-Jan-2023',
        seats: 2,
        avgCostSeat: 30,
        cost: 15,
      },
      {
        creationDate: '1-Feb-2023',
        seats: 2,
        avgCostSeat: 25,
        cost: 35,
      },
      {
        creationDate: '1-Mar-2023',
        seats: 2,
        avgCostSeat: 2,
        cost: 5,
      },
      {
        creationDate: '1-Apr-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 30,
      },
      {
        creationDate: '12-Apr-2023',
        seats: 2,
        avgCostSeat: 60,
        cost: 30,
      },
      {
        creationDate: '1-Nov-2023',
        seats: 2,
        avgCostSeat: 40,
        cost: 45,
      },
      {
        creationDate: '1-Dec-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 20,
      },
      {
        creationDate: '1-Jan-2024',
        seats: 2,
        avgCostSeat: 20,
        cost: 10,
      },
      {
        creationDate: '1-Feb-2024',
        seats: 2,
        avgCostSeat: 20,
        cost: 15,
      },
      {
        creationDate: '1-Mar-2024',
        seats: 2,
        avgCostSeat: 20,
        cost: 30,
      },
      {
        creationDate: '1-Apr-2024',
        seats: 2,
        avgCostSeat: 20,
        cost: 30,
      },
      {
        creationDate: '12-Apr-2024',
        seats: 2,
        avgCostSeat: 60,
        cost: 50,
      },
    ],
  },
  {
    application: 'Notion',
    licences: [
      {
        creationDate: '1-Jan-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 30,
      },
      {
        creationDate: '1-Feb-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 70,
      },
      {
        creationDate: '1-Mar-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 20,
      },
      {
        creationDate: '1-Apr-2023',
        seats: 2,
        avgCostSeat: 20,
        cost: 44,
      },
    ],
  },
];

export const teamsWithLicences = [
  {
    _id: 1,
    name: 'Marketing',
    members: members,
    cost: 1520.5,
    owner: {
      avatar:
        'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
      userName: 'Zouhair Morchedi',
      role: 'Tech Lead',
    },
    licences: [
      {
        application: 'Slack',
        licences: [
          {
            creationDate: '1-Feb-2023',
            seats: 3,
            avgCostSeat: 20,
            cost: 40,
          },
          {
            creationDate: '1-Nov-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Dec-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Apr-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
        ],
      },
      {
        application: 'Notion',
        licences: [
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 70,
          },
        ],
      },
    ],
    image: '/images/01.jpg',
    isAdded: true,
  },
  {
    _id: 2,
    name: 'Design',
    members: members,
    cost: 5520.5,
    owner: {
      userName: 'Fedi Bn',
      avatar:
        'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    },
    licences: [
      {
        application: 'Slack',
        licences: [
          {
            creationDate: '1-Nov-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Dec-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 35,
          },
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 32,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 20,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 3,
            avgCostSeat: 20,
            cost: 10,
          },
          {
            creationDate: '1-Apr-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 15,
          },
        ],
      },
    ],
    image: '/images/02.jpg',
    isAdded: true,
  },
  {
    _id: 3,
    name: 'IT',
    members: members,
    cost: 3520.5,
    owner: {
      userName: 'Fedi Bn',
      avatar:
        'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    },
    licences: [
      {
        application: 'Slack',
        licences: [
          {
            creationDate: '1-Dec-2022',
            seats: 3,
            avgCostSeat: 20,
            cost: 40,
          },
          {
            creationDate: '1-Nov-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 10,
          },
          {
            creationDate: '1-Dec-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 20,
          },
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 38,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Apr-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
        ],
      },
    ],
    image: '/images/05.jpg',
    isAdded: true,
  },
  {
    _id: 4,
    name: 'Finance',
    members: members,
    cost: 520.5,
    owner: {
      userName: 'Fedi Bn',
      avatar:
        'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    },
    licences: [
      {
        application: 'Slack',
        licences: [
          {
            creationDate: '1-Dec-2022',
            seats: 3,
            avgCostSeat: 20,
            cost: 40,
          },
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Apr-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
        ],
      },
      {
        application: 'Notion',
        licences: [
          {
            creationDate: '1-Dec-2023',
            seats: 2,
            avgCostSeat: 20,
            cost: 70,
          },
          {
            creationDate: '1-Jan-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
        ],
      },
    ],
    image: '/images/04.jpg',
    isAdded: true,
  },
  {
    _id: 5,
    name: 'Sales',
    members: members,
    cost: 0,
    owner: {
      userName: 'Fedi Bn',
      avatar:
        'https://media.licdn.com/dms/image/C4D03AQF9qiraU1YRBQ/profile-displayphoto-shrink_800_800/0/1658229020491?e=1722470400&v=beta&t=DJh5IyHXpGCyzQTqic9-nQJnNLTEdYnBkuhdCvcVlsw',
    },
    licences: [
      {
        application: 'Notion',
        licences: [
          {
            creationDate: '1-Feb-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 70,
          },
          {
            creationDate: '1-Mar-2024',
            seats: 2,
            avgCostSeat: 20,
            cost: 30,
          },
        ],
      },
    ],
    image: '/images/03.jpg',
  },
];

export const getFullWidgetList = () => {
  return [
    {
      id: 1,
      type: 'spending',
      data: {
        title: 'Total spending',
        subtitle: 'Since 01 Nov',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
        metric: 1267.5,
        rate: '49%',
        isRateGreen: true,
        average: '-€623.500 ',
        linkToReport: '',
      },
    },
    {
      id: 2,
      type: 'subscription',
      data: {
        title: 'Subscriptions',
        subtitle: 'Number of licences',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
        metric: {
          value: 0,
          label: 'Software used',
        },
        rate: '-6',
        isRateGreen: true,
        average: 'Avg 8 seats / software ',
        linkToReport: '',
      },
    },
    {
      id: 4,
      type: 'analytics',
      data: {
        chart: 'NBLicencesChart',
        title: 'NB Licences evolution',
        subtitle: '',
        app: 'Slack',
        appData: {
          series: transformNBLicencesSeries(CostHistory)['Slack'],
          height: 150,
        },
      },
    },
  ];
};

export const getUserWidgetList = () => {
  return [
    {
      id: 1,
      type: 'spending',
      data: {
        title: 'Total spending',
        subtitle: 'Since 01 Nov',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
        metric: 1267.5,
        rate: '49%',
        isRateGreen: true,
        average: '-€623.500 ',
        linkToReport: '',
      },
    },
    {
      id: 2,
      type: 'subscription',
      data: {
        title: 'Subscriptions',
        subtitle: 'Number of licences',
        icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
        metric: {
          value: 12,
          label: 'Software used',
        },
        rate: '-6',
        isRateGreen: true,
        average: 'Avg 8 seats / software ',
        linkToReport: '',
      },
    },

    { id: 33, type: 'empty', data: {} },
    { id: 44, type: 'empty', data: {} },
  ];
};

export const getWidgetPlaceHolders = () => [
  {
    type: 'spending',
  },
  {
    type: 'subscription',
  },
  {
    type: 'analytics',
    data: {
      chart: 'NBLicencesChart',
      title: 'NB Licences evolution',
      subtitle: '',
      app: 'Slack',
      appData: {
        series: [
          {
            data: [
              { x: 'Nov', y: 2, tooltip: 45 },
              { x: 'Dec', y: 2, tooltip: 20 },
              { x: 'Jan', y: 2, tooltip: 10 },
            ],
          },
        ],
        height: 150,
      },
    },
  },
  {
    type: 'analytics',
    data: {
      chart: 'NBLicencesChart',
      title: 'NB Licences evolution',
      subtitle: '',
      app: 'Slack',
      appData: {
        series: [
          {
            data: [
              { x: 'Nov', y: 2, tooltip: 45 },
              { x: 'Dec', y: 2, tooltip: 20 },
              { x: 'Jan', y: 2, tooltip: 10 },
            ],
          },
        ],
        height: 150,
      },
    },
  },
];

export const getSpendingData = () => ({
  type: 'spending',
  title: 'Total spending',
  subtitle: 'Since 01 Nov',
  icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
  metric: 1267.5,
  rate: '49%',
  isRateGreen: true,
  average: '-€623.500 ',
  linkToReport: '',
});

export const getSubscriptionData = () => ({
  type: 'subscription',
  title: 'Subscriptions',
  subtitle: 'Number of licences',
  icon: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
  metric: {
    value: 12,
    label: 'Software used',
  },
  rate: '-6',
  isRateGreen: true,
  average: 'Avg 8 seats / software ',
  linkToReport: '',
});

export const getAnalyticsData = () => ({
  chart: 'NBLicencesChart',
  title: 'NB Licences evolution',
  subtitle: '',
  app: 'Slack',
  appData: {
    series: [
      {
        data: [
          { x: 'Nov', y: 2, tooltip: 45 },
          { x: 'Dec', y: 2, tooltip: 20 },
          { x: 'Jan', y: 2, tooltip: 10 },
        ],
      },
    ],
    height: 150,
  },
});

export const getAchievementData = () => ({
  goals: [
    {
      type: 'Budget',//'financial goal',
      goalValue: 250,
      unit: '€',
      period: 'annual budget',//'annual growth',
      currentValue: 180,
    },
  ],
  events: [
    {
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png',
      title: 'AWS Service',
      date: '1 hour ago',
      numericValue: 250,
      unit: '€',
    },
    {
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png',
      title: 'AWS Service',
      date: '1 hour ago',
      numericValue: 250,
      unit: '€',
    },
    {
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png',
      title: 'AWS Service',
      date: '1 hour ago',
      numericValue: 250,
      unit: '€',
    },
  ],
});

export const commonOptions = {
  legend: {
    horizontalAlign: 'right',
    position: 'top',
    markers: {
      width: 15,
      height: 15,
      strokeColor: '#D0CFCF',
      radius: 20,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    foreColor: '#bababa99',
  },
  colors: ['#CCA57B'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    lineCap: 'round',
    width: 1.5,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    lines: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    tickAmount: 3,
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
      },
    },
  },
  grid: {
    position: 'back',
    borderColor: '#bababa99',
    strokeDashArray: 10,
  },
};

export const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

export const getChartTypes = () => [
  'analytics-cost-history',
  'analytics-nb-licences',
  'analytics-all-saas-costs',
  'analytics-seats-licences-global',
  'analytics-saas-costs-per-team',
  'analytics-seats-licences-per-team',
  'analytics-cost-difference',
  'analytics-comparison',
];

export const getChartName = (type) => {
  const chartNames = {
    'analytics-cost-history': 'Cost History',
    'analytics-nb-licences': 'Licences Evolution',
    'analytics-all-saas-costs': 'All SaaS Costs',
    'analytics-seats-licences-global': 'Seats Licences Global',
    'analytics-saas-costs-per-team': 'SaaS Costs Per Team',
    'analytics-seats-licences-per-team': 'Seats Licences Per Team',
    'analytics-cost-difference': 'Cost Difference',
    'analytics-comparison': 'Comparison',
  };

  return chartNames[type];
};

export const getNotifications = () => [
  {
    thumbnail:
      'https://res.cloudinary.com/dg91u71x5/image/upload/v1699564783/lc4rzgce8iy4reumntvj.jpg',
    user: {
      name: 'Zouhair',
    },
    team: {
      name: 'Marketing',
    },
    timestamp: '4 hours ago • 12:40',
    reactions: [
      {
        icon: 'https://images.emojiterra.com/mozilla/512px/1f389.png',
        name: 'thumbsUp',
        users: [
          { name: '' },
          { name: '' },
          { name: '' },
          { name: '' },
          { name: '' },
        ],
      },
      {
        icon: 'https://cdn-icons-png.flaticon.com/512/10413/10413827.png',
        name: 'heart',
        users: [{ name: '' }, { name: '' }],
      },
    ],
    type: 'add-user-to-team',
    isSeen: false,
  },
  {
    thumbnail:
      'https://seeklogo.com/images/P/postman-logo-0087CA0D15-seeklogo.com.png',
    software: {
      name: 'Postman',
    },
    team: {
      name: 'Dev',
    },
    timestamp: '4 hours ago • 12:40',
    type: 'add-team-software',
    isSeen: false,
  },
  {
    thumbnail: 'https://webuildthemes.com/go/assets/images/demo/user-4.jpg',
    user: {
      name: 'Slah',
    },
    team: {
      name: 'Marketing',
    },
    timestamp: '4 hours ago • 12:40',
    type: 'set-team-manager',
    isSeen: false,
  },
  {
    thumbnail:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Microsoft_Azure.svg/2048px-Microsoft_Azure.svg.png',
    software: {
      name: 'Azure',
    },
    value: '10 sept',
    timestamp: '4 hours ago • 12:40',
    type: 'renewal-date-reminder',
    isSeen: true,
  },
  {
    thumbnail: 'https://www.notion.so/images/meta/notion-wordmark.png',
    software: {
      name: 'Notion',
    },
    value: '160 €',
    timestamp: '4 hours ago • 12:40',
    type: 'subscription-paid',
    isSeen: true,
  },
  {
    thumbnail: 'https://www.notion.so/images/meta/notion-wordmark.png',
    software: {
      name: 'Notion',
    },
    value: '160 €',
    timestamp: '4 hours ago • 12:40',
    type: 'subscription-paid',
    isSeen: true,
  },
];

//? Subscription widget animation code:
// let startX, startY;
// const [direction, setDirection] = useState('');
// const directionRef = useState();

// useEffect(() => {
//   return;
//   document.addEventListener('mousemove', (e) => {
//     const endX = e.clientX;
//     const endY = e.clientY;

//     const deltaX = endX - startX;
//     const deltaY = endY - startY;

//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       if (deltaX > 0 && directionRef.current !== 'right') {
//         setDirection('right');
//         console.log('right');
//         directionRef.current = 'right';
//       } else if (directionRef.current !== 'left') {
//         setDirection('left');
//         directionRef.current = 'left';
//         console.log('left');
//       }
//     } else {
//       //setDirection('left')
//     }
//   });

//   document.addEventListener('mouseup', (e) => {
//     setDirection('');
//     startX = 0;
//     startY = 0;
//   });
// });

// const startMouse = (e) => {
//   startX = e.clientX;
//   startY = e.clientY;
// };
