import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import useAuthRedirect from '../hooks/useAuthRedirect';
import isValidToken from '../util/isValidToken';

export default function StartContainer() {
  
  const { token } = useSelector((state) => state.user);

  useAuthRedirect('/dashboard');

  if (!isValidToken(token)) {
    return (
      <div className='start-container'>
        <div className='auth-card-container'>
          <div className='logo-container'>
            <img
              alt='Reknah logo'
              src='https://reknah.com/reknah_logo.png'
            />
          </div>
          <Outlet />
        </div>
      </div>
    );
  }
}
