import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFolders } from '../../../services/api/folder/fetch';
import {
  addFolder,
  deleteFolder,
  updateFolderName,
} from '../../../services/api/folder/modify';

export const getAllFoldersAction = createAsyncThunk(
  'folder/getAllFolders',
  async (_, { rejectWithValue }) => {
    try {
      const folders = await getAllFolders();

      return folders;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addFolderAction = createAsyncThunk(
  'folder/addFolder',
  async (name, { rejectWithValue }) => {
    try {
      
      const folder = await addFolder(name);

      return folder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFolderAction = createAsyncThunk(
  'folder/deleteFolder',
  async (id, { rejectWithValue }) => {
    try {
      
      const folder = await deleteFolder(id);

      return folder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFolderNameAction = createAsyncThunk(
  'folder/updateFolderName',
  
  async ({ folderId, name }, { rejectWithValue }) => {
    try {
      const response = await updateFolderName({ folderId, name });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
