import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserWidgetArrayAction } from '../../redux/features/widgets/widgetThunks';
import { getWidgetTypes } from '../../services/widget/get';
import WidgetRenderer from './WidgetRenderer';

export default function AddWidgetsModal(props) {
  const dispatch = useDispatch();

  const { toggleModal, emptyCardIndex } = props;
  
  const { widgets } = useSelector((state) => state.widgets);
  
  const { user } = useSelector((state) => state.user);

  const widgetPlaceHolders = getWidgetTypes().map((type) => ({ type }));

  const addWidget = (type) => {
    const tempId = crypto.randomUUID();
    const widgetToAdd = { _id: tempId, type, user: user?._id };

    const newWidgets = widgets.map((widget) => {
      if (widget._id === emptyCardIndex) {
        return widgetToAdd;
      } else {
        return widget;
      }
    });

    
    dispatch(updateUserWidgetArrayAction({ newWidgets, oldWidgets: widgets }));

    toggleModal(false, -1);
  };

  const renderContent = () => {
    return (
      <div className='dashboard-grid'>
        {widgetPlaceHolders.map((widget) => {
          return (
            <div
              key={widget.type}
              className='container'
              onClick={() => {
                addWidget(widget.type);
              }}
            >
              <WidgetRenderer widget={widget} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false, -1)}
      />
      <div className='modal-container add-widgets'>
        <div className='modal'>
          <div className='titles'>
            <h3>New Widget</h3>
            <p>Add and configure a new widget to your dashboard</p>
          </div>
          <div className='modal-content'>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}
