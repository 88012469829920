import isValidToken from '../../util/isValidToken';
import axios from './axiosConfig';

export class AuthService {
  static async connect(endpoint) {
    try {
      const response = await axios.get(endpoint);
      const data = response.data;
      window.location.href = data.authUrl;
    } catch (error) {
      console.log(error);
      console.error('Error during sign-in:', error);
    }
  }
}

export class AzureSignin {
  static async connect(endpoint) {
    try {
      const response = await axios.get(endpoint);
      
      console.log('here')
      return response;
    } catch (error) {
      console.log(error);
      console.error('Error during sign-in:', error);
    }
  }
}

