import { createSlice } from '@reduxjs/toolkit';
import { getNewWidgetsAfterDelete } from '../../../services/widget/get';
import {
  rehydrateWidgetData,
  rehydrateWidgets,
} from '../../../services/widget/rehydrate';
import {
  addWidgetAction,
  deleteWidgetAction,
  getAllUserWidgetsAction,
  getWidgetDataByTypeAction,
  updateUserWidgetArrayAction,
} from './widgetThunks';

const initialState = {
  widgets: rehydrateWidgets(),
  widgetData: rehydrateWidgetData(),
};

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserWidgetsAction.fulfilled, (state, action) => {
        console.log('widgets here')
        state.widgets = action.payload;
      })
      .addCase(getWidgetDataByTypeAction.fulfilled, (state, action) => {
        const type = action.payload.type;
        state.widgetData[type] = action.payload;
      })
      .addCase(addWidgetAction.fulfilled, (state, action) => {
        state.widgets.push(action.payload);
      })
      .addCase(deleteWidgetAction.pending, (state, action) => {
        
        const { widgetId } = action.meta.arg;

        state.widgets = getNewWidgetsAfterDelete(widgetId, state.widgets);
      })
      .addCase(deleteWidgetAction.rejected, (state, action) => {
        
        const { oldWidgets } = action.meta.arg;

        state.widgets = oldWidgets;
      })
      .addCase(updateUserWidgetArrayAction.pending, (state, action) => {
        
        const { newWidgets } = action.meta.arg;

        state.widgets = newWidgets;
      })
      .addCase(updateUserWidgetArrayAction.rejected, (state, action) => {
        
        const { oldWidgets } = action.meta.arg;

        state.widgets = oldWidgets;
      });
  },
});

export default widgetsSlice.reducer;
