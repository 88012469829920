import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { commonOptions } from '../../../util/UtilFunctions';
import DropdownMenu from '../DropdownMenu';

export default function SaasCostsPerTeam({
  series,
  teams,
  selectedView,
  height,
}) {
  const [selectedItem, setSelectedItem] = useState(() => teams[0]);

  useEffect(() => {
    if (teams.length > 0) {
      setSelectedItem(teams[0]);
    }
  }, [teams]);

  const seriesData = useMemo(() => {
    return series?.[selectedView]?.[selectedItem?._id] ?? [];
  }, [series, selectedView, selectedItem]);

  const isLoading = useMemo(() => {
    return !series?.[selectedView];
  }, [series, selectedView]);

  const options = {
    ...commonOptions,
    tooltip: {
      y: {
        title: {
          formatter: () => 'Number of Licences',
        },
        formatter: function (_, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ].tooltip;
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Skeleton
        height={height}
        baseColor='#333'
        highlightColor='#444'
      />
    );
  }

  return (
    <>
      <DropdownMenu
        dropdownItems={teams}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <ReactApexChart
        
        options={options}
        series={seriesData}
        type='line'
        height={height}
      />
    </>
  );
}
