import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/folder';

export const addFolder = async ({ name }) => {
  try {
    const response = await axios.post(`${prefix}`, {
      name,
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteFolder = async ({ folderId }) => {
  try {
    const response = await axios.delete(`${prefix}/${folderId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateFolderName = async ({ folderId, name }) => {
  try {
    const response = await axios.put(`${prefix}/name/${folderId}`, {
      name,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
