import { createSlice } from '@reduxjs/toolkit';
import {
  rehydrateToken,
  rehydrateUser,
  rehydrateUserData,
  rehydrateUserSlugToId,
} from '../../../services/user/rehydrate';
import updateUserDataObject from '../../../services/user/updateUserDataObject';
import mergeObjects from '../../../util/mergeObjects';
import {
  addCompanyAction,
  getCompanyDataAction,
} from '../company/companyThunks';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
} from '../software/softwareThunks';
import {
  addUsersToTeamAction,
  createUserFromInviteAction,
  getPendingUsersByTeamAction,
} from '../teams/teamsThunks';
import {
  getMultipleUserDataAction,
  getUserProfileDataAction,
  googleLoginAction,
  loginAction,
  registerAction,
  updateTokenAction,
  verifyUserAction,
  verifyUserFromProviderAction,
  verifyUserFromTeamInviteAction,
} from './userThunks';

const initialState = {
  user: rehydrateUser(),
  userData: rehydrateUserData(),
  userSlugToId: rehydrateUserSlugToId(),
  allCostsData: {},
  costsDataByTeam: {},
  profileData: {},
  token: rehydrateToken(),
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loginAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(googleLoginAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.accessToken;
        state.error = null;
      })
      .addCase(googleLoginAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getMultipleUserDataAction.fulfilled, (state, action) => {
        updateUserDataObject(state, action.payload);
        localStorage.setItem('userData', JSON.stringify(state.userData));
      })
      .addCase(getCompanyDataAction.fulfilled, (state, action) => {
        const { company, companyUserData } = action.payload;

        state.user.company = company._id;

        updateUserDataObject(state, companyUserData);
        localStorage.setItem('userData', JSON.stringify(state.userData));
        localStorage.setItem('user', JSON.stringify(state.user));
      })
      .addCase(getTrackedSoftwareDataAction.fulfilled, (state, action) => {
        const { allUserCostsData } = action.payload;
        state.allCostsData = allUserCostsData;
      })
      .addCase(
        getDynamicTrackedSoftwareDataByTeamAction.fulfilled,
        (state, action) => {
          const { userCostsDataByTeam: costsDataByTeam } = action.payload;
          state.costsDataByTeam = costsDataByTeam;
        }
      )
      .addCase(addUsersToTeamAction.fulfilled, (state, action) => {
        
        const { teamId, userIds } = action.meta.arg;

        const allCostsData = { ...state.allCostsData };
        const costsDataByTeam = { ...state.costsDataByTeam };

        userIds.forEach((userId) => {
          if (!allCostsData[userId]) {
            allCostsData[userId] = {
              monthlyCosts: null,
              activeSoftwareCount: null,
              totalSoftwareCount: null,
            };
          }

          if (!costsDataByTeam[teamId]) {
            costsDataByTeam[teamId] = {};
          }

          if (!costsDataByTeam[teamId][userId]) {
            costsDataByTeam[teamId][userId] = {
              monthlyCosts: null,
              activeSoftwareCount: null,
              totalSoftwareCount: null,
            };
          }

          state.allCostsData = mergeObjects(state.allCostsData, allCostsData);
          state.costsDataByTeam = mergeObjects(
            state.costsDataByTeam,
            costsDataByTeam
          );
        });
      })
      .addCase(getUserProfileDataAction.fulfilled, (state, action) => {
        const data = action.payload;
        const { user, costsBySoftware, totalCostsBySoftware } = data;

        state.profileData[user._id] = {
          ...user,
          costsBySoftware,
          totalCostsBySoftware,
        };
      })
      .addCase(verifyUserAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(verifyUserAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(verifyUserFromTeamInviteAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(verifyUserFromTeamInviteAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(verifyUserFromProviderAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(verifyUserFromProviderAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(addCompanyAction.fulfilled, (state, action) => {
        const { company, accessToken: token } = action.payload;

        state.user.company = company._id;
        state.user.managedCompany = company._id;

        state.token = token;

        localStorage.setItem('user', JSON.stringify(state.user));
        localStorage.setItem('token', token);

        state.error = null;
      })
      .addCase(updateTokenAction.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(getPendingUsersByTeamAction.fulfilled, (state, action) => {
        const { userData } = action.payload;

        state.userData = mergeObjects(state.userData, userData);
      })
      .addCase(createUserFromInviteAction.pending, (state, action) => {
        
        const { userId, email } = action.meta.arg;

        const name = email.split('@')[0];

        state.userData[userId] = {
          _id: userId,
          name,
          email,
          isPending: true,
        };
      })
      .addCase(createUserFromInviteAction.rejected, (state, action) => {
        
        const { userId } = action.meta.arg;

        const userData = { ...state.userData };
        delete userData[userId];

        state.userData = userData;
      }),
});

export const { logoutUser } = userSlice.actions;

export default userSlice.reducer;
