import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router';
import { useUserDropdown } from '../../hooks/Dropdown/useUserDropdown';
import UpdateUserModal from '../Modals/Update/UpdateUserModal';

export default function UserRow(props) {
  const { user, teamId, index } = props;

  const navigate = useNavigate();

  const {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    setDropdownOpen,
    toggleModal,
    dropdownOptions,
  } = useUserDropdown(user, teamId);

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  console.log('user dis', user, user.isExtensionInstalled)
  return (
    <>
      <div
        className='row'
        key={user.userId}
        onClick={() => navigate(`/dashboard/profile/${user._id}`)}
      >
        <div className='element'>
          <div
            className='icon avatar'
            style={{
              backgroundImage: user?.imageUrl ? `url(${user?.imageUrl})` : `url(/avatars/${(index % 7) + 1}.jpg)`,
            }}
          />
          <div className='title'>
            {user?.name || (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>
          <div style={{ textTransform: 'lowercase' }}>
            {user?.email || (
              <Skeleton
                width={150}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>
          <div>
            {user?.monthlyCosts != null ? (
              `€${user.monthlyCosts.toFixed(3)}`
            ) : (
              <Skeleton
                width={50}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>
        {user?.activeSoftwareCount != null &&
          user?.totalSoftwareCount != null ? (
            `${user.activeSoftwareCount}/${user.totalSoftwareCount}`
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
         
        </div>

        <div className='element'>
        <div className={`user-activity ${!user.isExtensionInstalled ? 'not-active' : 'active'}`}>
            {user?.isExtensionInstalled != null ? (
              `${user.isExtensionInstalled ? 'Installed' : 'Not Installed'}`
            ) : (
              <Skeleton
                width={50}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
          <div className='element'>
            <i
              ref={iconRef}
              className='icon options pointer'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDropdownOpen(!dropdownOpen);
              }}
            />
          </div>
          {dropdownOpen && (
            <div
              ref={dropdownRef}
              className='dropdown-menu'
            >
              {dropdownOptions.map((option) => (
                <div
                  key={crypto.randomUUID()}
                  className='dropdown-item'
                  onClick={option?.onClick}
                >
                  <i className={option?.icon} />
                  {option?.value}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <UpdateUserModal
          message={message}
          value={value}
          onConfirm={onConfirm}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
}
