import React from 'react';
import { ListManager } from 'react-beautiful-dnd-grid';
import Skeleton from 'react-loading-skeleton';

const LoadingWidgets = () => {
  const items = Array(4)
    .fill()
    .map(() => ({ id: crypto.randomUUID() }));

  return (
    <div className='dashboard-grid'>
      <div className='widgets'>
        <ListManager
          items={items}
          direction='horizontal'
          maxItems={2}
          render={(item) => (
            <div
              key={item.id}
              className='container'
            >
              <div className='card'>
                <Skeleton
                  height={200}
                  baseColor='#333'
                  highlightColor='#444'
                />
              </div>
            </div>
          )}
          onDragEnd={() => {}}
        />
      </div>
    </div>
  );
};

export default LoadingWidgets;
