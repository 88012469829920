import React from 'react';

export default function UpdateUserModal(props) {
  const { message, value, onConfirm, toggleModal } = props;

  const onCancel = () => {
    toggleModal(false);
  };

  const renderContent = () => {
    switch (value) {
      case 'remove user':
        return <p className='titles'>{message}</p>;

      default:
        return <p className='titles'>{message}</p>;
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={onCancel}
      />
      <div className='modal-container confirmation'>
        <div className='modal'>
          {renderContent()}
          <div className='next-btn-container'>
            <button
              className='next-btn previous'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='next-btn'
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
