import React, { createContext, useContext, useState } from "react";
import Alert from "../components/Alert";

const AlertContext = createContext(undefined);

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(undefined);
  const [type, setType] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const showAlert = (content, alertType) => {
    setAlert(content);
    setType(alertType);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
      setAlert(undefined);
      setType(undefined);
    }, 5000);
  };

  const closeAlert = () => {
    setIsVisible(false);
    setAlert(undefined);
    setType(undefined);
  };

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      {alert && isVisible && (
        <Alert content={alert} type={type} onClose={closeAlert} />
      )}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert not in AlertProvider");
  }
  return context;
};

export { AlertProvider, useAlert };
