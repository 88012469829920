import { useRef, useCallback } from "react";

export const useMouseDragScroll = () => {
  const containerRef = useRef(null);
  const isDraggingRef = useRef(false); // Tracks dragging state
  const startXRef = useRef(0); // Tracks starting X position
  const scrollLeftRef = useRef(0); // Tracks the initial scroll position

  const handleMouseDown = useCallback((e) => {
    if (!containerRef.current) return;
    isDraggingRef.current = true;
    startXRef.current = e.pageX - containerRef.current.offsetLeft;
    scrollLeftRef.current = containerRef.current.scrollLeft;
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDraggingRef.current || !containerRef.current) return;
    e.preventDefault(); // Prevent text selection during drag
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startXRef.current;
    containerRef.current.scrollLeft = scrollLeftRef.current - walk;
  }, []);

  const handleMouseUpOrLeave = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  const attachDragHandlers = () => ({
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUpOrLeave,
    onMouseLeave: handleMouseUpOrLeave,
  });

  return { containerRef, attachDragHandlers };
};
