import { createSlice } from '@reduxjs/toolkit';
import {
  rehydrateAvailableSoftwareIds,
  rehydrateCompanySoftwareIds,
  rehydrateExcludedSoftwareIds,
  rehydrateMostPopularSoftwareIds,
  rehydrateSoftwareSlugToId,
  rehydrateStaticSoftwareData,
  rehydrateTrackedSoftwareIds,
} from '../../../services/software/rehydrate';
import updateSoftwareStateData from '../../../services/software/updateSoftwareStateData';
import { deleteTeamAction } from '../teams/teamsThunks';
import {
  deactivateLicenceAction,
  deleteLicenceAction,
  deleteTeamSoftwareAction,
  deleteTeamSoftwareForMultipleTeamsAction,
  getCompanySoftwareAction,
  getDynamicTrackedSoftwareDataByTeamAction,
  getMostPopularSoftwareByLicenceCountAction,
  getMultipleTeamsAvailableSoftwareAction,
  getSoftwareByIdListAction,
  getTotalInvoicesCostBySoftwateAction,
  getTrackedSoftwareDataAction,
  renewLicenceAction,
  updateLicencesAction,
  updateLicencesForMultipleTeamsAction,
} from './softwareThunks';

const initialState = {
  trackedSoftwareIds: rehydrateTrackedSoftwareIds(),
  availableSoftwareIds: rehydrateAvailableSoftwareIds(),
  excludedSoftwareIds: rehydrateExcludedSoftwareIds(),
  mostPopularSoftwareIds: rehydrateMostPopularSoftwareIds(),
  companySoftwareIds: rehydrateCompanySoftwareIds(),
  softwareSlugToId: rehydrateSoftwareSlugToId(),
  staticSoftwareData: rehydrateStaticSoftwareData(),
  allDynamicSoftwareData: {},
  trackedSoftwareDataByTeam: {},
  trackedSoftwareIdsByTeam: {},
  userMonthlyCostsBySoftware: {},
  userIds: [], //? The users that use the software
};

const softwareSlice = createSlice({
  name: 'software',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getTrackedSoftwareDataAction.fulfilled, (state, action) => {
        updateSoftwareStateData(state, action.payload);
      })
      .addCase(
        getDynamicTrackedSoftwareDataByTeamAction.fulfilled,
        (state, action) => {
          const { trackedSoftwareDataByTeam, trackedSoftwareIdsByTeam } =
            action.payload;
          state.trackedSoftwareDataByTeam = trackedSoftwareDataByTeam;
          state.trackedSoftwareIdsByTeam = trackedSoftwareIdsByTeam;
        }
      )
      .addCase(
        getMultipleTeamsAvailableSoftwareAction.fulfilled,
        (state, action) => {
          updateSoftwareStateData(state, action.payload);
        }
      )
      .addCase(
        getMostPopularSoftwareByLicenceCountAction.fulfilled,
        (state, action) => {
          updateSoftwareStateData(state, action.payload);
        }
      )
      .addCase(getCompanySoftwareAction.fulfilled, (state, action) => {
        updateSoftwareStateData(state, action.payload);
      })
      .addCase(deleteTeamSoftwareAction.pending, (state, action) => {
        
        const { softwareId, teamId } = action.meta.arg;

        delete state.trackedSoftwareDataByTeam[teamId][softwareId];

        state.trackedSoftwareIdsByTeam[teamId] = state.trackedSoftwareIdsByTeam[
          teamId
        ].filter((id) => id !== softwareId);
      })
      .addCase(deleteTeamSoftwareAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
        state.trackedSoftwareIdsByTeam = prevState.trackedSoftwareIdsByTeam;
      })
      .addCase(
        deleteTeamSoftwareForMultipleTeamsAction.pending,
        (state, action) => {
          
          const { softwareId } = action.meta.arg;

          state.trackedSoftwareIds = state.trackedSoftwareIds.filter(
            (id) => id !== softwareId
          );

          delete state.allDynamicSoftwareData[softwareId];

          state.trackedSoftwareDataByTeam = Object.keys(
            state.trackedSoftwareDataByTeam
          ).reduce((acc, teamId) => {
            delete acc[teamId][softwareId];
            return acc;
          }, state.trackedSoftwareDataByTeam);

          state.trackedSoftwareIdsByTeam = Object.keys(
            state.trackedSoftwareDataByTeam
          ).reduce((acc, teamId) => {
            acc[teamId] = state.trackedSoftwareIdsByTeam[teamId].filter(
              (id) => id !== softwareId
            );
            return acc;
          }, {});
        }
      )
      .addCase(
        deleteTeamSoftwareForMultipleTeamsAction.rejected,
        (state, action) => {
          
          const { prevState } = action.meta.arg;

          state.trackedSoftwareIds = prevState.trackedSoftwareIds;
          state.allDynamicSoftwareData = prevState.allDynamicSoftwareData;
          state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
          state.trackedSoftwareIdsByTeam = prevState.trackedSoftwareIdsByTeam;
        }
      )
      .addCase(updateLicencesAction.pending, (state, action) => {
        
        const { softwareId, teamId, updatedDetails } = action.meta.arg;

        const { paymentType } = updatedDetails;

        const oldPaymentData =
          state.trackedSoftwareDataByTeam[teamId][softwareId].paymentData;

        const newPaymentData = oldPaymentData.map((data) => ({
          ...data,
          paymentType,
        }));

        state.trackedSoftwareDataByTeam[teamId][softwareId].paymentData =
          newPaymentData;
      })
      .addCase(updateLicencesAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
      })
      .addCase(
        updateLicencesForMultipleTeamsAction.pending,
        (state, action) => {
          
          const { softwareId, updatedDetails } = action.meta.arg;

          const { paymentType } = updatedDetails;

          const oldPaymentData =
            state.allDynamicSoftwareData[softwareId].paymentData;

          const newPaymentData = oldPaymentData.map((data) => ({
            ...data,
            paymentType,
          }));

          state.allDynamicSoftwareData[softwareId].paymentData = newPaymentData;

          Object.keys(state.trackedSoftwareDataByTeam).forEach((teamId) => {
            if (state.trackedSoftwareDataByTeam[teamId][softwareId]) {
              const oldPaymentData =
                state.trackedSoftwareDataByTeam[teamId][softwareId].paymentData;

              const newPaymentData = oldPaymentData.map((data) => ({
                ...data,
                paymentType,
              }));

              state.trackedSoftwareDataByTeam[teamId][softwareId].paymentData =
                newPaymentData;
            }
          });
        }
      )
      .addCase(
        updateLicencesForMultipleTeamsAction.rejected,
        (state, action) => {
          
          const { prevState } = action.meta.arg;

          state.allDynamicSoftwareData = prevState.allDynamicSoftwareData;
          state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
        }
      )
      .addCase(deactivateLicenceAction.pending, (state, action) => {
        
        const { softwareId, teamId, licenceId } = action.meta.arg;

        state.allDynamicSoftwareData[softwareId].userMonthlyCosts =
          state.allDynamicSoftwareData[softwareId].userMonthlyCosts.map(
            (data) => {
              if (data.licenceId === licenceId) {
                return {
                  ...data,
                  isActive: false,
                  lastActivity: 'Manually Deactivated',
                };
              }
              return data;
            }
          );

        state.trackedSoftwareDataByTeam[teamId][softwareId].userMonthlyCosts =
          state.trackedSoftwareDataByTeam[teamId][
            softwareId
          ].userMonthlyCosts.map((data) => {
            if (data.licenceId === licenceId) {
              return {
                ...data,
                isActive: false,
                lastActivity: 'Manually Deactivated',
              };
            }
            return data;
          });
      })
      .addCase(deactivateLicenceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.allDynamicSoftwareData = prevState.allDynamicSoftwareData;
        state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
      })
      .addCase(renewLicenceAction.pending, (state, action) => {
        
        const { softwareId, teamId, licenceId } = action.meta.arg;

        state.allDynamicSoftwareData[softwareId].userMonthlyCosts =
          state.allDynamicSoftwareData[softwareId].userMonthlyCosts.map(
            (data) => {
              if (data.licenceId === licenceId) {
                return {
                  ...data,
                  isActive: true,
                  lastActivity: new Date(),
                };
              }
              return data;
            }
          );

        state.trackedSoftwareDataByTeam[teamId][softwareId].userMonthlyCosts =
          state.trackedSoftwareDataByTeam[teamId][
            softwareId
          ].userMonthlyCosts.map((data) => {
            if (data.licenceId === licenceId) {
              return {
                ...data,
                isActive: true,
                lastActivity: new Date(),
              };
            }
            return data;
          });
      })
      .addCase(renewLicenceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.allDynamicSoftwareData = prevState.allDynamicSoftwareData;
        state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
      })
      .addCase(getSoftwareByIdListAction.fulfilled, (state, action) => {
        updateSoftwareStateData(state, action.payload);
      })
      .addCase(deleteLicenceAction.pending, (state, action) => {
        
        const { softwareId, teamId, licenceId } = action.meta.arg;

        state.allDynamicSoftwareData[softwareId].userMonthlyCosts =
          state.allDynamicSoftwareData[softwareId].userMonthlyCosts.filter(
            (data) => data.licenceId !== licenceId
          );

        state.trackedSoftwareDataByTeam[teamId][softwareId].userMonthlyCosts =
          state.trackedSoftwareDataByTeam[teamId][
            softwareId
          ].userMonthlyCosts.filter((data) => data.licenceId !== licenceId);
      })
      .addCase(deleteLicenceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.allDynamicSoftwareData = prevState.allDynamicSoftwareData;
        state.trackedSoftwareDataByTeam = prevState.trackedSoftwareDataByTeam;
      })
      .addCase(deleteTeamAction.pending, (state, action) => {
        
        const { teamId } = action.meta.arg;

        delete state.trackedSoftwareDataByTeam[teamId];
        delete state.trackedSoftwareIdsByTeam[teamId];

        Object.keys(state.allDynamicSoftwareData).forEach((softwareId) => {
          state.allDynamicSoftwareData[softwareId].teams =
            state.allDynamicSoftwareData[softwareId].teams.filter(
              (team) => team.teamId !== teamId
            );

          if (state.allDynamicSoftwareData[softwareId].teams.length === 0) {
            delete state.allDynamicSoftwareData[softwareId];
            delete state.staticSoftwareData[softwareId];

            state.trackedSoftwareIds = state.trackedSoftwareIds.filter(
              (id) => id !== softwareId
            );
          }
        });
      })
      .addCase(deleteTeamAction.rejected, (state, action) => {
        
        const { prevSoftwareState } = action.meta.arg;

        state.trackedSoftwareDataByTeam =
          prevSoftwareState.trackedSoftwareDataByTeam;
        state.trackedSoftwareIdsByTeam =
          prevSoftwareState.trackedSoftwareIdsByTeam;
        state.allDynamicSoftwareData = prevSoftwareState.allDynamicSoftwareData;
        state.staticSoftwareData = prevSoftwareState.staticSoftwareData;
        state.trackedSoftwareIds = prevSoftwareState.trackedSoftwareIds;
      })
      .addCase(getTotalInvoicesCostBySoftwateAction.fulfilled, (state, action) => {
        state.totalInvoiceSoftwareCost = action.payload;
      }),
});

export default softwareSlice.reducer;
