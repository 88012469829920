import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeamSoftwareAction } from '../../redux/features/software/softwareThunks';
import {
  deleteTeamAction,
  getManagedTeamsAndMonthlyCostsAction,
  updateTeamAction,
} from '../../redux/features/teams/teamsThunks';

export const useTeamDropdown = (
  team,
  softwareData,
  isFromApplicationComponent = false
) => {
  const dispatch = useDispatch();

  
  const prevTeamsState = useSelector((state) => state.teams);
  
  const prevSoftwareState = useSelector((state) => state.software);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => setIsModalOpen(false));
  const [initialTeamDetails, setInitialTeamDetails] = useState({});

  const toggleModal = useCallback((value) => {
    setIsModalOpen(value);
  }, []);

  const onDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage(`Are you sure you want to delete the ${team?.name} team?`);
      setValue('delete team');

      const onConfirm = () => {
        dispatch(
          
          deleteTeamAction({
            teamId: team?._id,
            prevTeamsState,
            prevSoftwareState,
          })
          
        ).finally(() => {
          
          dispatch(getManagedTeamsAndMonthlyCostsAction());
        });
        toggleModal(false);
      };
      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, team, prevTeamsState, prevSoftwareState, toggleModal]
  );

  const onEdit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage(`Edit ${team?.name} team`);
      setValue('edit team');
      setInitialTeamDetails({
        name: team?.name || '',
        email: team?.email || '',
        description: team?.description || '',
      });

      const onConfirm = (updatedTeamDetails) => {
        dispatch(
          
          updateTeamAction({
            teamId: team?._id,
            ...updatedTeamDetails,
            prevTeamsState,
          })
        );
        toggleModal(false);
      };
      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, team, prevTeamsState, toggleModal]
  );

  const onDeleteSoftware = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage(`Are you sure you want to delete ${softwareData.name}?`);
      setValue('delete software');

      const onConfirm = () => {
        dispatch(
          
          deleteTeamSoftwareAction({
            teamSoftwareId: softwareData.teamSoftwareId,
            softwareId: softwareData._id,
            teamId: team._id,
            prevState: prevSoftwareState,
          })
          
        ).finally(() => {
          
          dispatch(getManagedTeamsAndMonthlyCostsAction());
        });
        toggleModal(false);
      };

      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, softwareData, team, prevSoftwareState, toggleModal]
  );

  const dropdownOptions = useMemo(() => {
    const options = [
      {
        value: 'delete team',
        onClick: onDelete,
        icon: 'bx bx-trash',
      },
      {
        value: 'edit team',
        onClick: onEdit,
        icon: 'bx bx-edit',
      },
    ];

    if (isFromApplicationComponent && softwareData) {
      options.push({
        value: 'delete software',
        onClick: onDeleteSoftware,
        icon: 'bx bx-trash',
      });
    }

    return options;
  }, [
    onDelete,
    onEdit,
    onDeleteSoftware,
    isFromApplicationComponent,
    softwareData,
  ]);

  return {
    isModalOpen,
    dropdownOpen,
    message,
    value,
    onConfirm,
    initialTeamDetails,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
  };
};
