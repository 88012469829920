import React, { useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ApplicationUserRow from './ApplicationUserRow';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 10;

const fakeUsers = Array.from({ length: itemsPerPage }, (_, index) => ({
  userId: index,
  name: '',
  team: '',
  isActive: false,
  lastActivity: '',
  monthlyCosts: null,
}));

export default function ApplicationUsersTable(props) {
  const { users, fromInvoiceRow } = props;

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex.selected);
  };

  const startIndex = currentPage * itemsPerPage;

  const getDisplayedUsers = (users, fromInvoiceRow, startIndex) => {
    if (!users?.length) {
      return fakeUsers;
    }

    if (fromInvoiceRow) {
      return users;
    }

    return users.slice(startIndex, startIndex + itemsPerPage);
  };

  const displayedUsers = useMemo(
    () => getDisplayedUsers(users, fromInvoiceRow, startIndex),
    [users, fromInvoiceRow, startIndex]
  );

  const renderUsersTable = () => {
    return (
      <div className='table-container'>
        <div className='table'>
          <div className='table-header'>
            <div>
              Name{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Team{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Activity{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Last Activity{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Monthly Costs{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div>
              Total Costs{''}
              <i className='bx bx-sort-alt-2' />
            </div>
            <div className='no-width' />
            <div className='no-width' />
          </div>
          <div className='body'>
            {displayedUsers.map((user) => {
              return (
                <ApplicationUserRow
                  key={user.userId}
                  user={user}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className='application-page'>
      {!fromInvoiceRow && (
        <div className='titles-container'>
          <h3>Users of this Application</h3>
        </div>
      )}
      {renderUsersTable()}
      {!fromInvoiceRow && (
        <div className='pagination-container'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            pageRangeDisplayed={2}
            pageCount={Math.ceil(
              (users?.length || itemsPerPage) / itemsPerPage
            )}
            previousLabel='<'
            onPageChange={handlePageClick}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </section>
  );
}
