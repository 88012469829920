import getWheelTeamMemberStyles from '../../util/design/getWheelTeamMemberStyles';
import formatToSlug from '../../util/formatToSlug';

export const getFormattedManagedTeamsAndMonthlyCosts = (response) => {
  const { teams, userIds, monthlyCostsByTeam, companyMonthlyCosts } = response;

  teams.forEach((team) => {
    team.monthlyCosts = monthlyCostsByTeam[team._id] || 0;
    team.costsPercentage = Math.round(
      (team.monthlyCosts / companyMonthlyCosts) * 100
    );
  });

  const teamIds = teams.map((team) => team._id);

  const teamSlugToId = {};

  const teamData = teams.reduce((map, team) => {
    teamSlugToId[formatToSlug(team.name)] = team._id;
    map[team._id] = team;

    return map;
  }, {});

  return { teamIds, teamSlugToId, teamData, userIds };
};

const getStyle = (index, isMember) => {
  const styles = getWheelTeamMemberStyles();

  let style = styles[index];

  if (!isMember) {
    style = { ...style, filter: 'blur(5px)' };
  }

  return style;
};

export const getMemberElements = (members) => {
  members ||= [];

  const maxStyles = getWheelTeamMemberStyles().length;
  const elements = [];

  for (let i = 0; i < Math.min(members.length, maxStyles); i++) {
    elements.push({
      _id: members[i]?._id,
      isMember: true,
      member: members[i],
      style: getStyle(i, true),
    });
  }

  for (let i = members.length; i < maxStyles; i++) {
    elements.push({
      _id: crypto.randomUUID(),
      isMember: false,
      style: getStyle(i, false),
    });
  }

  return elements;
};

export const getFormattedNewSoftwareForLicences = (response) => {
  const pricesAndUsersBySoftware = response.reduce((map, data) => {
    const { software, userIds } = data;
    const { prices } = software;

    map[software._id] = { prices, userIds };

    return map;
  }, {});

  return pricesAndUsersBySoftware;
};

export const getFormattedPendingUsersByTeam = (response) => {
  const pendingUsers = response.map((user) => user._id);

  const userData = response.reduce((map, data) => {
    const user = data;

    map[user._id] = user;

    return map;
  }, {});

  return { pendingUsers, userData };
};
