import React from 'react';

export default function NotificationDescription({ notification }) {
  const renderNotificationDescription = (notification) => {
    switch (notification?.type) {
      case 'add-user-to-team':
        return (
          <p>
            <b>{notification?.metaData?.user?.name}</b> was added to new team{' '}
            <b>{notification?.metaData?.team?.name}</b>
          </p>
        );
      case 'add-team-software':
        return (
          <p>
            <b>{notification?.metaData?.software?.name}</b> software was added
            to new team <b>{notification?.metaData?.team?.name}</b>
          </p>
        );
      case 'set-team-manager':
        return (
          <p>
            <b>{notification?.metaData?.manager?.name}</b> was set as{' '}
            <b>{notification?.metaData?.team?.name}</b> team Manager
          </p>
        );
      case 'new-software':
        return (
          <p>
            New software <b>{notification?.metaData?.software?.name}</b> was
            added
          </p>
        );
      case 'create-company':
        return (
          <p>
            Company <b>{notification?.metaData?.company?.name}</b> was created
          </p>
        );
      case 'set-company-manager':
        return (
          <p>
            <b>{notification?.metaData?.manager?.name}</b> was set as manager of{' '}
            <b>{notification?.metaData?.company?.name}</b>
          </p>
        );
      case 'create-team':
        return (
          <p>
            Team <b>{notification?.metaData?.team?.name}</b> was created
          </p>
        );
      case 'add-team-to-company':
        return (
          <p>
            Team <b>{notification?.metaData?.team?.name}</b> was added to
            company <b>{notification?.metaData?.company?.name}</b>
          </p>
        );
      case 'add-user-to-company':
        return (
          <p>
            User <b>{notification?.metaData?.user?.name}</b> was added to
            company <b>{notification?.metaData?.company?.name}</b>
          </p>
        );
      case 'gain-new-licence':
        return (
          <p>
            User <b>{notification?.metaData?.user?.name}</b> gained a new
            licence for <b>{notification?.metaData?.software?.name}</b>
          </p>
        );
      case 'deactivate-licence':
        return (
          <p>
            Licence for <b>{notification?.metaData?.software?.name}</b> was
            deactivated for user <b>{notification?.metaData?.user?.name}</b>
          </p>
        );
      case 'activate-licence':
        return (
          <p>
            Licence for <b>{notification?.metaData?.software?.name}</b> was
            activated for user <b>{notification?.metaData?.user?.name}</b>
          </p>
        );
      case 'deactivate-team-software':
        return (
          <p>
            Software <b>{notification?.metaData?.software?.name}</b> was
            deactivated for team <b>{notification?.metaData?.team?.name}</b>
          </p>
        );
      case 'activate-team-software':
        return (
          <p>
            Software <b>{notification?.metaData?.software?.name}</b> was
            activated for team <b>{notification?.metaData?.team?.name}</b>
          </p>
        );
      case 'update-company':
        return (
          <p>
            Company <b>{notification?.metaData?.oldCompany?.name}</b> was
            updated to <b>{notification?.metaData?.updatedCompany?.name}</b>
          </p>
        );
      case 'update-team':
        return (
          <p>
            Team <b>{notification?.metaData?.oldTeam?.name}</b> was updated to{' '}
            <b>{notification?.metaData?.updatedTeam?.name}</b>
          </p>
        );
      case 'update-user':
        return (
          <p>
            User <b>{notification?.metaData?.oldUser?.name}</b> was updated to{' '}
            <b>{notification?.metaData?.updatedUser?.name}</b>
          </p>
        );
      case 'renewal-date-reminder':
        return (
          <p>
            <b>{notification?.metaData?.software?.name}</b> software
            subscription renewal date is due in{' '}
            <b>
              {new Date(
                notification?.metaData?.expirationDate
              ).toLocaleDateString([], {
                day: 'numeric',
                month: 'long',
              })}
            </b>
          </p>
        );
      case 'subscription-paid':
        return (
          <p>
            <b>{notification?.metaData?.software?.name}</b> monthly subscription
            of <b>{notification?.metaData?.value} </b>was paid
          </p>
        );
      default:
        return null;
    }
  };

  return renderNotificationDescription(notification);
}
