import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateTokenAction } from '../../redux/features/user/userThunks';
import { TokenService } from '../../services/TokenService';

export default function AuthCallback() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authData = decodeURIComponent(params.get('authData'));

    if (authData) {
      const parsedAuthData = JSON.parse(authData);
      const id = parsedAuthData.id;

      const newCompany = parsedAuthData.newCompany;

      if (newCompany) {
        
        dispatch(updateTokenAction());
      }

      TokenService.storeAuthData(id, parsedAuthData.account);
      TokenService.storeToken(id, parsedAuthData.accessToken);

      navigate('/dashboard/connect');
    } else {
      console.error('No auth data found in query params');
      navigate('/dashboard/connect');
    }
  }, [dispatch, navigate]);

  return <div>Redirecting...</div>;
}
