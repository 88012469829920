import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { commonOptions } from '../../../util/UtilFunctions';

export default function CostDifferencesChart({ series, selectedView, height }) {
  const seriesData = useMemo(() => {
    return series?.[selectedView] ?? [];
  }, [series, selectedView]);

  const isLoading = useMemo(() => {
    return !series?.[selectedView];
  }, [series, selectedView]);

  const options = {
    ...commonOptions,
    tooltip: {
      y: {
        title: {
          formatter: () => 'Difference',
        },
        formatter: function (_, opts) {
          return (
            opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
              .tooltip + '%'
          );
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Skeleton
        height={height}
        baseColor='#333'
        highlightColor='#444'
      />
    );
  }

  return (
    <ReactApexChart
      
      options={options}
      series={seriesData}
      type='line'
      height={height}
    />
  );
}
