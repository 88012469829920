import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSoftwareInvoiceAction } from '../../redux/features/invoice/invoiceThunks';
import { getTrackedSoftwareDataAction } from '../../redux/features/software/softwareThunks';

export default function SoftwareDropDown(props) {
  const dispatch = useDispatch();
  const { invoice, fromPreview, updateSelectedSoftware } = props;
  const { trackedSoftwareIds, staticSoftwareData } = useSelector(
    (state) => state.software
  );

  const prevState = useSelector((state) => state.invoice);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState();
  const dropdownRef = useRef(null);

  useEffect(() => {
    
    dispatch(getTrackedSoftwareDataAction());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSoftwareSelect = (software) => {
    const invoiceId = invoice?._id;

    setSelectedSoftware(software)
    if (!fromPreview)
      dispatch(addSoftwareInvoiceAction({ invoiceId, software, prevState }));
    else 
      updateSelectedSoftware(software)

    setIsDropdownOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className='element software-selection'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
      }}
    >
      <div
        className='icon'
        style={{
          backgroundImage: `url(${selectedSoftware?.imageUrl || invoice?.software?.imageUrl || invoice?.softwareData?.imageUrl || ''})`
        }}
      />
      <div>
        {selectedSoftware?.name || invoice?.software?.name || invoice?.softwareData?.name ? (
          <>
            {selectedSoftware?.name || invoice.software.name || invoice?.softwareData?.name }
            <i class='bx bxs-down-arrow' style={{fontSize: "8px", marginLeft: "5px"}}></i>
          </>
        ) : (
          <>
            <i className='bx bx-plus' />
          </>
        )}
        {isDropdownOpen && (
          <ul className='dropdown-menu'>
            {Object.values(staticSoftwareData).map((software) => (
              <li
                key={software?._id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSoftwareSelect(software);
                }}
              >
                <div
                  className='icon'
                  style={{
                    backgroundImage: `url(${
                        software?.imageUrl || ''
                    })`,
                  }}
                ></div>
                {software?.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
