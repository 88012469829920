import React from 'react';
import { useSelector } from 'react-redux';
import MostUsedSoftwareInternal from './Tracked Software/TrackedSoftware';

export default function ApplicationsListComponent() {
  
  const { trackedSoftwareIds } = useSelector((state) => state.software);

  return (
    <section className='invoices-container'>
      <div className='titles-container'>
        <h2>
          <span>Applications</span>
        </h2>
        <h4>
          Explore your list of software subscriptions. (
          {trackedSoftwareIds.length})
        </h4>
      </div>
      <MostUsedSoftwareInternal />
    </section>
  );
}
