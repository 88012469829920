import { updateLocalStorageState } from '../../util/localStorage';
import mergeObjects from '../../util/mergeObjects';

const updateSoftwareStateData = (state, payload) => {
  const { staticSoftwareData, allDynamicSoftwareData } = state;

  Object.assign(state, payload);

  state.staticSoftwareData = mergeObjects(
    staticSoftwareData,
    payload.staticSoftwareData || {}
  );

  state.allDynamicSoftwareData = mergeObjects(
    allDynamicSoftwareData,
    payload.allDynamicSoftwareData || {}
  );

  updateLocalStorageState('staticSoftwareData', state.staticSoftwareData);
};

export default updateSoftwareStateData;
