export const getCompanyUserData = (company) => {
  const userData = company?.users?.reduce((acc, user) => {
    acc[user._id] = user;

    return acc;
  }, {});

  return userData;
};

export default getCompanyUserData;
