import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function OneTeamSelectionStep(props) {
  const { selectedTeam, setSelectedTeam } = props;
  
  const { teamIds, teamData } = useSelector((state) => state.teams);
  
  const { userData } = useSelector((state) => state.user);

  const teams = useMemo(
    () => teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware'),
    [teamIds, teamData]
  );

  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
  };

  const renderTeams = () => {
    return teams.map((team, index) => {
      const members = team?.users.map((user) => userData[user.userId]);
      const maxVisibleMembers = 2;

      return (
        <div
          key={team?._id}
          className={`team-card ${selectedTeam === team._id ? 'selected' : ''}`}
          onClick={() => handleTeamSelect(team._id)}
        >
          <div
            className='overlay'
            style={{
              backgroundImage: `url(/images/0${(index % 7) + 1}.jpg)`,
            }}
          />

          <div className='team-card-header'>
            <div className='title'>
              <p>Team department</p>
              <h3>{team?.name}</h3>
            </div>
          </div>

          <div className='team-card-body'>
            {selectedTeam === team._id && (
              <div className='selected'>
                <i className='bx bxs-check-circle' />
                <div>Selected</div>
              </div>
            )}
            <div className='users-list'>
              {members.slice(0, maxVisibleMembers).map((member) => (
                <div
                  key={member?._id}
                  className='user-image'
                  style={{ backgroundImage: member?.imageUrl ? `url(${member?.imageUrl})` :'',
                  transform: members?.length === 1 && 'unset' }}
                ></div>
              ))}
              {members.length > maxVisibleMembers && (
                <div className='user-image nbr'>
                  <div>+{members.length - maxVisibleMembers}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className='search-bar-container'>
        <p>Select a team to add users to</p>
      </div>
      <div className='teams-content'>{renderTeams()}</div>
    </>
  );
}
