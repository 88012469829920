export const getAllUserCostsData = (allDynamicSoftwareData) => {
  const allUserCostsData = {};

  Object.values(allDynamicSoftwareData).forEach((softwareData) => {
    softwareData.userMonthlyCosts.forEach((userCost) => {
      const { userId, isActive, monthlyCosts } = userCost;

      if (!allUserCostsData[userId]) {
        allUserCostsData[userId] = {
          monthlyCosts: 0,
          activeSoftwareCount: 0,
          totalSoftwareCount: 0,
        };
      }

      allUserCostsData[userId].monthlyCosts += monthlyCosts;
      allUserCostsData[userId].totalSoftwareCount += 1;

      if (isActive) {
        allUserCostsData[userId].activeSoftwareCount += 1;
      }
    });
  });

  return allUserCostsData;
};

export const getUserCostsDataByTeam = (trackedSoftwareDataByTeam) => {
  const userCostsDataByTeam = {};

  Object.entries(trackedSoftwareDataByTeam).forEach(([teamId, teamData]) => {
    userCostsDataByTeam[teamId] = {};
    Object.values(teamData).forEach((softwareData) => {
      softwareData.userMonthlyCosts.forEach((userCost) => {
        const { userId, isActive, monthlyCosts } = userCost;

        if (!userCostsDataByTeam[teamId][userId]) {
          userCostsDataByTeam[teamId][userId] = {
            monthlyCosts: 0,
            activeSoftwareCount: 0,
            totalSoftwareCount: 0,
          };
        }

        userCostsDataByTeam[teamId][userId].monthlyCosts += monthlyCosts;
        userCostsDataByTeam[teamId][userId].totalSoftwareCount += 1;

        if (isActive) {
          userCostsDataByTeam[teamId][userId].activeSoftwareCount += 1;
        }
      });
    });
  });

  return userCostsDataByTeam;
};

export const getUserMonthlyCostsBySoftware = (allDynamicSoftwareData) => {
  const userMonthlyCostsBySoftware = {};

  Object.entries(allDynamicSoftwareData).forEach(([softwareId, softwareData]) => {
    softwareData.userMonthlyCosts.forEach((userCost) => {
      const { userId, ...userData } = userCost;

      if (!userMonthlyCostsBySoftware[userId]) {
        userMonthlyCostsBySoftware[userId] = {};
      }

      userMonthlyCostsBySoftware[userId][softwareId] = userData;
    });
  });

  return userMonthlyCostsBySoftware;
};