import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMultipleUserData,
  getUserProfileData,
  updateToken,
} from '../../../services/api/user/fetch';
import {
  googleLogin,
  login,
  register,
  verifyUser,
  verifyUserFromProvider,
  verifyUserFromTeamInvite,
} from '../../../services/api/user/modify';
import isValidToken from '../../../util/isValidToken';

export const loginAction = createAsyncThunk(
  'user/login',
  
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await login(email, password);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const googleLoginAction = createAsyncThunk(
  'user/googleLogin',
  async (credentialResponse, { rejectWithValue }) => {
    try {
      const response = await googleLogin(credentialResponse);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMultipleUserDataAction = createAsyncThunk(
  'user/getMultipleUserData',
  
  async (userIds, { rejectWithValue }) => {
    try {
      const result = await getMultipleUserData(userIds);

      const userData = result.reduce((acc, user) => {
        acc[user._id] = user;

        return acc;
      }, {});

      return userData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserProfileDataAction = createAsyncThunk(
  'user/getUserProfileData',
  async (userId, { rejectWithValue }) => {
    try {
      const data = await getUserProfileData(userId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyUserAction = createAsyncThunk(
  'user/verifyUser',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { token: verificationToken } = arg;

      const response = await verifyUser(verificationToken);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyUserFromTeamInviteAction = createAsyncThunk(
  'user/verifyUserFromTeamInvite',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { token: verificationToken } = arg;

      const response = await verifyUserFromTeamInvite(verificationToken);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyUserFromProviderAction = createAsyncThunk(
  'user/verifyUserFromProvider',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { token: verificationToken } = arg;

      const response = await verifyUserFromProvider(verificationToken);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerAction = createAsyncThunk(
  'user/register',
  
  async ({ name, email, password }, { rejectWithValue }) => {
    try {
      const response = await register(name, email, password);
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTokenAction = createAsyncThunk(
  'user/updateToken',
  async (arg, { rejectWithValue }) => {
    try {
      const response = await updateToken();
      const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        return rejectWithValue({
          type: 'InvalidToken',
          message: 'Invalid token',
        });
      }

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      return { user, token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
