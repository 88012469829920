import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteInvoiceAction,
  updateInvoiceDueDateAction,
  updateInvoiceValueAction,
} from '../../redux/features/invoice/invoiceThunks';

export const useInvoiceDropdown = (invoice, setInvoiceConfirmation) => {
  const dispatch = useDispatch();
  const prevState = useSelector((state) => state.invoices);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => setIsModalOpen(false));
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [editedValue, setEditedValue] = useState(invoice?.value);
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);
  const [editedDueDate, setEditedDueDate] = useState(invoice?.dueDate);

  const toggleModal = useCallback((value) => {
    setIsModalOpen(value);
  }, []);

  const onDelete = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      setMessage('Are you sure you want to delete this Invoice?');
      setValue('delete');

      const onConfirm = () => {
        dispatch(deleteInvoiceAction({ invoiceId: invoice._id, prevState }));
      };

      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, invoice, prevState, toggleModal]
  );

  const handleValueClick = useCallback(() => {
    setIsEditingValue(true);
  }, []);

  const handleValueChange = useCallback((e) => {
    setEditedValue(e.target.value || 0);
  }, []);

  const handleValueBlur = useCallback(() => {
    setIsEditingValue(false);

    if (editedValue !== invoice?.value) {
      dispatch(
        
        updateInvoiceValueAction({ invoiceId: invoice._id, value: editedValue || 0 })
      );
    }
  }, [dispatch, editedValue, invoice]);

  const handleDueDateClick = useCallback(() => {
    setIsEditingDueDate(true);
  }, []);

  const handleDueDateChange = useCallback((e) => {
    setEditedDueDate(e.target.value);
  }, []);

  const handleDueDateBlur = useCallback(() => {
    setIsEditingDueDate(false);

    if (editedDueDate !== invoice?.dueDate) {
      dispatch(
        
        updateInvoiceDueDateAction({
          invoiceId: invoice._id,
          dueDate: editedDueDate,
        })
      );
    }
  }, [dispatch, editedDueDate, invoice]);

  const dropdownOptions = [
    {
      value: 'Confirm Data',
      onClick: () => setInvoiceConfirmation(invoice),
      icon: 'bx bx-edit',
    },
    {
      value: 'delete',
      onClick: (e) => onDelete(e),
      icon: 'bx bx-trash',
    },
  ];

  return {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    isEditingValue,
    editedValue,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
    handleValueClick,
    handleValueChange,
    handleValueBlur,
    isEditingDueDate,
    editedDueDate,
    handleDueDateClick,
    handleDueDateChange,
    handleDueDateBlur,
    onDelete
  };
};
