import { createSlice } from '@reduxjs/toolkit';
import {
  addInvoiceAction,
  addSoftwareInvoiceAction,
  confirmInvoiceAndUpdateAction,
  deleteInvoiceAction,
  getAllInvoicesAction,
  getRecentInvoicesAction,
  updateInvoiceDueDateAction,
  updateInvoiceNameAction,
  updateInvoiceValueAction,
} from './invoiceThunks';
import { getPreviewInvoice } from '../../../services/invoice/format';

const initialState = {
  invoices: [],
  recentInvoices: [],
  currentPage: 1,
  totalPages: 1,
};

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getAllInvoicesAction.fulfilled, (state, action) => {
        state.invoices = action.payload.invoices;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getRecentInvoicesAction.fulfilled, (state, action) => {
        state.recentInvoices = action.payload.recentInvoices;
      })
      .addCase(addInvoiceAction.pending, (state, action) => {
        
        const { invoice } = action.meta.arg;

        if (state.totalPages === 1) {
          state.invoices.unshift(invoice);
        }

        state.recentInvoices.unshift(invoice);
      })
      .addCase(addInvoiceAction.fulfilled, (state, action) => {
        
        const { invoice } = action.meta.arg;

        state.invoices = state.invoices.map((inv) =>
          inv._id === invoice._id ? action.payload : inv
        );

        state.recentInvoices = state.recentInvoices.map((inv) =>
          inv._id === invoice._id ? action.payload : inv
        );
      })
      .addCase(addInvoiceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.invoices = prevState.invoices;
        state.recentInvoices = prevState.recentInvoices;
      })
      .addCase(addSoftwareInvoiceAction.pending, (state, action) => {
        
        const { invoiceId, software } = action.meta.arg;

        state.invoices = state.invoices.map((invoice) => {
          if (invoice._id === invoiceId) {
            invoice.software = software;
          }

          return invoice;
        });

        state.recentInvoices = state.recentInvoices.map((invoice) => {
          if (invoice._id === invoiceId) {
            invoice.software = software;
          }

          return invoice;
        });
      })
      .addCase(addSoftwareInvoiceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.invoices = prevState.invoices;
        state.recentInvoices = prevState.recentInvoices;
      })
      .addCase(updateInvoiceNameAction.fulfilled, (state, action) => {
        const { updatedInvoice } = action.payload;

        const index = state.invoices.findIndex(
          (invoice) => invoice._id === updatedInvoice?._id
        );

        if (index !== -1) {
          state.invoices[index] = updatedInvoice;
        }
        const indexInRecent = state.recentInvoices.findIndex(
          (invoice) => invoice._id === updatedInvoice?._id
        );

        if (indexInRecent !== -1) {
          state.recentInvoices[indexInRecent] = updatedInvoice;
        }
      })
      .addCase(deleteInvoiceAction.pending, (state, action) => {
        state.invoices = state.invoices.filter(
          
          (invoice) => invoice._id !== action.meta.arg.invoiceId
        );

        state.recentInvoices = state.recentInvoices.filter(
          
          (invoice) => invoice._id !== action.meta.arg.invoiceId
        );
      })
      .addCase(deleteInvoiceAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.invoices = prevState.invoices;
        state.recentInvoices = prevState.recentInvoices;
      })
      .addCase(updateInvoiceValueAction.pending, (state, action) => {
        
        const { invoiceId, value } = action.meta.arg;

        const index = state.invoices.findIndex(
          (invoice) => invoice._id === invoiceId
        );

        if (index !== -1) {
          state.invoices[index].value = value;
        }

        const indexInRecent = state.recentInvoices.findIndex(
          (invoice) => invoice._id === invoiceId
        );

        if (indexInRecent !== -1) {
          state.recentInvoices[indexInRecent].value = value;
        }
      })
      .addCase(updateInvoiceValueAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.invoices = prevState.invoices;
        state.recentInvoices = prevState.recentInvoices;
      })
      .addCase(updateInvoiceDueDateAction.pending, (state, action) => {
        
        const { invoiceId, dueDate } = action.meta.arg;

        const index = state.invoices.findIndex(
          (invoice) => invoice._id === invoiceId
        );

        if (index !== -1) {
          state.invoices[index].dueDate = dueDate;
        }

        const indexInRecent = state.recentInvoices.findIndex(
          (invoice) => invoice._id === invoiceId
        );

        if (indexInRecent !== -1) {
          state.recentInvoices[indexInRecent].dueDate = dueDate;
        }
      })
      .addCase(updateInvoiceDueDateAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.invoices = prevState.invoices;
        state.recentInvoices = prevState.recentInvoices;
      }).addCase(confirmInvoiceAndUpdateAction.fulfilled, (state, action) => {
        let updatedInvoice = action.payload?.updatedInvoice;

        if (updatedInvoice) {
          updatedInvoice.isUpdated = true
          updatedInvoice.preview = getPreviewInvoice(updatedInvoice)
          state.invoices = state.invoices.map((invoice) => 
            invoice._id === updatedInvoice._id ? updatedInvoice : invoice
          );
        }
        
      }),
});

export default invoiceSlice.reducer;
