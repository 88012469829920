import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import FileComponent from './FileComponent';
import PreviewFile from '../Modals/Invoices/PreviewFile';

export default function InvoiceGrid(props) {
  const {
    files,
    currentPage,
    totalPages,
    isEditing,
    editValue,
    visibleDropdown,
    handleEdit,
    handleDelete,
    onEditValueChange,
    handleSave,
    onToggleDropdown,
    onPageClick,
    setSelectedFile,
    selectedFile,
    setInvoiceConfirmation
  } = props;

  return (
    <>
      <div className='files-gallery'>
        {files.map((file) => (
          <FileComponent
            key={file._id}
            file={file}
            isEditing={isEditing}
            editValue={editValue}
            visibleDropdown={visibleDropdown}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onEditValueChange={onEditValueChange}
            handleSave={handleSave}
            onToggleDropdown={onToggleDropdown}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            setInvoiceConfirmation={setInvoiceConfirmation}
          />
        ))}
      </div>
      
      {
        selectedFile &&
        <PreviewFile selectedFile={selectedFile} toggleModal={setSelectedFile} />
      }

      <div className='pagination-container'>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          pageRangeDisplayed={2}
          previousLabel='<'
          renderOnZeroPageCount={null}
          onPageChange={onPageClick}
          pageCount={totalPages}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
}
