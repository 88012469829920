import React from 'react';

export default function TeamDetailsStep({ teamDetails, setTeamDetails }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <form>
      <div className='search-bar-container'>
        <p>Fill your new Team Details</p>
      </div>
      
      <div className='form-input-container'>
        <label htmlFor='name'>Name</label>
        <input
          type='text'
          id='name'
          name='name'
          className='text-input'
          placeholder='Your new team name'
          value={teamDetails.name}
          onChange={handleChange}
        />
      </div>
      <div className='form-input-container'>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          id='email'
          name='email'
          className='email-input'
          placeholder='Your team email'
          value={teamDetails.email}
          onChange={handleChange}
        />
      </div>
      <div className='form-input-container'>
        <label htmlFor='description'>Description</label>
        <textarea
          id='description'
          name='description'
          className='text-area-input'
          value={teamDetails.description}
          placeholder='Your team description'
          onChange={handleChange}
        />
      </div>
    </form>
  );
}
