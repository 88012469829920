import React from 'react';

export default function AddMemberCard(props) {
  const { onClick } = props;

  return (
    <div className='member-card add-member'>
      <div className='image-overlay'>
        <div className='add-button'>
          <div
            className='plus-sign'
            onClick={onClick}
          >
            <i className='bx bx-plus'></i>
          </div>
        </div>
      </div>

      <div className='overlay'></div>
    </div>
  );
}
