import React, { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPaymentTypeValue } from '../../services/software/format';
import { getMetric } from '../../util/UtilFunctions';
import formatToSlug from '../../util/formatToSlug';

const SoftwareCard = forwardRef(({ selectedSoftwareId, dynamicSoftwareData, isSelected, setSelectedCard, isFromInterface = false, totalInvoiceSoftwareCost = 0 }, ref) => {
  const { staticSoftwareData } = useSelector(
    (state) => state.software
  );

  const { userData } = useSelector(
    (state) => state.user
  );

  const selectedSoftwareData = {
    ...staticSoftwareData[selectedSoftwareId],
    ...dynamicSoftwareData[selectedSoftwareId],
  };

  const metric = getMetric(selectedSoftwareData?.monthlyCosts);
  const metricTotal = getMetric(selectedSoftwareData?.totalCosts);
  const metricTotalProviders = getMetric(totalInvoiceSoftwareCost?.totalInvoiceSoftwareCost?.totalCost);

  const activeUserLength = selectedSoftwareData?.userMonthlyCosts?.filter(
    (user) => user.isActive
  ).length;

  const totalUserLength = selectedSoftwareData?.userMonthlyCosts?.length;

  const users = selectedSoftwareData?.userMonthlyCosts;

  const formattedLinkName = formatToSlug(selectedSoftwareData?.name);

  const renderUsers = (users) => {
    if (!users?.length) {
      return null;
    }

    const usersLimit = users?.length > 2 ? users.slice(0, 2) : users;

    return usersLimit.map((user, index) => {
      return (
        <div
          key={user?.userId}
          className='user-image'
          style={{
            backgroundImage:userData?.[user?.userId]?.imageUrl ? `url(${
              userData?.[user?.userId]?.imageUrl
            })` : `url(/avatars/${Math.floor(Math.random() * 7) + 1}.jpg)`,
          }}
        />
      );
    });
  };

  const renderCategories = (categories) => {
    if (!categories?.length) {
      return null;
    }

    return categories.map((category) => {
      return (
        <span
          key={crypto.randomUUID()}
          className='category'
        >
          {category?.name}
        </span>
      );
    });
  };

  const renderMore = () => {
    if (isSelected && dynamicSoftwareData[selectedSoftwareId]) {
      return (
        <div className='card-content details'>
          <div className='detail'>
            <div className='label'>Renewal Date(s)</div>
            <div className='metric'>
              {selectedSoftwareData?.paymentData?.map((payment) => (
                <>
                  <span
                    key={crypto.randomUUID()}
                    className='decimal small'
                  >
                    {payment?.nextPayment}
                  </span>
                  <br />
                </>
              ))}
            </div>
          </div>
          <div className='detail'>
            <div className='label'>Payment Types(s)</div>
            <div className='metric'>
              {selectedSoftwareData?.paymentData?.map((payment) => (
                <>
                  <span
                    key={crypto.randomUUID()}
                    className='decimal small'
                  >
                    {getPaymentTypeValue(payment?.paymentType)}
                  </span>
                  <br />
                </>
              ))}
            </div>
          </div>

          <div className='detail'>
            <div className='label'>Category</div>
            <div className='metric flex'>
              {selectedSoftwareData?.categories &&
                renderCategories(selectedSoftwareData.categories)}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderTotalCosts = () => {
    if (isFromInterface) {
      return (
        <div className='detail'>
          <div className='label'>Total Costs (approximative)</div>
          <div className='metric'>
            {metricTotal ? (
              <>
                <span className='currency'>€</span>
                <span>{metricTotal?.first}</span>
                <span className='decimal'>{metricTotal?.second}</span>
              </>
            ) : (
              <Skeleton
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
      )
    }
  }

  const renderTotalInvoicesCosts = () => {
    if (totalInvoiceSoftwareCost?.totalInvoiceSoftwareCost?.totalCost > 0) {
      return (
        <div className='detail'>
          <div className='label'>Total Costs from Providers</div>
          <div className='metric'>
            {metricTotalProviders ? (
              <>
                <span className='currency'>€</span>
                <span>{metricTotalProviders?.first}</span>
                <span className='decimal'>{metricTotalProviders?.second}</span>
              </>
            ) : (
              <Skeleton
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <div
      className={'software-card ' + (isSelected ? 'selected' : '')}
      onClick={() => setSelectedCard(selectedSoftwareData)}
      ref={ref}
    >
      <div
        className='overlay'
        style={{
          backgroundImage: `url(${selectedSoftwareData?.imageUrl || ''})`,
        }}
      />
      <div className='card-header'>
        <div
          className='company-logo-image'
          style={{
            backgroundImage: `url(${selectedSoftwareData?.imageUrl || ''})`,
          }}
        ></div>

        <div className='card-titles'>
          <div className='title'>{selectedSoftwareData?.name}</div>
          <div className='subtitle'>
            {selectedSoftwareData?.description
              ? 'Overview of the application'
              : ''}
          </div>
        </div>

        <Link
          to={`/dashboard/application/${formattedLinkName}`}
          className='icon-btn link'
        >
          <i className='bx bx-link-external' />
        </Link>
      </div>

      <div className='card-content'>
        <div className='description'>{selectedSoftwareData?.description}</div>
      </div>

      <div className='card-content details'>
        <div className='detail'>
          <div className='label'>Monthly Costs (last 31 days)</div>
          <div className='metric'>
            {metric ? (
              <>
                <span className='currency'>€</span>
                <span>{metric?.first}</span>
                <span className='decimal'>{metric?.second}</span>
              </>
            ) : (
              <Skeleton
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>

        {renderTotalCosts()}
        {renderTotalInvoicesCosts()}

        <div className='detail'>
          <div className='label'>Active Users</div>
          <div className='metric flex'>
            {typeof activeUserLength === 'number' &&
            typeof totalUserLength === 'number' ? (
              <>
                <span>{activeUserLength}</span>
                <span className='decimal'>/{totalUserLength}</span>
              </>
            ) : (
              <Skeleton
                width={50}
                height={20}
                baseColor='#333'
                highlightColor='#444'
              />
            )}

            <div className='users-list'>
              {users && (
                <>
                  {renderUsers(users)}
                  {users?.length > 2 && (
                    <div className='user-image nbr'>
                      <div>+{users?.length - 2}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {renderMore()}
    </div>
  );
})
export default SoftwareCard;
