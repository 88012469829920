import { createSlice } from '@reduxjs/toolkit';
import { addCompanyAction, getCompanyDataAction } from './companyThunks';

const companyFromStorage = localStorage.getItem('company')
  ? JSON.parse(localStorage.getItem('company'))
  : null;

const initialState = {
  company: companyFromStorage,
  error: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getCompanyDataAction.fulfilled, (state, action) => {
        const { company } = action.payload;

        state.company = company;
        state.error = null;
      })
      .addCase(getCompanyDataAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(addCompanyAction.fulfilled, (state, action) => {
        const { company } = action.payload;

        state.company = company;
        state.error = null;
      })
      .addCase(addCompanyAction.rejected, (state, action) => {
        state.error = action.payload;
      }),
});

export default companySlice.reducer;
