import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/event';

export const seeNotifications = async (eventIds) => {
  try {
    const response = await axios.post(`${prefix}/notifications/see`, {
      eventIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
