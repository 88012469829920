import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWidgetAction } from '../../redux/features/widgets/widgetThunks';
import EmptyCard from '../UI/EmptyCard';
import AnalyticsCard from './AnalyticsCard';
import SpendingWidget from './SpendingWidget';
import SubscriptionWidget from './SubscriptionWidget';

function WidgetRenderer(props) {
  const dispatch = useDispatch();

  const { widget, toggleAddWidgetModal } = props;
  
  const { widgets } = useSelector((state) => state.widgets);

  const widgetType = widget?.type;

  const handleDelete = () => {
    
    dispatch(deleteWidgetAction({ widgetId: widget._id, oldWidgets: widgets }));
  };

  switch (widgetType.split('-')[0]) {
    case 'spending':
      return (
        <SpendingWidget
          key={widget._id}
          widget={widget}
          onDelete={handleDelete}
        />
      );
    case 'subscription':
      return (
        <SubscriptionWidget
          key={widget._id}
          widget={widget}
          onDelete={handleDelete}
        />
      );
    case 'analytics':
      return (
        <AnalyticsCard
          key={widget._id}
          widget={widget}
          onDelete={handleDelete}
        />
      );
    case 'empty':
      return (
        <EmptyCard
          key={widget._id}
          emptyCardIndex={widget._id}
          toggleAddWidgetModal={toggleAddWidgetModal}
        />
      );
    default:
      return null;
  }
}

export default React.memo(WidgetRenderer);
