import React, { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import useInitComparisonSeries from '../../../hooks/useInitComparisonSeries';
import { getTrackedTeamMonthlyCostsByDateAction } from '../../../redux/features/analytics/analyticsThunks';
import { commonOptions } from '../../../util/UtilFunctions';

const maxDateInputs = Number(process.env.REACT_APP_MAX_DATE_INPUTS) || 5;

export default function ComparisonChart({ height }) {
  const options = {
    ...commonOptions,
    yaxis: {
      ...commonOptions.yaxis,
      decimalsInFloat: 2,
      labels: {
        formatter: function (val) {
          return val + '%';
        },
        style: {
          fontFamily: 'Inter',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: () => 'Monthly Costs',
        },
        formatter: function (_, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ].tooltip;
        },
      },
    },
  };

  const todayData = useInitComparisonSeries();
  
  const { costByDateData } = useSelector((state) => state.analytics);

  const [dates, setDates] = useState([]);

  const seriesData = useMemo(() => {
    const newSeries = [todayData];

    dates.forEach((date) => {
      const costs = costByDateData[date];

      if (costs !== undefined) {
        const currMonthCosts = todayData.tooltip;
        const y = ((costs / currMonthCosts) * 100).toFixed(2);
        newSeries.push({ x: date, y, tooltip: Math.round(costs) });
      }
    });

    return [{ data: newSeries }];
  }, [dates, todayData, costByDateData]);

  const dispatch = useDispatch();

  const handleChange = (index, value) => {
    const newDates = [...dates];
    newDates[index] = value;
    setDates(newDates);
    
    dispatch(getTrackedTeamMonthlyCostsByDateAction(value));
  };

  const handleAddDateField = () => {
    if (dates.length < 5) {
      setDates([...dates, '']);
    }
  };

  const handleRemoveDateField = (index) => {
    const newDates = [...dates];
    newDates.splice(index, 1);
    setDates(newDates);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {dates.map((date, index) => (
          <div key={index}>
            <input
              type='date'
              value={date}
              onChange={(e) => handleChange(index, e.target.value)}
            />
            <button onClick={() => handleRemoveDateField(index)}>-</button>
          </div>
        ))}
        {dates.length < maxDateInputs && (
          <button onClick={handleAddDateField}>+</button>
        )}
      </div>
      <ReactApexChart
        
        options={options}
        series={seriesData}
        type='bar'
        height={height}
      />
    </>
  );
}
