export const rehydrateWidgetData = () => {
  const widgetData = localStorage.getItem('widgetData');

  return widgetData ? JSON.parse(widgetData) : {};
};

export const rehydrateWidgets = () => {
  const widgets = localStorage.getItem('widgets');

  return widgets ? JSON.parse(widgets) : [];
};
