import { configureStore } from '@reduxjs/toolkit';
import analyticsReducer from './features/analytics/analyticsSlice';
import companyReducer from './features/company/companySlice';
import eventsReducer from './features/events/eventsSlice';
import folderReducer from './features/folder/folderSlice';
import invoiceReducer from './features/invoice/invoiceSlice';
import responseReducer from './features/response/responseSlice';
import softwareReducer from './features/software/softwareSlice';
import teamsReducer from './features/teams/teamsSlice';
import userReducer from './features/user/userSlice';
import widgetsReducer from './features/widgets/widgetsSlice';
import updateWidgetOrderMiddleware, {
  widgetLocalStorageMiddleware,
} from './middlewares/widgets';

export const store = configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    widgets: widgetsReducer,
    software: softwareReducer,
    teams: teamsReducer,
    response: responseReducer,
    analytics: analyticsReducer,
    folders: folderReducer,
    invoices: invoiceReducer,
    events: eventsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(widgetLocalStorageMiddleware)
      .concat(updateWidgetOrderMiddleware),
});
