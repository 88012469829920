import React, { useEffect, useRef, useState } from 'react';
export default function DropdownMenu({
  dropdownItems,
  selectedItem,
  setSelectedItem,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  const handleChangeItem = (item) => {
    setSelectedItem(item);
    setShowDropdown(false);
  };

  return (
    <div
      ref={dropdownRef}
      className='button'
      onClick={() => setShowDropdown(!showDropdown)}
    >
      {selectedItem?.name} <i className='bx bxs-chevron-down'></i>
      {showDropdown && (
        <div className='dropdown-menu'>
          {dropdownItems
            .filter((item) => item._id !== selectedItem._id)
            .map((item) => (
              <div
                key={item._id}
                className='dropdown-item'
                onClick={() => handleChangeItem(item)}
              >
                {item?.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
