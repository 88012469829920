import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyData } from '../../../services/api/company/fetch';
import { addCompany } from '../../../services/api/company/modify';
import { getCompanyUserData } from '../../../services/company/get';

export const getCompanyDataAction = createAsyncThunk(
  'company/getData',
  
  async (companyId, { rejectWithValue }) => {
    try {
      const company = await getCompanyData(companyId);

      const companyUserData = getCompanyUserData(company);

      if (company) {
        localStorage.setItem('company', JSON.stringify(company));
      }

      return { company, companyUserData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCompanyAction = createAsyncThunk(
  'company/add',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { name, users } = arg;

      const { savedCompany: company, accessToken } = await addCompany(
        name,
        users
      );

      if (company) {
        localStorage.setItem('company', JSON.stringify(company));
      }

      return { company, accessToken };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
