import React from 'react';

export default function UpdateInvoiceModal(props) {
  const { message, value, onConfirm, toggleModal, invoice } = props;

  const onCancel = () => {
    toggleModal(false);
  };

  const styleTitle = {
    fontSize: '18px',
    fontWeight: 600,
  }, 
  styleMessage = {
    fontSize: '15px',
    opacity: .9,
  },
  styleModal = value === 'delete' ? {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '80px',
  } : {},
  styleNote = {
    fontStyle: 'italic',
    fontSize: '12px',
    opacity: .8,
    margin: 0
  };

  const renderContent = (message, value) => {
    switch (value) {
      case 'delete':
        return (
          <>
            <div className='titles'>
              <h3 style={styleTitle}>Delete invoice</h3>
              <p style={styleMessage}>Are you sure you want to delete this Invoice <b>{invoice?.name}</b>?</p>
            </div>
            <p className='titles' style={styleNote}>
              * Deleting this Invoice will remove its specific software payment data for all its users and teams (added before the invoice upload).
            </p>
          </>
        );
      default:
        return;
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container confirmation'>
        <div className='modal' style={styleModal}>
          {renderContent(message, value)}
          <div className='next-btn-container'>
            <button
              className='next-btn previous'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='next-btn'
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
