import React from 'react';
import TableDataSoftware from './TableDataSoftware';

export default function TableContainer(props) {
  const {
    headerData,
    softwareIds,
    dynamicSoftwareData,
    selectedCard,
    setSelectedCard,
    addFunction,
    currentPage,
    itemsPerPage,
    teamId,
  } = props;

  const renderTableHeaders = () => {
    if (!headerData?.length) {
      return null;
    }

    return headerData.map((header) => {
      return (
        <div
          key={crypto.randomUUID()}
          className='element'
        >
          {header}
        </div>
      );
    });
  };

  return (
    <div className='table'>
      <div className='row head'>{renderTableHeaders()}</div>
      <TableDataSoftware
        softwareIds={softwareIds}
        dynamicSoftwareData={dynamicSoftwareData}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        teamId={teamId}
      />
      {addFunction && (
        <div
          className='row add'
          onClick={() => addFunction(true)}
        >
          <div className='element plus-sign'>
            <i className='bx bx-plus'></i>
          </div>
        </div>
      )}
    </div>
  );
}
