import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { commonOptions } from '../../../util/UtilFunctions';
import DropdownMenu from '../DropdownMenu';

export default function CostHistoryChart(props) {
  const { series, selectedView, softwareList, selectedSoftware, height } =
    props;

  const [selectedItem, setSelectedItem] = useState(
    selectedSoftware || softwareList[0]
  );

  useEffect(() => {
    if (!selectedSoftware && softwareList.length > 0) {
      setSelectedItem(softwareList[0]);
    }
  }, [selectedSoftware, softwareList]);

  const seriesData = useMemo(() => {
    return series?.[selectedView]?.[selectedItem?._id] ?? [];
  }, [series, selectedView, selectedItem]);

  const isLoading = useMemo(() => {
    return !series?.[selectedView];
  }, [series, selectedView]);

  const options = {
    ...commonOptions,
    tooltip: {
      y: {
        title: {
          formatter: () => 'Number of Licences',
        },
        formatter: function (_, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ].tooltip;
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Skeleton
        height={height}
        baseColor='#333'
        highlightColor='#444'
      />
    );
  }

  return (
    <>
      <DropdownMenu
        dropdownItems={softwareList}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <ReactApexChart
        
        options={options}
        series={seriesData}
        type='line'
        height={height}
      />
    </>
  );
}
