import { useSelector } from 'react-redux';

const useInitComparisonSeries = () => {
  
  const { allSaasCostsSeries } = useSelector((state) => state.analytics);

  const data = allSaasCostsSeries?.['months']?.[0]?.data?.at(-1);
  const today = new Date();
  const x = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  const y = '100';
  const tooltip = data?.y;

  return { x, y, tooltip };
};

export default useInitComparisonSeries;
