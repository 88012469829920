import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MemberCard from '../../UI/MemberCard';
import InviteUsersEmail from './InviteUsersEmail';

export default function AddUsersStep(props) {
  const { selectedUsers, toggleUserSelection, teamId } = props;
  
  const { company } = useSelector((state) => state.company);

  
  const { teamData } = useSelector((state) => state.teams);

  
  const { userData } = useSelector((state) => state.user);

  const users = useMemo(() => {
    let existingUsers = new Set();

    if (teamId) {
      const team = teamData[teamId];
      existingUsers = new Set(
        team?.users?.map((user) => userData[user?.userId]?._id)
      );
    }
    return company?.users
      ?.filter((user) => !existingUsers.has(user?._id))
      ?.map((user) => {
        return {
          ...user,
        };
      });
  }, [teamId, userData, teamData, company]);

  const renderMemberCards = () => {
    return users?.map((user) => (
      <MemberCard
        key={user?._id}
        member={user}
        onClick={() => toggleUserSelection(user?._id)}
        isSelected={selectedUsers[user?._id]}
      />
    ));
  };

  return (
    <>
      <div className='titles'>
        <p className='aligned'>
          Select available users to add to team{' '}
          <i className='bx bxs-chevron-right'></i>
        </p>
      </div>
      <div className='members-cards-container'>{renderMemberCards()}</div>
      <InviteUsersEmail teamId={teamId} />
    </>
  );
}
