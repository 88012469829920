import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthCallback from './components/Providers/AuthCallback';
import ScrollTop from './components/ScrollTop';
import Company from './components/Start/Company';
import Confirm from './components/Start/Confirm';
import Login from './components/Start/Login';
import Signup from './components/Start/Signup';
import DashboardContainer from './containers/DashboardContainer';
import StartContainer from './containers/StartContainer';
import useAuthRedirect from './hooks/useAuthRedirect';
import './index.scss';
import { AlertProvider } from './providers/AlertProvider';

export default function App() {
  useAuthRedirect();

  return (
    <>
      <ScrollTop />
      <AlertProvider>
        <Routes>
          <Route
            path='/start/'
            element={<StartContainer />}
          >
            <Route
              index
              path='login'
              element={<Login />}
            />
            <Route
              index
              path='signup'
              element={<Signup />}
            />
            <Route
              index
              path='confirm/:token'
              element={<Confirm />}
            />
            <Route
              index
              path='confirm_provider/:token'
              element={<Confirm />}
            />
            <Route
              index
              path='confirm_team_invite/:token'
              element={<Confirm />}
            />
          </Route>
          <Route
            path='/company'
            element={<Company />}
          />
          <Route
            path='/dashboard'
            element={<DashboardContainer page='/' />}
          />
          <Route
            path='/dashboard/analytics'
            element={<DashboardContainer page='analytics' />}
          />
          <Route
            path='/dashboard/invoices'
            element={<DashboardContainer page='invoices' />}
          />
          <Route
            path='/dashboard/invoices/folder/:folderId'
            element={<DashboardContainer page='folder' />}
          />
          <Route
            path='/dashboard/application/:softwareName'
            element={<DashboardContainer page='application' />}
          />
          <Route
            path='/dashboard/applications'
            element={<DashboardContainer page='applications' />}
          />
          <Route
            path='/dashboard/team/:teamName'
            element={<DashboardContainer page='team' />}
          />
          <Route
            path='/dashboard/teams'
            element={<DashboardContainer page='teams' />}
          />
          <Route
            path='/dashboard/connect'
            element={<DashboardContainer page='connect-to-providers' />}
          />
          <Route
            path='/dashboard/profile/:userId'
            element={<DashboardContainer page='profile' />}
          />
          <Route
            path='/auth/callback'
            element={<AuthCallback />}
          />
          <Route
            path='/*'
            element={<DashboardContainer page='/' />}
          />
        </Routes>
      </AlertProvider>
    </>
  );
}
