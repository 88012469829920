import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamCostsAndLicenceCountByYearAction } from '../../redux/features/analytics/analyticsThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';
import { getMultipleUserDataAction } from '../../redux/features/user/userThunks';
import AddTeamModal from '../Modals/Add Team/AddTeamModal';
import TeamCard from './TeamCard';
import TeamComponent from './TeamComponent';
import Skeleton from 'react-loading-skeleton';

export default function ManagedTeamsComponent() {
  const dispatch = useDispatch();
  const { teamIds, teamData, userIds } = useSelector((state) => state.teams);
    const [isLoading, setIsLoading] = useState(true);
  
  const teams = useMemo(
    () => teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware'),
    [teamIds, teamData]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const year = new Date().getFullYear();
    
    dispatch(getTeamCostsAndLicenceCountByYearAction(year));
    
    dispatch(getManagedTeamsAndMonthlyCostsAction()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (userIds?.length) {
      
      dispatch(getMultipleUserDataAction(userIds));
    }
  }, [dispatch, userIds]);

  const toggleAddTeamModal = (value) => {
    setIsModalOpen(value);
  };

  const renderManagedTeams = () => {
    if (isLoading) {
      return [...Array(1)].map((_, index) => {
        return (
          <Skeleton
          key={index}
          height={250}
          width={300}
          baseColor="#333"
          highlightColor="#444"
          className="team-card"
        />
        )
      })
    }
    if (teams?.length) {
      return teams.map((team, index) => (
        <TeamCard
          key={team._id}
          team={team}
          index={index}
        />
      ));
    }
  };

  const renderAddTeamCard = () => {
    return (
      <div className='team-card card-add' onClick={toggleAddTeamModal}>
        <div className='team-card-body'>
          <div
            className='add-button'
          >
            <div className='plus-sign'>
              <i className='bx bx-plus'></i>
            </div>
            <p>Add Team</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className='titles-container'>
        <h2>
          <span>Teams</span>
        </h2>
        <h4>Manage, budget and list your teams.</h4>
      </div>

      <div className='teams-cards-container'>
        {renderAddTeamCard()}
        {renderManagedTeams()}
      </div>

      <div
        className='titles-container'
        style={{ paddingTop: '4rem' }}
      >
        <h2>
          <span>Managed Teams</span>
        </h2>
        <h4>View details from the managed teams and switch between teams.</h4>
      </div>
      <TeamComponent />

      {isModalOpen && <AddTeamModal toggleModal={toggleAddTeamModal} />}
    </section>
  );
}
