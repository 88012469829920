import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications } from '../../../services/api/event/fetch';
import { seeNotifications } from '../../../services/api/event/modify';
import getFormattedEvents from '../../../services/events/format';

export const getNotificationsAction = createAsyncThunk(
  'notifications/getNotifications',
  
  async ({ excludedTypes, options }, { rejectWithValue }) => {
    try {
      const notifications = await getNotifications(excludedTypes, options);

      const { eventIds, eventData, seenEvents } =
        getFormattedEvents(notifications);

      return { eventIds, eventData, seenEvents };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const seeNotificationsAction = createAsyncThunk(
  'notifications/seeNotifications',
  async (eventIds, { rejectWithValue }) => {
    try {
      await seeNotifications(eventIds);

      return eventIds;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
