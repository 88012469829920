import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import { getPaymentTypeValue } from '../../services/software/format';
import { formatDate } from '../../util/formatDate';
import formatToSlug from '../../util/formatToSlug';

export default function ProfileSoftwareRow(props) {
  const { software } = props;

  const renderPaymentType = (software) => {
    if (!software?.paymentType) {
      return (
        <Skeleton
          width={80}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }

    return getPaymentTypeValue(software.paymentType);
  };

  const renderSoftwareMonthlyCosts = (software) => {
    if (software?.isActive != null) {
      return software?.monthlyCosts !== undefined
        ? `€${software?.monthlyCosts?.toFixed(3)}`
        : '€0.000';
    } else {
      return (
        <Skeleton
          width={50}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }
  };

  return (
    <Link to={`/dashboard/application/${formatToSlug(software?.name)}`}>
      <div className='row'>
        <div className='element main'>
          <div
            className='icon'
            style={{ backgroundImage: `url(${software?.imageUrl})` }}
          />
          <div className='software-name'>
            {software?.name || (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>
          {software?.team != null ? (
            software?.team?.name
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        <div className='element'>
          <div
            className={
              'user-activity' + (software?.isActive ? '' : ' not-active')
            }
          >
            • {software?.isActive ? 'Active' : 'Inactive'}
          </div>
        </div>
        <div className='element'>
          <div>
            {formatDate(software?.lastActivity) || (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>{renderSoftwareMonthlyCosts(software)}</div>
        <div className='element'>
          {software?.totalCosts != null ? (
            `€${software?.totalCosts?.toFixed(3)}`
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        <div className='element'>{renderPaymentType(software)}</div>
      </div>
    </Link>
  );
}
