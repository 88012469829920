import formatToSlug from '../../util/formatToSlug';

export const formatTierName = (tier) => {
  if (!tier) return tier;

  return tier
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getPaymentTypeValue = (paymentType) => {
  const paymentTypeValue = {
    '1/1': 'Monthly',
    '1/3': 'Quarterly',
    '1/6': 'Bi-Yearly',
    '1/12': 'Yearly',
  };

  return paymentTypeValue[paymentType];
};

export const getFormattedTrackedSoftwareData = (trackedSoftwareData) => {
  const trackedSoftwareIds = Object.keys(trackedSoftwareData['softwareData'])
    .map((id) => {
      let data = trackedSoftwareData['softwareData'][id];

      return {
        id,
        monthlyCosts: data?.monthlyCosts,
        totalCosts: data?.totalCosts,
        name: data?.name,
      }
    })
    .sort((a, b) => {
      if (b.monthlyCosts === a.monthlyCosts) {
        return b.name.length - a.name.length;
      }
      return b.monthlyCosts - a.monthlyCosts;
    })
    .map((software) => software.id);

  const softwareSlugToId = {};

  const staticSoftwareData = trackedSoftwareIds.reduce((acc, softwareId) => {
    const { name, description, imageUrl, link, categories, tierToName } =
      trackedSoftwareData['softwareData'][softwareId];

    softwareSlugToId[formatToSlug(name)] = softwareId;

    acc[softwareId] = {
      _id: softwareId,
      name,
      description,
      imageUrl,
      link,
      categories,
      tierToName,
    };

    return acc;
  }, {});

  const allDynamicSoftwareData = trackedSoftwareIds.reduce(
    (acc, softwareId) => {
      const { teams, monthlyCosts, paymentData, userMonthlyCosts, prices, totalCosts } =
        trackedSoftwareData['softwareData'][softwareId];

      acc[softwareId] = {
        teams,
        monthlyCosts,
        totalCosts,
        paymentData,
        userMonthlyCosts,
        prices,
      };

      return acc;
    },
    {}
  );

  const { userIds } = trackedSoftwareData;

  return {
    trackedSoftwareIds,
    staticSoftwareData,
    softwareSlugToId,
    allDynamicSoftwareData,
    userIds,
  };
};

export const getFormattedMultipleTeamsAvailableSoftware = (data) => {
  const { allSoftwareData, excludedSoftwareIds } = data;

  const excludedSoftwareIdsSet = new Set(excludedSoftwareIds);

  const availableSoftwareIds = allSoftwareData
    .filter((software) => !excludedSoftwareIdsSet.has(software._id))
    .map((software) => software._id);

  const [staticSoftwareData, allDynamicSoftwareData] = allSoftwareData.reduce(
    ([staticData, dynamicData], software) => {
      const { prices, ...rest } = software;
      staticData[software._id] = rest;
      dynamicData[software._id] = { prices };

      return [staticData, dynamicData];
    },
    [{}, {}]
  );

  return {
    availableSoftwareIds,
    excludedSoftwareIds,
    staticSoftwareData,
    allDynamicSoftwareData,
  };
};

export const getFormattedSoftwareData = (softwareData) => {
  const softwareIds = softwareData.map((software) => software._id);

  const staticSoftwareData = softwareData.reduce((acc, software) => {
    const { _id, ...rest } = software;
    acc[_id] = rest;

    return acc;
  }, {});

  return { softwareIds, staticSoftwareData };
};

export const getFormattedTrackedSoftwareDataByTeam = (
  allDynamicSoftwareData
) => {
  const { dynamicSoftwareDataByTeam } = allDynamicSoftwareData;
  const trackedSoftwareDataByTeam = {};
  const trackedSoftwareIdsByTeam = {};

  Object.keys(dynamicSoftwareDataByTeam).forEach((teamId) => {
    const teamData = dynamicSoftwareDataByTeam[teamId];
    trackedSoftwareDataByTeam[teamId] = {};

    const trackedSoftwareIds = Object.keys(teamData)
      .map((id) => ({
        id,
        monthlyCosts: teamData[id].monthlyCosts,
        name: teamData[id].softwareName,
      }))
      .sort((a, b) => {
        if (b.monthlyCosts === a.monthlyCosts) {
          return b.name.length - a.name.length;
        }
        return b.monthlyCosts - a.monthlyCosts;
      })
      .map((software) => software.id);

    trackedSoftwareIdsByTeam[teamId] = trackedSoftwareIds;

    trackedSoftwareIds.forEach((softwareId) => {
      const softwareData = teamData[softwareId];

      trackedSoftwareDataByTeam[teamId][softwareId] = softwareData;
    });
  });

  return { trackedSoftwareDataByTeam, trackedSoftwareIdsByTeam };
};
