import React, { useEffect, useRef, useState } from 'react';
import useHandleChartRender from '../../hooks/useHandleChartRender';
import { getChartName } from '../../util/UtilFunctions';

function AnalyticsCard({ widget, onDelete }) {
  const { handleChartRender } = useHandleChartRender();

  const [selectedView, setSelectedView] = useState('months');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showViewDropdown, setShowViewDropdown] = useState(false);

  const dropDownItems = ['months', 'years'];
  const dropdownOptions = [
    {
      value: 'delete',
      onClick: onDelete,
      icon: 'bx bx-trash',
    },
  ];

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const viewDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        viewDropdownRef.current &&
        !viewDropdownRef.current.contains(event.target)
      ) {
        setShowViewDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen, setShowViewDropdown]);

  const widgetType = widget?.type;

  const height = 150;

  const handleViewChange = (view) => {
    setSelectedView(view);
    setShowViewDropdown(false);
  };

  return (
    <div className='card analytics'>
      <div className='card-header'>
        <div>
          <div className='title'>{getChartName(widgetType)}</div>
        </div>
        {widgetType !== 'analytics-comparison' && (
          <div
            className='button'
            onClick={() => setShowViewDropdown(!showViewDropdown)}
            ref={viewDropdownRef}
          >
            {selectedView} <i className='bx bxs-chevron-down'></i>
            {showViewDropdown && (
              <div className='dropdown-menu'>
                {dropDownItems.map((item) => (
                  <div
                    key={item}
                    className='dropdown-item'
                    onClick={() => handleViewChange(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <i
          ref={iconRef}
          className='bx bx-dots-horizontal options pointer'
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className='dropdown-menu widget'
            ref={dropdownRef}
          >
            {dropdownOptions.map((option) => {
              return (
                <div
                  key={crypto.randomUUID()}
                  className='dropdown-item'
                  onClick={option?.onClick}
                >
                  <i className={option?.icon} />
                  {option?.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {handleChartRender(widgetType, selectedView, height)}
    </div>
  );
}

export default React.memo(AnalyticsCard);
