import formatToSlug from '../../util/formatToSlug';
import getTimestamp from './get';

export const getFormattedEvents = (events) => {
  const eventIds = events.map((event) => event._id);

  const { eventData, seenEvents } = events.reduce(
    (acc, event) => {
      const { _id, createdAt, isSeen } = event;

      const timestamp = getTimestamp(createdAt);

      acc.eventData[_id] = {
        ...event,
        thumbnail: getThumbnail(event),
        link: getLink(event),
        timestamp,
      };

      acc.seenEvents[_id] = isSeen;

      return acc;
    },
    { eventData: {}, seenEvents: {} }
  );

  return { eventIds, eventData, seenEvents };
};

const getThumbnail = (event) => {
  const { type, metaData } = event;

  const getImageUrl = (url) => (url && url.trim() !== '' ? url : '');

  switch (type) {
    case 'new-software':
      return getImageUrl(metaData?.software?.imageUrl);
    case 'create-company':
      return getImageUrl(metaData?.company?.imageUrl);
    case 'set-company-manager':
      return getImageUrl(metaData?.company?.imageUrl);
    case 'create-team':
      return getImageUrl(metaData?.team?.imageUrl);
    case 'set-team-manager':
      return getImageUrl(metaData?.manager?.imageUrl);
    case 'add-team-to-company':
      return getImageUrl(metaData?.company?.imageUrl);
    case 'add-user-to-company':
      return getImageUrl(metaData?.user?.imageUrl);
    case 'add-team-software':
      return getImageUrl(metaData?.software?.imageUrl);
    case 'add-user-to-team':
      return getImageUrl(metaData?.user?.imageUrl);
    case 'gain-new-licence':
      return getImageUrl(metaData?.user?.imageUrl);
    case 'deactivate-licence':
      return getImageUrl(metaData?.user?.imageUrl);
    case 'activate-licence':
      return getImageUrl(metaData?.user?.imageUrl);
    case 'deactivate-team-software':
      return getImageUrl(metaData?.software?.imageUrl);
    case 'activate-team-software':
      return getImageUrl(metaData?.software?.imageUrl);
    case 'update-company':
      return getImageUrl(metaData?.updatedCompany?.imageUrl);
    case 'update-team':
      return getImageUrl(metaData?.updatedTeam?.imageUrl);
    case 'update-user':
      return getImageUrl(metaData?.updatedUser?.imageUrl);
    case 'renewal-date-reminder':
      return getImageUrl(metaData?.software?.imageUrl);
    default:
      return '';
  }
};

const getLink = (event) => {
  const { type, metaData } = event;

  switch (type) {
    case 'new-software':
      return `/dashboard/application/${formatToSlug(metaData?.software?.name)}`;
    case 'create-company':
      return `/dashboard/company/${formatToSlug(metaData?.company?.name)}`;
    case 'set-company-manager':
      return `/dashboard/company/${formatToSlug(metaData?.company?.name)}`;
    case 'create-team':
      return `/dashboard/team/${formatToSlug(metaData?.team?.name)}`;
    case 'set-team-manager':
      return `/dashboard/team/${formatToSlug(metaData?.team?.name)}`;
    case 'add-team-to-company':
      return `/dashboard/company/${formatToSlug(metaData?.company?.name)}`;
    case 'add-user-to-company':
      return `/dashboard/company/${formatToSlug(metaData?.company?.name)}`;
    case 'add-team-software':
      return `/dashboard/application/${formatToSlug(metaData?.software?.name)}`;
    case 'add-user-to-team':
      return `/dashboard/team/${formatToSlug(metaData?.team?.name)}`;
    case 'gain-new-licence':
      return `/dashboard/profile/${metaData?.user?._id}`;
    case 'deactivate-licence':
      return `/dashboard/profile/${metaData?.user?._id}`;
    case 'activate-licence':
      return `/dashboard/profile/${metaData?.user?._id}`;
    case 'deactivate-team-software':
      return `/dashboard/application/${formatToSlug(metaData?.software?.name)}`;
    case 'activate-team-software':
      return `/dashboard/application/${formatToSlug(metaData?.software?.name)}`;
    case 'update-company':
      return `/dashboard/company/${formatToSlug(
        metaData?.updatedCompany?.name
      )}`;
    case 'update-team':
      return `/dashboard/team/${formatToSlug(metaData?.updatedTeam?.name)}`;
    case 'update-user':
      return `/dashboard/profile/${metaData?.updatedUser?._id}`;
    case 'renewal-date-reminder':
      return `/dashboard/application/${formatToSlug(metaData?.software?.name)}`;
    default:
      return '/dashboard';
  }
};

export default getFormattedEvents;
