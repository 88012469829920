import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManagedTeamsAndMonthlyCostsAction,
  removeUsersAction,
  removeUsersFromMultipleTeamsAction,
} from '../../redux/features/teams/teamsThunks';

export const useUserDropdown = (user, teamId) => {
  const dispatch = useDispatch();

  
  const prevState = useSelector((state) => state.teams);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => setIsModalOpen(false));

  const toggleModal = useCallback((value) => {
    setIsModalOpen(value);
  }, []);

  const onRemove = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (teamId) {
        setMessage(
          `Are you sure you want to remove ${user.name} from the team?`
        );
      } else {
        setMessage(
          `Are you sure you want to remove ${user.name} from all teams?`
        );
      }

      setValue('remove user');

      const onConfirm = () => {
        if (teamId) {
          dispatch(
            
            removeUsersAction({ teamId, userIds: [user._id], prevState })
            
          ).finally(() => {
            
            dispatch(getManagedTeamsAndMonthlyCostsAction());
          });
        } else {
          dispatch(
            
            removeUsersFromMultipleTeamsAction({
              userIds: [user._id],
              prevState,
            })
            
          ).finally(() => {
            
            dispatch(getManagedTeamsAndMonthlyCostsAction());
          });
        }
        toggleModal(false);
      };
      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, user, teamId, prevState, toggleModal]
  );

  const dropdownOptions = [
    {
      value: 'remove user',
      onClick: onRemove,
      icon: 'bx bx-user-minus',
    },
  ];

  return {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
  };
};
