import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/folder';

export const getAllFolders = async () => {
  try {
    const response = await axios.get(`${prefix}/company`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
