import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDate } from '../../util/formatDate';
import formatFileSize from '../../util/formatFileSize';

export default function RecentFileComponent({
  file,
  isEditingRecent,
  editValue,
  handleEdit,
  handleSave,
  toggleDropdown,
  handleDelete,
  visibleRecentDropdown,
  setEditValue,
  setSelectedFile
}) {
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        toggleDropdown(null, true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  const renderRecentFileContent = () => {
    return (
      <div className='recent-file-content'>
        {!isEditingRecent || isEditingRecent !== file?._id ? (
          file?.name ? (
            <h3>{file.name}</h3>
          ) : (
            <Skeleton
              width={150}
              baseColor='#333'
              highlightColor='#444'
            />
          )
        ) : (
          <input
            type='text'
            className='text-input folder-input'
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={() => handleSave(file._id, true, true)}
            autoFocus
          />
        )}
        {file?.createdAt ? (
          <p>
            {formatDate(file?.createdAt)} • {formatFileSize(file?.size)}
          </p>
        ) : (
          <Skeleton
            width={100}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className='recent-file-container' onClick={() => setSelectedFile(file)}>
        <div className='preview'>
          {file ? (
            <i
              className={
                file?.type === 'pdf' ? 'bx bx-file' : 'bx bxs-file-image'
              }
            />
          ) : (
            <Skeleton
              circle={true}
              height={40}
              width={40}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        {renderRecentFileContent()}
        <div className='options-container'>
          {file ? (
            <i
              ref={iconRef}
              className='bx bx-dots-vertical-rounded'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleDropdown(file?._id, true)
              }}
            />
          ) : (
            <Skeleton
              width={20}
              height={20}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        {visibleRecentDropdown === file?._id && file && (
          <div
            className='dropdown-menu'
            ref={dropdownRef}
          >
            <ul>
              <li onClick={() => handleDelete(file?._id, true)}>
                <i className='bx bx-trash' /> Delete
              </li>
              <li onClick={() => handleEdit(file?._id, file.name, true)}>
                <i className='bx bx-edit' /> Edit
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
