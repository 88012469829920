import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/invoice';

export const getAllInvoices = async (page, limit, search, software, folder) => {
  try {
    const response = await axios.get(`${prefix}/company`, {
      params: { page, limit, search, software, folder },
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getRecentInvoices = async (limit) => {
  try {
    const response = await axios.get(`${prefix}/recent`, {
      params: { limit },
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTotalInvoiceSoftwareCost = async (softwareId) => {
  try {
    if (!softwareId) {
      return window.location.reload()
    }

    const response = await axios.get(`${prefix}/software/costs/${softwareId}`, {
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};