import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getMetric } from '../../util/UtilFunctions';

export default function ProfileCard(props) {
  const { user, targetRef } = props;

  const monthlyCosts = useMemo(
    () =>
      Object.keys(user?.costsBySoftware || {}).reduce((acc, softwareId) => {
        acc += user?.costsBySoftware[softwareId];
        return acc;
      }, 0),
    [user]
  );

  const activeLicences = useMemo(
    () => user?.licences.filter((licence) => licence.isActive),
    [user]
  );

  const scrollDown = () => {
    if (targetRef.current)
      targetRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const renderUserTeamsTags = () => {
    if (!user?.teams?.length) {
      return;
    }

    return user?.teams.map((team) => {
      const isManager = team?.manager === user?._id;

      return (
        <span
          key={team?._id}
          className={`category ${isManager ? 'selected' : ''}`}
        >
          {team?.name || (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </span>
      );
    });
  };

  return (
    <div className='profile_card'>
      <div className='profile_picture_container'>
        <div className='picture_container'>
          <div
            className='user-image'
            style={{
              backgroundImage: `url(${user?.imageUrl || 'https://i2.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/' + (user?.name?.length ? user?.name[0]?.toUpperCase() : '') + '-6.png?ssl=1'} )`,
            }}
          >
            {!user && (
              <Skeleton
                circle={true}
                height={70}
                width={70}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <h3>
          {user?.name ? (
            user.name
          ) : (
            <Skeleton
              width={100}
              baseColor='#333'
              highlightColor='#444'
            />
          )}{' '}
          <i className='bx bxs-star' />
        </h3>
        <h4 style={{ textTransform: 'lowercase' }}>{user?.email}</h4>
      </div>

      <div className='card-content'>
        <div className='details'>
          <div className='detail'>
            <div className='label'>Total Licences</div>
            <div className='metric'>
              <span>
                {user?.licences?.length !== undefined ? (
                  user?.licences.length
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
            </div>
          </div>

          <div className='detail'>
            <div className='label'>Active Applications</div>
            <div className='metric'>
              <span>
                {activeLicences?.length !== undefined ? (
                  activeLicences.length
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
              <span className='decimal'>
                {' '}
                /{' '}
                {user?.licences?.length !== undefined ? (
                  user.licences.length
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
            </div>
          </div>

          <div className='detail'>
            <div className='label'>Monthly Costs (last 31 days)</div>
            <div className='metric'>
              <span className='currency'>€</span>
              <span>
                {' '}
                {getMetric(monthlyCosts)?.first !== undefined ? (
                  getMetric(monthlyCosts).first
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
              <span className='decimal'>
                {getMetric(monthlyCosts)?.second !== undefined ? (
                  getMetric(monthlyCosts).second
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
            </div>
          </div>

          <div className='detail'>
            <div className='label'>Extension</div>
            <div className='metric'>
              <span style={{fontSize: "16px", marginTop: '5px', display: 'block'}}>
                {user?.isExtensionInstalled !== undefined ? (
                  user?.isExtensionInstalled ? 'Installed' : 'Not Installed'
                ) : (
                  <Skeleton
                    width={20}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </span>
            </div>
          </div>
        </div>

        <div className='details second_row'>
          <div className='detail teams'>
            <div className='label'>Teams</div>
            <div className='metric flex'>{renderUserTeamsTags()}</div>
          </div>

          <div className='detail button-container' onClick={scrollDown}>
            <div className='button'>
              View Details
              <i className='bx bx-chevron-down' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
