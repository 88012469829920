import React, { useEffect, useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import { useInvoiceDropdown } from '../../hooks/Dropdown/useInvoiceDropdown';
import { formatTierName } from '../../services/software/format';
import { formatDate } from '../../util/formatDate';
import ApplicationUsersTable from '../Application/Users/ApplicationUsersTable';
import UpdateInvoiceModal from '../Modals/Update/UpdateInvoiceModal';
import SoftwareDropdown from './SoftwareDropDown';

export default function InvoiceRow(props) {
  const { invoice, isSelected, onClick, fromApplication, setInvoiceConfirmation } = props;

  const { staticSoftwareData, userMonthlyCostsBySoftware } = useSelector(
    (state) => state.software
  );

  const { userData } = useSelector((state) => state.user);

  const licenceUsers = useMemo(
    () =>
      invoice?.licences?.map((licence) => {
        return {
          ...userData[licence?.user],
          ...userMonthlyCostsBySoftware?.[licence?.user]?.[
            invoice?.software?._id
          ],
        };
      }),
    [invoice?.licences, userData, userMonthlyCostsBySoftware, invoice?.software]
  );

  const {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    isEditingValue,
    editedValue,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
    handleValueClick,
    handleValueChange,
    handleValueBlur,
    isEditingDueDate,
    editedDueDate,
    handleDueDateClick,
    handleDueDateChange,
    handleDueDateBlur,
  } = useInvoiceDropdown(invoice, setInvoiceConfirmation);

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  const renderTier = () => {
    if (!invoice?.licences) {
      return (
        <Skeleton
          width={100}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }

    const tiers = Array.from(
      new Set(invoice?.licences?.map((licence) => licence?.tier))
    );

    if (tiers.length > 1) {
      return 'Multiple';
    }

    if (tiers.length === 0) {
      return 'No tier';
    }

    if (staticSoftwareData[invoice?.software?._id]?.tierToName?.length && tiers?.length)
      return formatTierName(
        staticSoftwareData[invoice?.software?._id]?.tierToName[tiers[0]]
      );
    return 'No tier'
  };

  const renderValue = () => {
    if (isEditingValue) {
      return (
        <input
          type='number'
          value={editedValue}
          onChange={handleValueChange}
          onBlur={handleValueBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              
              e.target.blur();
            }
          }}
          className='text-input'
          autoFocus
        />
      );
    }

    return (
      <>
        {invoice?.value !== null ? (
          `${invoice.currency && invoice.currency !== 'unknown' ? invoice.currency : '€'}${invoice.value}`
        ) : (
          <Skeleton
            width={50}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </>
    );
  };

  const renderDueDate = () => {
    if (isEditingDueDate) {
      return (
        <input
          type='date'
          value={editedDueDate}
          onChange={handleDueDateChange}
          onBlur={handleDueDateBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              
              e.target.blur();
            }
          }}
          className='text-input'
          autoFocus
        />
      );
    }

    return (
      <>
        {invoice?.dueDate || invoice?.createdAt ? (
          formatDate(invoice?.dueDate || invoice?.createdAt)
        ) : (
          <Skeleton
            width={100}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={`row ${isSelected ? 'active' : ''}`}
        onClick={onClick}
      >
        <div className='element'>
          <div className='title'>
            {invoice?.name ? (
              invoice.name
            ) : (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element'>
          <div
            className='icon avatar'
            style={{
              backgroundImage: `url(${
                invoice?.owner?.imageUrl || 'https://placehold.co/400'
              })`,
            }}
          >
            {!invoice?.owner && (
              <Skeleton
                circle={true}
                height={20}
                width={20}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
          <div>
            {invoice?.owner ? (
              invoice.owner.name
            ) : (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        {!fromApplication && <SoftwareDropdown invoice={invoice} />}
        <div className='element'>{renderTier()}</div>
        <div className='element'>
          {invoice?.licences ? (
            invoice.licences.length
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        <div
          className='element'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleValueClick();
          }}
        >
          {renderValue()}
        </div>
        <div
          className='element'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDueDateClick();
          }}
        >
          {renderDueDate()}
          <i
            ref={iconRef}
            className='icon options pointer'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropdownOpen((prev) => !prev);
            }}
          />
          {dropdownOpen && (
            <div
              ref={dropdownRef}
              className='dropdown-menu'
            >
              {dropdownOptions.map((option) =>  {
                if (option?.value === 'Confirm Data' && invoice?.isConfirmed)
                  return;

                return (
                  <div
                    key={crypto.randomUUID()}
                    className='dropdown-item'
                    onClick={option?.onClick}
                  >
                    <i className={option?.icon} />
                    {option?.value}
                  </div>
                )})}
            </div>
          )}
        </div>
      </div>
      {isSelected && (
        <div style={{ transform: 'scale(.95)' }}>
          <p className='invoice-licences-details'>
            Invoice licences details <i className='bx bx-chevron-down' />
          </p>
          {licenceUsers?.length ? (
            <ApplicationUsersTable
              users={licenceUsers}
              fromInvoiceRow={true}
            />
          ) : (
            <p
              className='invoice-licences-details empty'
              style={{ margin: '2rem 0' }}
            >
              <i className='bx bx-error-circle' /> No licences data yet
            </p>
          )}
        </div>
      )}
      {isModalOpen && (
        <UpdateInvoiceModal
          toggleModal={toggleModal}
          invoiceId={invoice._id}
          message={message}
          value={value}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}
