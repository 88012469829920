import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';

const mockEvents = Array.from({ length: 10 }, (_, index) => ({
  _id: index,
  thumbnail: '',
  description: '',
  timestamp: '',
}));

export default function AchievementMenu(props) {
  const { data } = props;

  
  const { isLoading, eventIds, eventData } = useSelector(
    
    (state) => state.events
  );

  const events = useMemo(
    () => eventIds.map((eventId) => eventData[eventId]),
    [eventIds, eventData]
  );

  const displayEvents = useMemo(
    () => (isLoading ? mockEvents : events),
    [isLoading, events]
  );

  const renderGoalCards = (goals) => {
    return (
      <>
        {goals.map((goal) => {
          return (
            <>
              <div className='goal-card'>
                <div className='goal-title'>{goal.type}</div>
                <div className='value'>
                  <span>{goal.unit}</span> {goal.goalValue} 000*
                </div>
                <div className='footer'>
                  <p>* {goal.period}</p>
                  <div className='icons'>
                    <i className='bx bxs-circle '></i>
                    <i className='bx bxs-circle behind'></i>
                  </div>
                </div>
              </div>

              <div className='goal-card-behind' />

              <div className='progress-bar-container'>
                {renderProgressBar(goal.currentValue, goal.goalValue)}
                <div className='text'>
                  {/*your current growth*/} your budget
                  <div className='values'>
                    <span>
                      {goal.unit} {goal.currentValue} K /{' '}
                    </span>
                    {goal.unit} {goal.goalValue}K
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  const getEventImageSize = (type) => {
    switch (type) {
      case 'add-user-to-team':
      case 'deactivate-licence':
      case 'activate-licence':
        return 'cover';
      default:
        return false;
    }
  };

  const renderEvents = (events) => {
    return (
      <>
        {events.map((event) => {
          const isMock = !event.description;
          return (
            <div
              key={event._id}
              className='event-row'
            >
              <div
                className='event-image'
                style={{
                  backgroundImage: event?.thumbnail ? `url(${event?.thumbnail})` : '',
                  
                  backgroundSize: getEventImageSize(event?.type),
                }}
              >
                {isMock && (
                  <Skeleton
                    circle={true}
                    height={40}
                    width={40}
                    baseColor='#333'
                    highlightColor='#444'
                  />
                )}
              </div>
              <div className='event-text'>
                {isMock ? (
                  <>
                    <Skeleton
                      width={200}
                      baseColor='#333'
                      highlightColor='#444'
                    />
                    <Skeleton
                      width={150}
                      baseColor='#333'
                      highlightColor='#444'
                    />
                  </>
                ) : (
                  <>
                    <div className='title'>{event?.description}</div>
                    <div className='subtitle'>{event?.timestamp}</div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderProgressBar = (currentValue, goalValue) => {
    const progress = (currentValue / goalValue) * 100;

    return (
      <div className='progress-bar'>
        <div
          className='progress-bar-fill'
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  };

  return (
    <div className='big-card two-rows'>
      <div className='card-header'>
        <div className='title'> Achievements</div>
        <i className='bx bx-dots-horizontal options background'></i>
      </div>
      <div className='card-content'>
        <div className='goals-container'>
          {renderGoalCards(data.goals)}
          <i className='bx bx-chevron-right right chevron'></i>
          {/* <i className="bx bx-chevron-left left chevron"></i> */}
        </div>
        <div className='events-container'>
          <p>New Events</p>
          <div className='events-scroll'>{renderEvents(displayEvents)}</div>
        </div>
      </div>
    </div>
  );
}
