import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserWidgetArray } from '../../../services/api/user/fetch';
import {
  deleteUserWidget,
  updateUserWidgetArray,
} from '../../../services/api/user/modify';
import {
  getAllUserWidgets,
  getWidgetDataByType,
} from '../../../services/api/widget/fetch';
import { addWidget } from '../../../services/api/widget/modify';
import { formatWidgetData } from '../../../services/widget/format/formatWidgetData';
import { getLastXWidgetsOrFill } from '../../../services/widget/get';

export const getAllUserWidgetsAction = createAsyncThunk(
  'widgets/getAllUserWidgets',
  async (_, { rejectWithValue }) => {
    try {
      const widgetArray = await getUserWidgetArray();

      if (widgetArray?.length === 0) {
        const userWidgets = await getAllUserWidgets();
        const widgets = getLastXWidgetsOrFill(userWidgets);

        return widgets;
      }

      return getLastXWidgetsOrFill(widgetArray);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWidgetDataByTypeAction = createAsyncThunk(
  'widgets/getWidgetDataByType',
  async (type, { rejectWithValue }) => {
    try {
      console.log('getWidgetDataByTypeAction', type)
      const widgetData = await getWidgetDataByType(type);
      console.log('widgetData', widgetData)

      const formattedData = formatWidgetData(widgetData);

      return formattedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addWidgetAction = createAsyncThunk(
  'widgets/addWidget',
  async (widget, { rejectWithValue }) => {
    try {
      const newWidget = await addWidget(widget);

      return newWidget;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWidgetAction = createAsyncThunk(
  'widgets/deleteWidget',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { widgetId } = arg;

      await deleteUserWidget(widgetId);

      return widgetId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserWidgetArrayAction = createAsyncThunk(
  'widgets/updateOrder',
  
  async ({ newWidgets }, { rejectWithValue }) => {
    try {
      const widgets = await updateUserWidgetArray(newWidgets);

      return widgets;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
