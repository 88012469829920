import { createSlice } from '@reduxjs/toolkit';
import {
  getAllTrackedSoftwareYearlyCostsAndLicenceCountAction,
  getTeamCostsAndLicenceCountByYearAction,
  getTeamYearlyCostsAndLicenceCountAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
  getTrackedTeamMonthlyCostsByDateAction,
} from './analyticsThunks';

const initialState = {
  costHistorySeries: {},
  nbLicencesSeries: {},
  allSaasCostsSeries: {},
  seatsLicencesGlobalSeries: {},
  saasCostsPerTeamSeries: {},
  seatsLicencesPerTeamSeries: {},
  costDifferenceSeries: {},
  costByDateData: {},
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getTrackedSoftwareCostsAndLicenceCountByYearAction.fulfilled,
        (state, action) => {
          const {
            costHistoryMonthlySeries,
            nbLicencesMonthlySeries,
            allSaasCostsMonthlySeries,
            seatsLicencesGlobalMonthlySeries,
            costDifferenceMonthlySeries,
          } = action.payload;

          state.costHistorySeries['months'] = costHistoryMonthlySeries;
          state.nbLicencesSeries['months'] = nbLicencesMonthlySeries;
          state.allSaasCostsSeries['months'] = allSaasCostsMonthlySeries;
          state.seatsLicencesGlobalSeries['months'] =
            seatsLicencesGlobalMonthlySeries;
          state.costDifferenceSeries['months'] = costDifferenceMonthlySeries;
        }
      )
      .addCase(
        getAllTrackedSoftwareYearlyCostsAndLicenceCountAction.fulfilled,
        (state, action) => {
          const {
            costHistoryYearlySeries,
            nbLicencesYearlySeries,
            allSaasCostsYearlySeries,
            seatsLicencesGlobalYearlySeries,
            costDifferenceYearlySeries,
          } = action.payload;

          state.costHistorySeries['years'] = costHistoryYearlySeries;
          state.nbLicencesSeries['years'] = nbLicencesYearlySeries;
          state.allSaasCostsSeries['years'] = allSaasCostsYearlySeries;
          state.seatsLicencesGlobalSeries['years'] =
            seatsLicencesGlobalYearlySeries;
          state.costDifferenceSeries['years'] = costDifferenceYearlySeries;
        }
      )
      .addCase(
        getTeamCostsAndLicenceCountByYearAction.fulfilled,
        (state, action) => {
          const {
            saasCostsPerTeamMonthlySeries,
            seatsLicencesPerTeamMonthlySeries,
          } = action.payload;

          state.saasCostsPerTeamSeries['months'] =
            saasCostsPerTeamMonthlySeries;
          state.seatsLicencesPerTeamSeries['months'] =
            seatsLicencesPerTeamMonthlySeries;
        }
      )
      .addCase(
        getTeamYearlyCostsAndLicenceCountAction.fulfilled,
        (state, action) => {
          const {
            saasCostsPerTeamYearlySeries,
            seatsLicencesPerTeamYearlySeries,
          } = action.payload;

          state.saasCostsPerTeamSeries['years'] = saasCostsPerTeamYearlySeries;
          state.seatsLicencesPerTeamSeries['years'] =
            seatsLicencesPerTeamYearlySeries;
        }
      )
      .addCase(
        getTrackedTeamMonthlyCostsByDateAction.fulfilled,
        (state, action) => {
          const { date, costs } = action.payload;
          
          state.costByDateData[date] = costs;
        }
      );
  },
});

export default analyticsSlice.reducer;
