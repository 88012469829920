import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSoftwareDropdown } from '../../hooks/Dropdown/useSoftwareDropdown';
import { getPaymentTypeValue } from '../../services/software/format';
import UpdateSoftwareModal from '../Modals/Update/UpdateSoftwareModal';

export default function SoftwareRow(props) {
  const { softwareData, selectedCard, setSelectedCard, teamId } = props;

  const {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    initialDetails,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
    pricesData,
  } = useSoftwareDropdown(softwareData, teamId);

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  const renderSoftwareIssuer = (softwareData) => {
    if (!softwareData?.issuer) {
      return (
        <Skeleton
          width={80}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }

    return (
      <div className='element owner'>
        <div
          className='icon avatar'
          style={{
            backgroundImage: `url(${softwareData?.issuer?.imageUrl})`,
          }}
        />
        <div className='owner-name'>{softwareData?.issuer?.name}</div>
      </div>
    );
  };

  const renderSoftwareOwners = (softwareData) => {
    if (!softwareData?.teams) {
      return (
        <Skeleton
          width={80}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }

    if (softwareData?.teams?.length > 1) {
      return (
        <div className='element owner'>
          <div
            className='icon avatar'
            style={{
              backgroundImage: `url(${softwareData?.teams[0]?.issuer?.imageUrl})`,
            }}
          />
          <div className='owner-name'>
            {softwareData?.teams?.length} Issuers
          </div>
        </div>
      );
    }

    if (softwareData?.teams?.length === 1) {
      return (
        <div className='element owner'>
          <div
            className='icon avatar'
            style={{
              backgroundImage: `url(${softwareData?.teams[0]?.issuer?.imageUrl})`,
            }}
          />
          <div className='owner-name'>
            {softwareData?.teams[0]?.issuer?.name}
          </div>
        </div>
      );
    }
  };

  const renderPaymentType = (softwareData) => {
    if (!softwareData?.paymentData) {
      return (
        <Skeleton
          width={80}
          baseColor='#333'
          highlightColor='#444'
        />
      );
    }

    const paymentTypes = Array.from(
      new Set(softwareData.paymentData.map((payment) => payment.paymentType))
    );

    if (paymentTypes.length > 1) {
      return 'Multiple';
    }

    return getPaymentTypeValue(paymentTypes[0]);
  };

  const activeUserLength = softwareData?.userMonthlyCosts?.filter(
    (user) => user.isActive
  ).length;

  const totalUserLength = softwareData?.userMonthlyCosts?.length;

  return (
    <>
      <div
        key={softwareData._id}
        className={
          'row ' +
          (selectedCard?._id && selectedCard?._id === softwareData._id
            ? 'active'
            : '')
        }
        onClick={() => setSelectedCard(softwareData)}
      >
        <div className='element main'>
          <div
            className='icon'
            style={{ backgroundImage: `url(${softwareData?.imageUrl})` }}
          />
          <div className='software-name'>
            {softwareData?.name || (
              <Skeleton
                width={100}
                baseColor='#333'
                highlightColor='#444'
              />
            )}
          </div>
        </div>
        <div className='element shrink'>
          {activeUserLength != null && totalUserLength != null ? (
            `${activeUserLength}/${totalUserLength}`
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        {teamId
          ? renderSoftwareIssuer(softwareData)
          : renderSoftwareOwners(softwareData)}
        <div className='element'>
          {softwareData?.monthlyCosts != null ? (
            `€${softwareData.monthlyCosts.toFixed(3)}`
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
            <label>/ Month</label>
        </div>
        <div className='element'>{renderPaymentType(softwareData)}</div>
        <div className='element'>
          {softwareData?.totalCosts != null ? (
            `€${softwareData.totalCosts.toFixed(3)}`
          ) : (
            <Skeleton
              width={50}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
            <label>Total</label>
        </div>
        <div className='element options'>
          <i
            ref={iconRef}
            className='icon options pointer'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropdownOpen(!dropdownOpen);
            }}
          />
          {dropdownOpen && (
            <div
              ref={dropdownRef}
              className='dropdown-menu'
            >
              {dropdownOptions.map((option) => (
                <div
                  key={crypto.randomUUID()}
                  className='dropdown-item'
                  onClick={option?.onClick}
                >
                  <i className={option?.icon} />
                  {option?.value}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <UpdateSoftwareModal
          message={message}
          value={value}
          onConfirm={onConfirm}
          toggleModal={toggleModal}
          initialDetails={initialDetails}
          pricesData={pricesData}
        />
      )}
    </>
  );
}
