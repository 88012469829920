const getWidgetIcon = () =>
  'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png';

const getMetric = (widgetData) => {
  const { metric } = widgetData;
  metric['label'] = 'Licences Used';

  return metric;
};

const getAverage = (widgetData) => {
  const { average } = widgetData;

  const avg = Number(average);
  if (isNaN(avg)) {
    return '0';
  }
  return `Avg ${Math.round(avg)} licences / software`;
};

export const formatSubscriptionWidgetData = (widgetData) => {
  return {
    ...widgetData,
    title: 'Subscriptions',
    subtitle: 'Number of licences used',
    icon: getWidgetIcon(),
    metric: getMetric(widgetData),
    average: getAverage(widgetData),
    linkToReport: '',
  };
};

export default formatSubscriptionWidgetData;
