import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFolderAction } from '../../redux/features/folder/folderThunks';

export default function AddFolderComponent() {
  const dispatch = useDispatch();

  const [folderName, setFolderName] = useState('');
  const [isOpen, setOpen] = useState(false);

  const handleInputChange = (e) => {
    setFolderName(e.target.value);
  };
  const handleSubmit = () => {
    if (!folderName.trim()) {
      return;
    }

    
    dispatch(addFolderAction({ name: folderName }));
    setFolderName('');
    setOpen(false);
  };

  const handleOpenAdd = () => {
    if (isOpen) return handleSubmit();

    setOpen(true);
  };

  return (
    <div
      className={`folder-container ${isOpen ? '' : 'add'}`}
      onClick={handleOpenAdd}
    >
      <div className='preview'>
        <i className='bx bx-plus' />
      </div>
      <div className='folder-content'>
        {isOpen && (
          <>
            <input
              type='text'
              className='text-input folder-input'
              value={folderName}
              onChange={handleInputChange}
              placeholder='Enter folder name ...'
              autoFocus
            />
            <p style={{ fontSize: '10px' }}>Click on plus icon to add folder</p>
          </>
        )}
      </div>
    </div>
  );
}
