import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/licence';

export const addLicence = async ({ software, user, tier, paymentType }) => {
  try {
    const response = await axios.post(`${prefix}`, {
      software,
      user,
      tier,
      paymentType,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateLicence = async (
  licenceId,
  { software, user, tier, paymentType }
) => {
  try {
    const response = await axios.put(`${prefix}/${licenceId}`, {
      software,
      user,
      tier,
      paymentType,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateLicence = async (licenceId) => {
  try {
    const response = await axios.post(`${prefix}/${licenceId}/deactivate`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const renewLicence = async (licenceId) => {
  try {
    const response = await axios.post(`${prefix}/${licenceId}/renewLicence`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteLicence = async (licenceId) => {
  try {
    const response = await axios.delete(`${prefix}/${licenceId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
