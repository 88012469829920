import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  formatTierName,
  getPaymentTypeValue,
} from '../../../services/software/format';

export default function ConfirmationStep(props) {
  const {
    softwareList,
    selectedSoftwareTracker,
    selectedTeamsTracker,
    teams,
    teamData,
    userToTeams,
    handleTeamChange,
    checkedUsers,
    toggleUserCheck,
    paymentDetails,
    setPaymentDetails,
    handleTierChange,
    handlePaymentTypeChange,
  } = props;

  
  const { userData } = useSelector((state) => state.user);

  const selectedSoftwareList = useMemo(() => {
    return softwareList.filter(
      (software) => selectedSoftwareTracker[software._id]
    );
  }, [softwareList, selectedSoftwareTracker]);

  const selectedTeams = useMemo(() => {
    return teams.filter((team) => selectedTeamsTracker[team._id]);
  }, [teams, selectedTeamsTracker]);

  //TODO: Get these from the initial state of payment details
  const defaultTier = '1';
  const defaultPaymentType = '1/1';

  useEffect(() => {
    const initialPaymentDetails = {};

    selectedSoftwareList.forEach((software) => {
      selectedTeams.forEach((team) => {
        if (!initialPaymentDetails[software._id]) {
          initialPaymentDetails[software._id] = {};
        }
        initialPaymentDetails[software._id][team._id] = {
          tier: defaultTier,
          paymentType: defaultPaymentType,
        };
      });
    });

    setPaymentDetails(initialPaymentDetails);
  }, [selectedSoftwareList, selectedTeams, setPaymentDetails]);

  const renderTeamMember = (softwareId, member) => {
    const memberTeams =
      member?.teams.map((teamId) => teamData[teamId]).filter(Boolean) || [];

    return (
      <div
        key={member?._id}
        className='user'
      >
        <div
          className='user-image'
          style={{ backgroundImage: member?.imageUrl ? `url(${member?.imageUrl})` : ''}}
        ></div>
        <div className='user-info'>
          <div className='user-name'>{member?.name}</div>
          <select
            value={userToTeams[softwareId]?.[member?._id] || ''}
            onChange={(e) =>
              handleTeamChange(softwareId, member?._id, e.target.value)
            }
          >
            <option value=''>Select Team</option>
            {memberTeams.map((team) => (
              <option
                key={team._id}
                value={team._id}
              >
                {team.name}
              </option>
            ))}
          </select>
          <i
            className={`bx ${
              checkedUsers[softwareId]?.[member?._id]
                ? 'bx-check-circle'
                : 'bx-circle'
            }`}
            onClick={() => toggleUserCheck(softwareId, member?._id)}
            style={{ cursor: 'pointer' }}
          ></i>
        </div>
      </div>
    );
  };

  const renderTeamUnderSoftware = (software, team) => {
    const members = team?.users.map((user) => userData[user.userId]);

    const tiers = Object.keys(software?.tierToName || {});
    const selectedTier =
      paymentDetails[software._id]?.[team._id]?.tier || defaultTier;
    const paymentTypes = Object.keys(software?.prices?.[selectedTier] || []);
    const selectedPaymentType =
      paymentDetails[software._id]?.[team._id]?.paymentType ||
      defaultPaymentType;

    const price = software?.prices?.[selectedTier][selectedPaymentType] || 0;

    return (
      <div
        key={team._id}
        className='team-container'
      >
        <h5 style={{ margin: '.5rem 0' }}>{team.name}</h5>
        <div className='dropdowns'>
          <select
            value={
              paymentDetails[software._id]?.[team._id]?.tier || defaultTier
            }
            onChange={(e) =>
              handleTierChange(software._id, team._id, e.target.value)
            }
          >
            <option value=''>Select Tier</option>
            {tiers.map((tier) => (
              <option
                key={tier}
                value={tier}
              >
                {formatTierName(software.tierToName[tier])}
              </option>
            ))}
          </select>
          <select
            value={
              paymentDetails[software._id]?.[team._id]?.paymentType ||
              defaultPaymentType
            }
            onChange={(e) =>
              handlePaymentTypeChange(software._id, team._id, e.target.value)
            }
          >
            <option value=''>Select Payment Type</option>
            {paymentTypes.map((paymentType) => (
              <option
                key={paymentType}
                value={paymentType}
              >
                {getPaymentTypeValue(paymentType)}
              </option>
            ))}
          </select>
          <span
            className='price'
            style={{ fontSize: '12px' }}
          >
            Price: ${price}
          </span>
        </div>
        <div className='users'>
          <div className='users-list confirmation'>
            {members.map((member) => renderTeamMember(software._id, member))}
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedSoftware = () => {
    return selectedSoftwareList?.map((software) => {
      return (
        <div
          key={software?._id}
          className='software-container'
        >
          <div className='titles'>
            <div
              className='company-logo-image'
              style={{ backgroundImage: `url(${software?.imageUrl})` }}
            />
            <h5>{software?.name}</h5>
          </div>
          <div>
            {selectedTeams.map((team) =>
              renderTeamUnderSoftware(software, team)
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className='search-bar-container'>
        <p>Confirm your selection</p>
      </div>
      <div className='list'>{renderSelectedSoftware()}</div>
    </>
  );
}
