import React, { useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SearchSoftware(props) {
  const {
    softwareList,
    existingSoftwareList,
    selectedSoftwareTracker,
    setSelectedSoftwareTracker,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const getFilteredSoftwareList = (softwareList, searchQuery) => {
    if (softwareList.length === 0) {
      return Array(10).fill({
        name: (
          <Skeleton
            width={100}
            baseColor='#333'
            highlightColor='#444'
          />
        ),
        description: (
          <Skeleton
            count={3}
            baseColor='#333'
            highlightColor='#444'
          />
        ),
      });
    }

    return softwareList.filter(
      (software) =>
        software?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        software?.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredSoftwareList = useMemo(() => {
    return getFilteredSoftwareList(softwareList, searchQuery);
  }, [softwareList, searchQuery]);

  const selectSoftware = (clickedSoftware) => {
    const selectedSoftwareTrackerTemp = {
      ...selectedSoftwareTracker,
      [clickedSoftware._id]: !selectedSoftwareTracker[clickedSoftware._id],
    };

    setSelectedSoftwareTracker(selectedSoftwareTrackerTemp);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const checkExists = (software, list) => {
    return list.some((item) => item._id === software._id);
  };

  const checkSimilar = (software) => {
    let exists = false;

    if (software?.similar?.length) {
      for (let similar of software.similar) {
        if (checkExists(similar, existingSoftwareList)) {
          exists = true;
          break;
        }
      }
    }

    return exists;
  };

  const renderSearchBar = () => {
    return (
      <div className={'search-bar alternative' + (isFocused ? ' focus' : '')}>
        <i className='bx bx-search' />
        <input
          type='text'
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder='Search for software...'
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  };

  const renderSoftwareResults = () => {
    return filteredSoftwareList.map((software) => {
      const exists = checkExists(software, existingSoftwareList);

      return (
        <div
          key={software?._id}
          className={
            'mini-software-card' +
            (exists ? ' used' : '') +
            (selectedSoftwareTracker[software._id] ? ' selected' : '')
          }
          onClick={() => !exists && selectSoftware(software)}
        >
          <div className='titles'>
            <div
              className='company-logo-image'
              style={{ backgroundImage: `url(${software?.imageUrl})` }}
            />
            <h5>{software?.name}</h5>
            {checkSimilar(software) && <i className='alert bx bx-error' />}
          </div>
          <p>{software?.description}</p>
          {selectedSoftwareTracker[software._id] && (
            <div className='selected'>
              <i className='bx bxs-check-circle' />
              <div>Selected</div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className='search-bar-container'>
        <p>Find and select your applications</p>
        {renderSearchBar()}
      </div>
      <div className='search-content'>{renderSoftwareResults()}</div>
    </>
  );
}
