const getExcludedUsersBySoftware = (checkedUsers) => {
  const excludedUsersBySoftware = {};

  Object.keys(checkedUsers).forEach((softwareId) => {
    excludedUsersBySoftware[softwareId] = Object.keys(
      checkedUsers[softwareId]
    ).filter((userId) => checkedUsers[softwareId][userId]);
  });

  return excludedUsersBySoftware;
};

const getAddMultipleSoftwareToMultipleTeamsBody = (
  selectedSoftwareTracker,
  checkedUsers,
  userToTeams,
  paymentDetails,
  teamIds
) => {
  const excludedUsersBySoftware = getExcludedUsersBySoftware(checkedUsers);

  const selectedSoftware = Object.keys(selectedSoftwareTracker).filter(
    (softwareId) => selectedSoftwareTracker[softwareId]
  );

  const softwares = [];

  selectedSoftware.forEach((softwareId) => {
    const excludedUserIds = excludedUsersBySoftware[softwareId] || [];
    const paymentDetailsByTeam = paymentDetails[softwareId];

    softwares.push({
      softwareId,
      excludedUserIds,
      paymentDetailsByTeam,
      userToTeams: userToTeams[softwareId] || [],
    });
  });

  return { softwares, teamIds };
};

export default getAddMultipleSoftwareToMultipleTeamsBody;
