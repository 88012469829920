import React from 'react';

export default function MemberCard(props) {
  const { member, onClick, isSelected } = props;

  return (
    <div
      className={`member-card ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <div
        className='image-overlay'
        style={{
          backgroundImage: member?.imageUrl ? `url(${member?.imageUrl})` : '',
        }}
      />
      {isSelected && (
        <div className='selected'>
          <i className='bx bxs-check-circle' />
          <div>Selected</div>
        </div>
      )}

      <div className='overlay'>
        <h5>{member?.name}</h5>
      </div>
    </div>
  );
}
