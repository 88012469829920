import handleErrorResponse from '../../../util/handleErrorResponse';
import { TokenService } from '../../TokenService';
import axios from '../axiosConfig';

const prefix = '/user';

export const register = async (name, email, password) => {
  try {
    const response = await axios.post(`${prefix}/register`, {
      name,
      email,
      password,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${prefix}/login`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const googleLogin = async (credentialResponse) => {
  try {
    const response = await axios.post(`${prefix}/google/login`, {
      token: credentialResponse.access_token,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateUserWidgetArray = async (widgets) => {
  try {
    const response = await axios.put(`${prefix}/widgets`, {
      widgets,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteUserWidget = async (widgetId) => {
  try {
    const response = await axios.delete(`${prefix}/widgets/${widgetId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

//TODO: Handle image uploads
export const updateUser = async (userId, { name, email, password }) => {
  try {
    const response = await axios.put(`${prefix}/${userId}`, {
      name,
      email,
      password,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const joinTeams = async (userId, teamIds) => {
  try {
    const body = {
      teamIds,
    };

    const response = await axios.post(`${prefix}/${userId}/joinTeams`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${prefix}/${userId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateLicences = async (userIds) => {
  try {
    const body = {
      userIds,
    };

    const response = await axios.post(`${prefix}/deactivateLicences`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const createUserFromInvite = async (email, teamId, companyId) => {
  try {
    const response = await axios.post(`${prefix}/register/invite`, {
      email,
      teamId,
      companyId,
      azureToken: TokenService.getToken("azure"),
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const verifyUser = async (token) => {
  try {
    const response = await axios.get(`${prefix}/register/verify/${token}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const verifyUserFromTeamInvite = async (token) => {
  try {
    const response = await axios.get(
      `${prefix}/register/verify_team_invite/${token}`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const verifyUserFromProvider = async (token) => {
  try {
    const response = await axios.get(
      `${prefix}/register/verify_provider/${token}`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
