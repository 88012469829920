import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/company';

export const getCompanyData = async (companyId) => {
  try {
    const response = await axios.get(`${prefix}/getData/${companyId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getCompanySoftware = async (companyId) => {
  try {
    const response = await axios.get(`${prefix}/getSoftware/${companyId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getCompanyLicencesInPeriod = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { from, to };

    const response = await axios.post(
      `${prefix}/${companyId}/licencesInPeriod`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMonthlyCosts = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${companyId}/getMonthlyCosts`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMonthlyCostsByDate = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  date
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { date };

    const response = await axios.post(
      `${prefix}/${companyId}/getMonthlyCostsByDate`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMonthlyCostsDifference = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  date1,
  date2
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { date1, date2 };

    const response = await axios.post(
      `${prefix}/${companyId}/getMonthlyCostsDifference`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMonthlyCostsDifferenceByYear = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  year1,
  year2
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { year1, year2 };

    const response = await axios.post(
      `${prefix}/${companyId}/getMonthlyCostsDifferenceByYear`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getCostsForecast = async (
  companyId,
  excludedTeamIds,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      excludedTeamIds,
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { from, to };

    const response = await axios.post(
      `${prefix}/${companyId}/getCostsForecast`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
