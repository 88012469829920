import React from 'react'
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function TeamsSelectionComponent(props) {
    const { teamIds, teamData } = useSelector((state) => state.teams);
    const { userData } = useSelector((state) => state.user);

     const teams = useMemo(
        () => teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware'),
        [teamIds, teamData]
    );

    const { selectedTeams, handleTeamSelect, initialSelectedTeams } = props;

    const handleTeamClick = (teamId) => {
        if (getIfTeamInitialSelect(teamId))
            return;

        handleTeamSelect(teamId);
    }

    const getIfTeamInitialSelect = (teamId) => {
        return initialSelectedTeams.includes(teamId);
    }

    const renderTeams = (teams) => {
        if (!teams?.length)
            return null;

        return teams.map((team, index) => {
            const members = team?.users.map((user) => userData[user.userId]);
            const maxVisibleMembers = 2;

            return (
                <div
                    key={team?._id}
                    className={`team-card ${selectedTeams.includes(team?._id) ? 'selected' : ''} ${getIfTeamInitialSelect(team?._id) ? 'disabled' : ''}`}
                    onClick={() => handleTeamClick(team?._id)}
                >
                    <div
                        className='overlay'
                        style={{
                            backgroundImage: `url(/images/0${(index % 7) + 1}.jpg)`,
                        }}
                    />

                    <div className='team-card-header'>
                        <div className='title'>
                            <p>Team department</p>
                            <h3>{team?.name}</h3>
                        </div>
                    </div>

                    <div className='team-card-body'>
                        {selectedTeams.includes(team?._id) && (
                            <div className='selected'>
                                <i className='bx bxs-check-circle' />
                                <div>{getIfTeamInitialSelect(team?._id) ? 'Already Included' : 'Selected'} </div>
                            </div>
                        )}
                        <div className='users-list'>
                            {members.slice(0, maxVisibleMembers).map((member) => (
                                <div
                                    key={member?._id}
                                    className='user-image'
                                    style={{ backgroundImage: member?.imageUrl ? `url(${member?.imageUrl})` : '',
                                        transform: members.length === 1 && 'unset' }}
                                ></div>
                            ))}
                            {members.length > maxVisibleMembers && (
                                <div className='user-image nbr'>
                                    <div>+{members?.length - maxVisibleMembers}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='teams-content'>
            {renderTeams(teams)}
        </div>
    )
}
