import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPendingUsersByTeam } from '../../../services/api/team/fetch';
import {
  addUsersToTeam,
  deleteTeam,
  removeUsers,
  removeUsersFromMultipleTeams,
  updateTeam,
} from '../../../services/api/team/modify';
import { getManagedTeamsAndMonthlyCosts } from '../../../services/api/user/fetch';
import { createUserFromInvite } from '../../../services/api/user/modify';
import {
  getFormattedManagedTeamsAndMonthlyCosts,
  getFormattedPendingUsersByTeam,
} from '../../../services/teams/format';

export const getManagedTeamsAndMonthlyCostsAction = createAsyncThunk(
  'teams/getManagedTeamsAndMonthlyCosts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getManagedTeamsAndMonthlyCosts();

      const { teamIds, teamSlugToId, teamData, userIds } =
        getFormattedManagedTeamsAndMonthlyCosts(response);

      return { teamIds, teamSlugToId, teamData, userIds };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUsersToTeamAction = createAsyncThunk(
  'teams/addUsersToTeam',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamId, userIds } = arg;
      const data = await addUsersToTeam(teamId, userIds);

      const { info, warning } = data;

      return { info, warning };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTeamAction = createAsyncThunk(
  'teams/deleteTeam',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamId } = arg;

      await deleteTeam(teamId);

      return teamId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTeamAction = createAsyncThunk(
  'teams/updateTeam',
  
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamId, name, email, description } = arg;

      await updateTeam(teamId, { name, email, description });

      return teamId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeUsersAction = createAsyncThunk(
  'teams/removeUsers',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamId, userIds } = arg;

      await removeUsers(teamId, userIds);

      return userIds;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeUsersFromMultipleTeamsAction = createAsyncThunk(
  'teams/removeUsersFromMultipleTeams',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { userIds } = arg;

      await removeUsersFromMultipleTeams(userIds);

      return userIds;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUserFromInviteAction = createAsyncThunk(
  'teams/createUserFromInvite',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { email, teamId, companyId } = arg;

      await createUserFromInvite(email, teamId, companyId);

      return email;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPendingUsersByTeamAction = createAsyncThunk(
  'teams/getPendingUsersByTeam',
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await getPendingUsersByTeam(teamId);

      const { pendingUsers, userData } =
        getFormattedPendingUsersByTeam(response);

      return { pendingUsers, userData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
