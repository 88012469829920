import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  googleLoginAction,
  loginAction,
} from '../../redux/features/user/userThunks';
import LoginErrorMessage from './LoginError';
import { AuthService } from '../../services/api/AuthService';
import { API_URLS } from '../../util/Constants';

export default function Login() {
  const azureEndpoint = `${process.env.REACT_APP_BACK_API_URL}${API_URLS.AZURE.SIGN_IN}`;

  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNotFoundError, setShowNotFoundError] = useState(false);
  const [errorAnimationKey, setErrorAnimationKey] = useState(0);

  useEffect(() => {
    if (error) {
      setShowNotFoundError(true);
      setErrorAnimationKey((prevKey) => prevKey + 1);
    } else {
      setShowNotFoundError(false);
    }
  }, [error]);

  const handleSubmit = () => {
    //@ts-ignore
    dispatch(loginAction({ email, password }));
  };
  const handleGoogleSuccess = async (response) => {

    dispatch(googleLoginAction(response));
  };

  const handleGoogleError = (error) => {
    console.error(error);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
  });

  const handleAzureConnect = async () => {
    await AuthService.connect(azureEndpoint);
  };

  return (
    <>
      <div className='auth-text-container'>
        <h3 className='welcome-text'>Welcome Back!</h3>
        <h4 className='signup-text'>
          Don&apos;t have an account yet?{' '}
          <Link
            className='signup-link'
            to={'/start/signup'}
          >
            Sign up
          </Link>
        </h4>
      </div>
      <div className='social-buttons'>
        <div className='social-button'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 16 16'
            className='page_socialButtonIcon__kPbNF'
          >
            <path
              fill='currentColor'
              d='M12.665 15.358c-.905.844-1.893.711-2.843.311-1.006-.409-1.93-.427-2.991 0-1.33.551-2.03.391-2.825-.31C-.498 10.886.166 4.078 5.28 3.83c1.246.062 2.114.657 2.843.71 1.09-.213 2.133-.826 3.296-.746 1.393.107 2.446.64 3.138 1.6-2.88 1.662-2.197 5.315.443 6.337-.526 1.333-1.21 2.657-2.345 3.635zM8.03 3.778C7.892 1.794 9.563.16 11.483 0c.268 2.293-2.16 4-3.452 3.777'
            ></path>
          </svg>
        </div>
        <div className='social-button azure' onClick={handleAzureConnect} />
        <div
          className='social-button google'

          onClick={handleGoogleLogin}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 16 16'
            className='page_socialButtonIcon__kPbNF'
          >
            <path
              fill='#4285F4'
              d='M15.483 8.176c0-.519-.042-1.04-.132-1.551h-7.2v2.94h4.123a3.53 3.53 0 0 1-1.526 2.32v1.907h2.46c1.445-1.33 2.275-3.293 2.275-5.616'
            ></path>
            <path
              fill='#34A853'
              d='M8.15 15.632c2.058 0 3.794-.676 5.06-1.843l-2.46-1.907c-.685.465-1.569.729-2.598.729-1.992 0-3.68-1.344-4.286-3.15H1.327v1.966a7.64 7.64 0 0 0 6.822 4.205'
            ></path>
            <path
              fill='#FBBC04'
              d='M3.863 9.462a4.57 4.57 0 0 1 0-2.923V4.572H1.328a7.64 7.64 0 0 0 0 6.856z'
            ></path>
            <path
              fill='#EA4335'
              d='M8.15 3.387a4.15 4.15 0 0 1 2.928 1.144l2.18-2.18A7.34 7.34 0 0 0 8.148.367a7.63 7.63 0 0 0-6.822 4.207L3.863 6.54c.603-1.81 2.294-3.153 4.286-3.153'
            ></path>
          </svg>
          {/* <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleError}
          /> */}
        </div>
      </div>
      <div className='separator'></div>
      <div className='input-container'>
        <input
          type='email'
          name='email'
          placeholder='Email address'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />
        <input
          type='password'
          name='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button
          className='button'
          onClick={handleSubmit}
        >
          Sign In
          <div className='chevron'></div>
        </button>
      </div>
      {/* Temporary error message */}
      <LoginErrorMessage
        showNotFoundError={showNotFoundError}
        errorAnimationKey={errorAnimationKey}
      />
      <div className='agreement-text'>
        By signing up, you agree to the{' '}
        <span className='highlight'>Terms of Service</span> and{' '}
        <span className='highlight'>Privacy Policy</span>.
      </div>
    </>
  );
}
