import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamCostsAndLicenceCountByYearAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
} from '../../redux/features/analytics/analyticsThunks';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getTotalInvoicesCostBySoftwateAction,
  getTrackedSoftwareDataAction,
} from '../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';
import CostHistoryChart from '../Analytics/Charts/CostHistoryChart';
import NBLicencesChart from '../Analytics/Charts/NBLicencesChart';
import InvoiceTable from '../Invoices/InvoiceTable';
import BudgetAnalysis from '../Sections/BudgetAnalysis';
import TeamCard from '../Teams/TeamCard';
import ApplicationUsersTable from './Users/ApplicationUsersTable';
import SoftwareCard from '../Tracked Software/SoftwareCard';

export default function ApplicationComponent(props) {
  const dispatch = useDispatch();
  const { softwareName } = props;

  const {
    costHistorySeries,
    nbLicencesSeries,
    
  } = useSelector((state) => state.analytics);

  const {
    trackedSoftwareIds,
    staticSoftwareData,
    softwareSlugToId,
    allDynamicSoftwareData,
    trackedSoftwareDataByTeam,
    totalInvoiceSoftwareCost
  } = useSelector(
    (state) => state.software
  );
  
  const { userData } = useSelector((state) => state.user);
  const { teamIds, teamData } = useSelector((state) => state.teams);

  const softwareId = useMemo(
    () => softwareSlugToId[softwareName],
    [softwareSlugToId, softwareName]
  );

  const softwareList = useMemo(
    () =>
      trackedSoftwareIds.map((softwareId) => staticSoftwareData[softwareId]),
    [trackedSoftwareIds, staticSoftwareData]
  );

  const users = useMemo(
    () =>
      allDynamicSoftwareData[softwareId]?.userMonthlyCosts.map((user) => ({
        ...userData[user.userId],
        ...user,
      })) || [],
    [allDynamicSoftwareData, softwareId, userData]
  );

  const allTeams = useMemo(
    () => teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware'),
    [teamIds, teamData]
  );

  const usingTeamsSet = useMemo(
    () =>
      new Set(
        allDynamicSoftwareData[softwareId]?.teams.map((team) => team.teamId) ||
          []
      ),
    [allDynamicSoftwareData, softwareId]
  );

  const { teamsWithSoftware, teamsWithoutSoftware } = useMemo(() => {
    const teamsWithSoftware = [];
    const teamsWithoutSoftware = [];

    allTeams.forEach((team) => {
      if (usingTeamsSet.has(team._id)) {
        teamsWithSoftware.push(team);
      } else {
        teamsWithoutSoftware.push(team);
      }
    });

    return { teamsWithSoftware, teamsWithoutSoftware };
  }, [allTeams, usingTeamsSet]);

  const height = 200;

  useEffect(() => {
    
    dispatch(getTrackedSoftwareDataAction());
    
    dispatch(getDynamicTrackedSoftwareDataByTeamAction());
    
    dispatch(getManagedTeamsAndMonthlyCostsAction());
    
    dispatch(getTeamCostsAndLicenceCountByYearAction());
    
    dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction());

  }, [dispatch]);

  useEffect(() => {
    dispatch(getTotalInvoicesCostBySoftwateAction(softwareId));
  }, [softwareId])

  const renderTeams = useCallback(() => {
    const getTeamCards = (teams, isOwned) =>
      teams.map((team, index) => {
        const softwareData = {
          teamSoftwareId:
            trackedSoftwareDataByTeam?.[team?._id]?.[softwareId]
              ?.teamSoftwareId,
          ...staticSoftwareData[softwareId],
          ...allDynamicSoftwareData[softwareId],
        };

        return (
          <TeamCard
            key={team?._id}
            team={team}
            index={index}
            isOwned={isOwned}
            softwareData={softwareData}
            isFromApplicationComponent={true}
          />
        );
      });

    return (
      <section className='application-page'>
        <div className='titles-container'>
          <h3>Teams using this Application</h3>
        </div>
        <div className='teams-cards-container'>
          {getTeamCards(teamsWithSoftware, true)}
          {getTeamCards(teamsWithoutSoftware, false)}
        </div>
      </section>
    );
  }, [
    teamsWithSoftware,
    teamsWithoutSoftware,
    trackedSoftwareDataByTeam,
    softwareId,
    staticSoftwareData,
    allDynamicSoftwareData,
  ]);

  const renderStats = () => {
    return (
      <section className='application-page'>
        <div className='titles-container'>
          <h3>Stats about this application</h3>
        </div>

        <div className='stats-cards-container'>
          <div className='software-card stats-card'>
            <div className='title'>Cost History</div>
            <CostHistoryChart
              series={costHistorySeries}
              selectedView='months'
              softwareList={softwareList}
              selectedSoftware={staticSoftwareData[softwareId]}
              height={height}
            />
          </div>

          <div className='software-card stats-card'>
            <div className='title'>NB Licences Evolution</div>
            <NBLicencesChart
              series={nbLicencesSeries}
              selectedView='months'
              softwareList={softwareList}
              selectedSoftware={staticSoftwareData[softwareId]}
              height={height}
            />
          </div>
        </div>
      </section>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <ApplicationUsersTable users={users} />
        {renderTeams()}
        <BudgetAnalysis
          title='Application Budget Analysis'
          subtitle='Overview of the application spendings.'
        />
        <InvoiceTable
          fromApplication={true}
          softwareId={softwareId}
        />

        {renderStats()}
      </>
    );
  };

  return (
    <section className='invoices-container applications-container'>
      <div className='titles-container'>
        <h2>
          <span>Application Details</span>
        </h2>
        <h4>
          Details about this application, the teams that are using it and its
          costs.
        </h4>
      </div>
      <div className='most-used-software application-page'>
        <SoftwareCard
          selectedSoftwareId={softwareId}
          isSelected={true}
          setSelectedCard={() => {}}
          dynamicSoftwareData={allDynamicSoftwareData}
          isFromInterface={true}
          totalInvoiceSoftwareCost={totalInvoiceSoftwareCost}
        />
      </div>
      {renderDetails()}
    </section>
  );
}
