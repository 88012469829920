export const getLocalStorageState = (key) => {
  const state = localStorage.getItem(key);

  return state ? JSON.parse(state) : {};
};

export const updateLocalStorageState = (key, newData) => {
  const existingData = JSON.parse(localStorage.getItem(key)) || {};

  const updatedData = { ...existingData, ...newData };

  localStorage.setItem(key, JSON.stringify(updatedData));
};
