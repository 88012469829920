import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamOverview from '../components/Sections/TeamOverview';
import { getManagedTeamsAndMonthlyCostsAction } from '../redux/features/teams/teamsThunks';
import { getMultipleUserDataAction } from '../redux/features/user/userThunks';

export default function TeamsOverviewContainer({ displayTitle }) {
  const dispatch = useDispatch();

  
  const { teamIds, teamData, userIds } = useSelector((state) => state.teams);
  const teams = useMemo(
    () => teamIds.map((teamId) => teamData[teamId]).filter((team) => team.name !== '_providerSoftware'),
    [teamIds, teamData]
  );

  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);

  useEffect(() => {
    
    dispatch(getManagedTeamsAndMonthlyCostsAction());
  }, [dispatch]);

  useEffect(() => {
    if (userIds?.length) {
      
      dispatch(getMultipleUserDataAction(userIds));
    }
  }, [dispatch, userIds]);

  const handleNextTeam = () => {
    setCurrentTeamIndex((prevIndex) => (prevIndex + 1) % teams.length);
  };

  const handlePrevTeam = () => {
    setCurrentTeamIndex(
      (prevIndex) => (prevIndex - 1 + teams.length) % teams.length
    );
  };

  return (
    <>
      <div className='titles-container'>
        {displayTitle && (
          <>
            <h3>Your Team</h3>
            <h4>
              As a team manager, you can track your team software usage and
              costs.
            </h4>
          </>
        )}
      </div>
      <TeamOverview
        team={teams[currentTeamIndex]}
        onNextTeam={handleNextTeam}
        onPrevTeam={handlePrevTeam}
      />
    </>
  );
}
