import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUserFromInviteAction,
  getManagedTeamsAndMonthlyCostsAction,
  getPendingUsersByTeamAction,
} from '../../../redux/features/teams/teamsThunks';
import MemberCard from '../../UI/MemberCard';

export default function InviteUsersEmail(props) {
  const dispatch = useDispatch();

  const { teamId } = props;

  
  const { user, userData } = useSelector((state) => state.user);

  
  const prevState = useSelector((state) => state.teams);

  const { pendingUsersByTeam } = prevState;

  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pendingUsers = useMemo(
    () => pendingUsersByTeam[teamId]?.map((userId) => userData[userId]),
    [pendingUsersByTeam, teamId, userData]
  );

  const submitEmail = () => {
    if (isSubmitting) {
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      return setIsError(true);
    }

    let emailValue = email.toLowerCase();

    setIsSubmitting(true);

    
    dispatch(
      
      createUserFromInviteAction({
        userId: crypto.randomUUID(),
        email: emailValue,
        teamId,
        companyId: user?.company,
        prevState,
      })
      
    ).finally(() => {
      
      dispatch(getManagedTeamsAndMonthlyCostsAction());
      setIsSubmitting(false);
      setEmail('')
    });
  };

  useEffect(() => {
    
    dispatch(getPendingUsersByTeamAction(teamId));
  }, [dispatch, teamId]);

  const handleInput = (e) => {
    setEmail(e.target.value);

    if (isError) {
      setIsError(false);
    }
  };

  const renderInviteUser = () => {
    return (
      <div className='invite-user-container'>
        <input
          type='email'
          className='email-input'
          placeholder='Invite user by email...'
          onChange={handleInput}
          value={email}
        />
        <div
          className='form-button'
          onClick={submitEmail}
        >
          Send Invitation <i className='bx bx-send' />
        </div>
      </div>
    );
  };

  const renderInvitationCards = () => {
    if (!pendingUsers?.length)
      return (
        <div className='empty-data-message'>
          <i className='bx bx-mail-send' />
          <p>No invitations sent yet...</p>
        </div>
      );

    return pendingUsers.map((user) => (
      <MemberCard
        key={user?.email}
        member={user}
      />
    ));
  };

  return (
    <>
      <div className='titles'>
        <p className='aligned'>
          Invite external users to your team by their emails
        </p>
      </div>
      {renderInviteUser()}

      <div className='titles'>
        <p className='aligned'>
          Pending user invitations <i className='bx bxs-envelope'></i>
        </p>
      </div>
      <div
        className='members-cards-container'
        style={{ paddingBottom: '3rem' }}
      >
        {renderInvitationCards()}
      </div>
    </>
  );
}
