import React from "react";
import { useRef, useEffect } from "react";

// eslint-disable-next-line react/prop-types
export default function Alert({ content, type, onClose }) {
    const color =
        type === "error" ? "red" : type === "success" ? "green" : "blue";
    const icon = type === "error" ? "bx bx-block" : type === 'success' ? "bx bx-check-circle" : 'bx bx-cloud-upload';
    const alertRef = useRef(null);
    const handleClickOutside = (e) => {
        if (alertRef.current && !alertRef.current.contains(e.target)) {
            onClose();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div className={"alert-container line-" + color} ref={alertRef}>
            <div className="text">
                <i className={icon} />
                <p className={"alert-content text-" + color}>{content}</p>
            </div>
            <div className={"alert-close text-" + color} onClick={onClose}>
                <i class='bx bx-x'></i>
            </div>
        </div>
    );
}
