import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['x-access-token'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(
      error instanceof Error ? error : new Error('Request failed')
    );
  }
);

export default axiosInstance;
