import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useApplicationUserDropdown } from '../../../hooks/Dropdown/useApplicationUserDropdown';
import {
  getTeamCostsAndLicenceCountByYearAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
} from '../../../redux/features/analytics/analyticsThunks';
import {
  deactivateLicenceAction,
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
  renewLicenceAction,
} from '../../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../../redux/features/teams/teamsThunks';
import { formatDate } from '../../../util/formatDate';
import UpdateApplicationUserModal from '../../Modals/Update/UpdateApplicationUserModal';

export default function ApplicationUserRow(props) {
  const dispatch = useDispatch();

  const { user } = props;

  
  const { teamData } = useSelector((state) => state.teams);

  
  const prevState = useSelector((state) => state.software);

  const {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
  } = useApplicationUserDropdown(user);

  const toggleActivity = () => {
    if (user?.isActive) {
      dispatch(
        
        deactivateLicenceAction({
          softwareId: user?.software,
          teamId: user?.team,
          licenceId: user?.licenceId,
          prevState,
        })
        
      ).finally(() => {
        
        dispatch(getTrackedSoftwareDataAction());
        
        dispatch(getDynamicTrackedSoftwareDataByTeamAction());
        
        dispatch(getManagedTeamsAndMonthlyCostsAction());
        
        dispatch(getTeamCostsAndLicenceCountByYearAction());
        
        dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction());
      });
    } else {
      dispatch(
        
        renewLicenceAction({
          softwareId: user?.software,
          teamId: user?.team,
          licenceId: user?.licenceId,
          prevState,
        })
        
      ).finally(() => {
        
        dispatch(getTrackedSoftwareDataAction());
        
        dispatch(getDynamicTrackedSoftwareDataByTeamAction());
        
        dispatch(getManagedTeamsAndMonthlyCostsAction());
        
        dispatch(getTeamCostsAndLicenceCountByYearAction());
        
        dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction());
      });
    }
  };

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownOpen]);

  return (
    <>
      <Link to={`/dashboard/profile/${user?._id}`}>
        <div className='row'>
          <div className='element'>
            <div
              className='icon avatar'
              style={{
                backgroundImage: user?.imageUrl ? `url(${user?.imageUrl})` : '',
              }}
            />
            <div className='title'>
              {user?.name || (
                <Skeleton
                  width={100}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )}
            </div>
          </div>
          <div className='element'>
            <div>
              {teamData[user?.team]?.name ? (
                `${teamData[user?.team]?.name} team`
              ) : (
                <Skeleton
                  width={100}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )}
            </div>
          </div>
          <div className='element'>
            <div
              className={
                'user-activity' + (user?.isActive ? '' : ' not-active')
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleActivity();
              }}
            >
              • {user?.isActive ? 'Active' : 'Inactive'}
            </div>
          </div>
          <div className='element'>
            <div>
              {formatDate(user?.lastActivity) || (
                <Skeleton
                  width={100}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )}
            </div>
          </div>
          <div className='element'>
            <div>
              {user?.monthlyCosts != null ? (
                `€${user.monthlyCosts.toFixed(3)}`
              ) : (
                <Skeleton
                  width={50}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )}
            </div>
          </div>

          <div className='element'>
            <div>
              {user?.monthlyCosts != null ? (
                `€${user.totalCosts.toFixed(3)}`
              ) : (
                <Skeleton
                  width={50}
                  baseColor='#333'
                  highlightColor='#444'
                />
              )}
            </div>
          </div>

          <div className='element options no-width' style={{width: '1% !important'}}> 
          <i
              ref={iconRef}
              className='icon options pointer'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDropdownOpen((prev) => !prev);
              }}
            />
            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className='dropdown-menu'
              >
                {dropdownOptions.map((option) => (
                  <div
                    key={crypto.randomUUID()}
                    className='dropdown-item'
                    onClick={option?.onClick}
                  >
                    <i className={option?.icon} />
                    {option?.value}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Link>
      {isModalOpen && (
        <UpdateApplicationUserModal
          toggleModal={toggleModal}
          message={message}
          value={value}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}
