import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isValidToken from '../util/isValidToken';

const useAuthRedirect = (
  redirectPath,
  fallbackPaths = [
    '/start/login',
    '/start/signup',
    '/start/confirm/*',
    '/start/confirm_team_invite/*',
    '/start/confirm_provider/*',
    '/company',
  ]
) => {
  
  const { token, user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isFallbackPath = fallbackPaths.some((path) => {
      const regex = new RegExp(`^${path.replace('*', '.*')}$`);
      return regex.test(location.pathname);
    });

    if (!isValidToken(token) && !isFallbackPath) {
      navigate(fallbackPaths[0], { replace: true });
      return;
    }

    if (isValidToken(token) && !user?.company) {
      if (location.pathname !== '/company') {
        navigate('/company', { replace: true });
      }
      return;
    }

    if (
      isValidToken(token) &&
      user?.company &&
      redirectPath &&
      location.pathname !== redirectPath
    ) {
      navigate(redirectPath, { replace: true });
    }
  }, [token, navigate, redirectPath, fallbackPaths, location, user]);
};

export default useAuthRedirect;
