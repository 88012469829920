import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { seeNotificationsAction } from '../../redux/features/events/eventsThunks';
import NotificationRenderer from './NotificationRenderer';

const mockNotifications = Array.from({ length: 10 }, (_, index) => ({
  _id: index,
  isSeen: true,
  team: false,
  company: false,
  thumbnail: '',
  timestamp: '',
  isMock: true,
}));

export default function NotificationsComponent(props) {
  const dispatch = useDispatch();
  const notificationRef = useRef(null);

  const { isNotificationMenuOpen, setIsNotificationMenuOpen } = props;

  const { eventIds, eventData, seenEvents, isLoading } = useSelector(
    
    (state) => state.events
  );

  const notifications = useMemo(
    () =>
      eventIds.map((eventId) => ({
        ...eventData[eventId],
        isSeen: seenEvents[eventId],
      })),
    [eventIds, eventData, seenEvents]
  );

  const displayNotifications = useMemo(
    () => (isLoading ? mockNotifications : notifications),
    [isLoading, notifications]
  );

  const allNotifications = displayNotifications;
  const teamNotifications = useMemo(
    () => displayNotifications.filter((notification) => notification?.team),
    [displayNotifications]
  );
  const companyNotifications = useMemo(
    () => displayNotifications.filter((notification) => notification?.company),
    [displayNotifications]
  );

  const unseenAllCount = useMemo(
    () =>
      displayNotifications.filter((notification) => !notification.isSeen)
        .length,
    [displayNotifications]
  );

  const unseenTeamCount = useMemo(
    () =>
      teamNotifications.filter((notification) => !notification.isSeen).length,
    [teamNotifications]
  );
  const unseenCompanyCount = useMemo(
    () =>
      companyNotifications.filter((notification) => !notification.isSeen)
        .length,
    [companyNotifications]
  );

  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    if (isNotificationMenuOpen) {
      
      dispatch(seeNotificationsAction(eventIds));
    }
  }, [dispatch, isNotificationMenuOpen, eventIds]);

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (!notificationRef?.current?.contains(event.target)) {
          setIsNotificationMenuOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  

  const renderNotifications = () => {
    let filteredNotifications;
    switch (activeTab) {
      case 'team':
        filteredNotifications = teamNotifications;
        break;
      case 'company':
        filteredNotifications = companyNotifications;
        break;
      case 'all':
      default:
        filteredNotifications = allNotifications;
        break;
    }

    return filteredNotifications.map((notification) => (
      <NotificationRenderer
        key={notification?._id}
        notification={notification}
        isMock={notification.isMock}
        setIsNotificationMenuOpen={setIsNotificationMenuOpen}
      />
    ));
  };

  return (
    <div
      className={`notification-panel ${isNotificationMenuOpen ? '' : 'closed'}`}
      ref={notificationRef}
    >
      <div
        className='close-button'
        onClick={() => setIsNotificationMenuOpen(false)}
      >
        <i className='bx bx-x'></i>
      </div>
      <div className='titles-container'>
        <h3>Notifications</h3>
        <div className='tabs'>
          <div
            className={`tab ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => setActiveTab('all')}
          >
            <div>All</div>
            {unseenAllCount > 0 && (
              <div className='tab-count'>{unseenAllCount}</div>
            )}
            <div className='tab-indicator' />
          </div>
          <span className='separator' />
          <div
            className={`tab ${activeTab === 'team' ? 'active' : ''}`}
            onClick={() => setActiveTab('team')}
          >
            <div>Team</div>
            {unseenTeamCount > 0 && (
              <div className='tab-count'>{unseenTeamCount}</div>
            )}
            <div className='tab-indicator' />
          </div>
          <span className='separator' />
          <div
            className={`tab ${activeTab === 'company' ? 'active' : ''}`}
            onClick={() => setActiveTab('company')}
          >
            <div>Company</div>
            {unseenCompanyCount > 0 && (
              <div className='tab-count'>{unseenCompanyCount}</div>
            )}
            <div className='tab-indicator' />
          </div>
        </div>
      </div>
      <div className='notifications-scroller'>{renderNotifications()}</div>
    </div>
  );
}
