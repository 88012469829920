import React from 'react';
import NotificationReactions from './NotificationReactions';

export default function NotificationActions({
  notification,
  handleViewTeamClick,
  toggleAddSoftwareModal,
}) {
  const renderActions = (notification) => {

    switch (notification?.type) {
      case 'add-user-to-team':
        return (
          <>
            <NotificationReactions notification={notification} />
            <div className={'reaction-add'} />
          </>
        );
      case 'add-team-software':
        return (
          <>
            <div
              className='action-button'
              onClick={handleViewTeamClick}
            >
              View Team
            </div>
            <div
              className='action-button highlighted'
              onClick={(e) => toggleAddSoftwareModal(e, true)}
            >
              Add to other Team
            </div>
          </>
        );
      case 'set-team-manager':
        return (
          <div
            className='action-button'
            onClick={handleViewTeamClick}
          >
            View Team
          </div>
        );
      case 'renewal-date-reminder':
      case 'subscription-paid':
        return <div className='action-button'>View Application</div>;
      default:
        return null;
    }
  };

  return renderActions(notification);
}
