export const getAddTeamBody = (
  teamDetails,
  selectedUsers,
  selectedManager,
  companyId,
  azureToken
) => {
  const team = {
    name: teamDetails.name,
    email: teamDetails.email,
    description: teamDetails.description,
    manager: selectedManager,
    companyId,
    users: Object.keys(selectedUsers).filter((userId) => selectedUsers[userId]),
    azureToken
  };

  return team;
};

export const haveSelectedUsers = (selectedUsers) => {
  return Object.keys(selectedUsers).some((userId) => selectedUsers[userId]);
};
