import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/invoice';

export const addInvoice = async (formData) => {
  try {
    const response = await axios.post(`${prefix}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateInvoiceName = async ({ invoiceId, name }) => {
  try {
    const response = await axios.put(`${prefix}/name/${invoiceId}`, {
      name,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateInvoiceValue = async ({ invoiceId, value }) => {
  try {
    const response = await axios.put(`${prefix}/value/${invoiceId}`, {
      value,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateInvoiceDueDate = async ({ invoiceId, dueDate }) => {
  try {
    const response = await axios.put(`${prefix}/dueDate/${invoiceId}`, {
      dueDate,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addSoftwareInvoice = async (invoiceId, softwareId) => {
  try {
    const response = await axios.put(`${prefix}/software/${invoiceId}`, {
      softwareId,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteInvoice = async (invoiceId) => {
  try {
    const response = await axios.delete(`${prefix}/${invoiceId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const confirmInvoiceAndUpdate = async (invoiceId, invoiceData) => {
  try {
    const response = await axios.post(
      `${prefix}/confirm/${invoiceId}`,
      invoiceData,
    );
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getExistingTeams = async (softwareId) => {
  try {
    const response = await axios.get(
      `${prefix}/softwareteams/${softwareId}`
    );
    return response?.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};