import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanySoftware } from '../../../services/api/company/fetch';
import {
  deactivateLicence,
  deleteLicence,
  renewLicence,
} from '../../../services/api/licence/modify';
import {
  getMostPopularSoftwareByLicenceCount,
  getSoftwareByIdList,
} from '../../../services/api/software/fetch';
import { getMultipleTeamsAvailableSoftware } from '../../../services/api/team/fetch';
import {
  deleteTeamSoftware,
  deleteTeamSoftwareForMultipleTeams,
  updateLicences,
  updateLicencesForMultipleTeams,
} from '../../../services/api/teamsoftware/modify';
import {
  getFormattedMultipleTeamsAvailableSoftware,
  getFormattedSoftwareData,
  getFormattedTrackedSoftwareData,
  getFormattedTrackedSoftwareDataByTeam,
} from '../../../services/software/format';
import {
  getAllUserCostsData,
  getUserCostsDataByTeam,
  getUserMonthlyCostsBySoftware,
} from '../../../services/software/get';
import { getTotalInvoiceSoftwareCost } from '../../../services/api/invoice/fetch';

const {
  getTrackedSoftwareData,
  getDynamicTrackedSoftwareDataByTeam,
} = require('../../../services/api/teamsoftware/fetch');

export const getTrackedSoftwareDataAction = createAsyncThunk(
  'software/getTrackedSoftwareData',
  async (_, { rejectWithValue }) => {
    try {
      const trackedSoftwareData = await getTrackedSoftwareData();
      const {
        trackedSoftwareIds,
        softwareSlugToId,
        staticSoftwareData,
        allDynamicSoftwareData,
        userIds,
      } = getFormattedTrackedSoftwareData(trackedSoftwareData);

      const allUserCostsData = getAllUserCostsData(allDynamicSoftwareData);

      const userMonthlyCostsBySoftware = getUserMonthlyCostsBySoftware(
        allDynamicSoftwareData
      );

      localStorage.setItem(
        'trackedSoftwareIds',
        JSON.stringify(trackedSoftwareIds)
      );

      localStorage.setItem(
        'softwareSlugToId',
        JSON.stringify(softwareSlugToId)
      );

      return {
        trackedSoftwareIds,
        staticSoftwareData,
        allDynamicSoftwareData,
        userIds,
        allUserCostsData,
        userMonthlyCostsBySoftware,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMultipleTeamsAvailableSoftwareAction = createAsyncThunk(
  'software/getMultipleTeamsAvailableSoftware',
  
  async (teamIds, { rejectWithValue }) => {
    try {
      const availableSoftware = await getMultipleTeamsAvailableSoftware(
        teamIds
      );

      const {
        availableSoftwareIds,
        excludedSoftwareIds,
        staticSoftwareData,
        allDynamicSoftwareData,
      } = getFormattedMultipleTeamsAvailableSoftware(availableSoftware);

      localStorage.setItem(
        'availableSoftwareIds',
        JSON.stringify(availableSoftwareIds)
      );
      localStorage.setItem(
        'excludedSoftwareIds',
        JSON.stringify(excludedSoftwareIds)
      );

      return {
        availableSoftwareIds,
        excludedSoftwareIds,
        staticSoftwareData,
        allDynamicSoftwareData,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanySoftwareAction = createAsyncThunk(
  'company/getSoftware',
  async (companyId, { rejectWithValue }) => {
    try {
      const softwareData = await getCompanySoftware(companyId);

      const { softwareIds: companySoftwareIds, staticSoftwareData } =
        getFormattedSoftwareData(softwareData);

      localStorage.setItem(
        'companySoftwareIds',
        JSON.stringify(companySoftwareIds)
      );

      return { companySoftwareIds, staticSoftwareData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMostPopularSoftwareByLicenceCountAction = createAsyncThunk(
  'software/getMostPopularSoftwareByLicenceCount',
  async (_, { rejectWithValue }) => {
    try {
      const softwareData = await getMostPopularSoftwareByLicenceCount();

      const { softwareIds: mostPopularSoftwareIds, staticSoftwareData } =
        getFormattedSoftwareData(softwareData);

      localStorage.setItem(
        'mostPopularSoftwareIds',
        JSON.stringify(mostPopularSoftwareIds)
      );

      return { mostPopularSoftwareIds, staticSoftwareData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDynamicTrackedSoftwareDataByTeamAction = createAsyncThunk(
  'software/getDynamicTrackedSoftwareDataByTeam',
  async (_, { rejectWithValue }) => {
    try {
      const allDynamicSoftwareData =
        await getDynamicTrackedSoftwareDataByTeam();

      const { trackedSoftwareDataByTeam, trackedSoftwareIdsByTeam } =
        getFormattedTrackedSoftwareDataByTeam(allDynamicSoftwareData);

      const userCostsDataByTeam = getUserCostsDataByTeam(
        trackedSoftwareDataByTeam
      );

      return {
        trackedSoftwareDataByTeam,
        trackedSoftwareIdsByTeam,
        userCostsDataByTeam,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTeamSoftwareAction = createAsyncThunk(
  'teams/deleteTeamSoftware',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamSoftwareId } = arg;

      await deleteTeamSoftware(teamSoftwareId);

      return teamSoftwareId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTeamSoftwareForMultipleTeamsAction = createAsyncThunk(
  'teams/deleteTeamSoftwareForMultipleTeams',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { softwareId } = arg;

      await deleteTeamSoftwareForMultipleTeams(softwareId);

      return softwareId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateLicencesAction = createAsyncThunk(
  'teams/updateLicences',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { teamSoftwareId, updatedDetails } = arg;

      await updateLicences(teamSoftwareId, updatedDetails);

      return { teamSoftwareId, updatedDetails };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateLicencesForMultipleTeamsAction = createAsyncThunk(
  'teams/updateLicencesForMultipleTeams',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { softwareId, updatedDetails } = arg;

      await updateLicencesForMultipleTeams(softwareId, updatedDetails);

      return { softwareId, updatedDetails };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deactivateLicenceAction = createAsyncThunk(
  'teams/deactivateLicence',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { licenceId } = arg;

      await deactivateLicence(licenceId);

      return licenceId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLicenceAction = createAsyncThunk(
  'teams/deleteLicence',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { licenceId } = arg;

      await deleteLicence(licenceId);

      return licenceId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const renewLicenceAction = createAsyncThunk(
  'teams/renewLicence',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { licenceId } = arg;

      await renewLicence(licenceId);

      return licenceId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSoftwareByIdListAction = createAsyncThunk(
  'software/getSoftwareByIdList',
  async (softwareIds, { rejectWithValue }) => {
    try {
      const softwareData = await getSoftwareByIdList(softwareIds);
      const { staticSoftwareData } = getFormattedSoftwareData(softwareData);

      return { staticSoftwareData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTotalInvoicesCostBySoftwateAction = createAsyncThunk(
  'software/getTotalInvoicesCostBySoftwateAction',
  async (softwarId, { rejectWithValue }) => {
    try {
      const totalInvoiceSoftwareCost = await getTotalInvoiceSoftwareCost(softwarId);

      return { totalInvoiceSoftwareCost };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
