import React, { useEffect, useMemo, useState } from 'react';
import { AuthService } from '../services/api/AuthService';
import { TokenService } from '../services/TokenService';
import { API_URLS } from '../util/Constants';
import { useAlert } from '../providers/AlertProvider';

export default function ProvidersComponent() {
  const { showAlert } = useAlert();

  const installExtension = () => {
    const chromeWebStoreUrl =
      'https://chromewebstore.google.com/detail/smallpdf-%E2%80%93-modifie-conver/ohfgljdgelakfkefopgklcohadegdpjf';
    window.open(chromeWebStoreUrl, '_blank');
  };

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  const extensionDetails = {
    id: 'extension',
    imageURL: 'https://reknah.com/reknah.png',
    service: 'chrome extension',
    name: 'Reknah Chrome Extensions',
    tenantId: '67220d1478c6cc',
    username: '67220d1478c220d',
    clientId: '123456789',
    business: 'lorem ipsum',
  };

  // const checkExtensionInstallation = async () => {
  //   const userId = localStorage.getItem('userId');
  //   const isInstalled = await ExtensionService.checkExtensionInstallation(
  //     userId
  //   );
  //   setIsExtensionInstalled(isInstalled);
  // };

  const initialCards = useMemo(
    () => [
      {
        id: 'azure',
        imageURL:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1698182299/t2e9fr5txvyx4fsy5olq.png',
        service: 'service',
        name: 'azure cloud',
        tenantId: '67220d1478c6cc',
        username: '67220d1478c220d',
        clientId: '123456789',
        business: 'lorem ipsum',
        endpoint: `${process.env.REACT_APP_BACK_API_URL}${API_URLS.AZURE.SIGN_IN}`,
      },
      {
        id: 'slack',
        imageURL:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png',
        service: 'service',
        name: 'Slack',
        tenantId: '67220fe5638c6cc',
        username: '4822441478c220d',
        clientId: '1234567892525',
        business: 'lorem ipsum',
        endpoint: `${process.env.REACT_APP_BACK_API_URL}${API_URLS.SLACK.SIGN_IN}`,
      },
      {
        id: 'google',
        imageURL:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1698184031/xkgwo4wrmneektvsmqi1.png',
        service: 'service',
        name: 'google workspace',
        tenantId: '672445223d26cc',
        username: '672445223d2220d',
        clientId: '1202424524523',
        business: 'lorem ipsum',
        endpoint: `${process.env.REACT_APP_BACK_API_URL}${API_URLS.GOOGLE_WORKSPACE_SIGN_IN}`,
      },
      /*{
        id: 'Microsoft Teams',
        imageURL:
          'https://res.cloudinary.com/dg91u71x5/image/upload/v1698252491/vkgmsivsvqlft4ksta0y.png',
        service: 'service',
        name: 'Microsoft Teams',
        tenantId: '672254g78c6ed',
        username: '672254g78c22ff',
        clientId: '12377815213789',
        business: 'lorem ipsum',
        endpoint: `${process.env.REACT_APP_BACK_API_URL}${API_URLS.TEAMS?.SIGN_IN}`,
      },*/
      {
        id: 'extension',
        imageURL: 'https://reknah.com/reknah.png',
        service: 'chrome extension',
        name: 'Reknah Chrome Extension',
        tenantId: '67220d1478c6cc',
        username: '67220d1478c220d',
        clientId: '123456789',
        business: 'lorem ipsum',
      }
    ],
    []
  );

  const [cards, setCards] = useState(
    initialCards.map((card) => ({
      ...card,
      isConnected: !!TokenService.getToken(card.id),
    }))
  );

  const [selectedProvider, setSelectedProvider] = useState(cards[0]);

  const handleProviderClick = (id) => {
    const selected = cards.find((card) => card.id === id);
    setSelectedProvider(selected);
  };

  useEffect(() => {
    const updatedCards = initialCards.map((card) => {
      const authData = TokenService.getAuthData(card.id);
      if (authData) {
        switch (card.id) {
          case 'azure':
            return {
              ...card,
              isConnected: true,
              username: authData.username,
              tenantId: authData.tenantId,
              objectId: authData.homeAccountId.split('.')[0],
            };
          case 'slack':
            return {
              ...card,
              isConnected: true,
              team: authData.team,
              enterprise: authData.enterprise,
              userId: authData.userId,
            };
          case 'google':
            return {
              ...card,
              isConnected: true,
              username: authData.username,
              clientId: authData.clientId,
              tenantId: authData.tenantId,
            };
          case 'teams':
            return {
              ...card,
              isConnected: true,
              username: authData.username,
              clientId: authData.clientId,
              tenantId: authData.tenantId,
            };
          default:
            return card;
        }
      }

      return card;
    });

    
    setSelectedProvider(updatedCards[0]);
    
    setCards(updatedCards);

    const isExtensionInstalledInLocalStorage = localStorage.getItem(
      'isExtensionInstalled'
    );
    setIsExtensionInstalled(isExtensionInstalledInLocalStorage === 'true');
  }, [initialCards]);

  const handleConnect = async () => {
    await AuthService.connect(selectedProvider.endpoint);
    const authData = TokenService.getAuthData(selectedProvider.id);

    if (authData) {
      console.log('authenticated', authData);
      showAlert('Synching data with your provider. If elements are missing, please synch again.');

      setCards(
        cards.map((card) =>
          card.id === selectedProvider.id
            ? {
                ...card,
                isConnected: true,
                ...(() => {
                  switch (card.id) {
                    case 'azure':
                      return {
                        username: authData.username,
                        tenantId: authData.tenantId,
                        objectId: authData.homeAccountId.split('.')[0],
                      };
                    case 'slack':
                      return {
                        team: authData.team,
                        enterprise: authData.enterprise,
                        userId: authData.userId,
                      };
                    case 'google':
                      return {
                        username: authData.username,
                        clientId: authData.clientId,
                        tenantId: authData.tenantId,
                      };
                    case 'teams':
                      return {
                        username: authData.username,
                        clientId: authData.clientId,
                        tenantId: authData.tenantId,
                      };
                    default:
                      return {};
                  }
                })(),
              }
            : card
        )
      );
    } else {
      showAlert('Error with synching your data. Please try again or contact support', 'error');
    }
  };

  const handleExtension = () => {
    return;
    
    return (
      <>
      <section style={{ marginTop: '5rem' }}>
        <div style={{ width: '53%' }}>
          <div
            className={`selected-provider empty-card ${
              isExtensionInstalled ? 'selected' : ''
            } `}
          >
            <div className='row'>
              <div
                className='provider-image'
                style={{
                  backgroundImage: `url(${extensionDetails?.imageURL})`,
                }}
              ></div>
              <div className='selected-service'>
                <div className='label'>selected service</div>
                <div className='value'>{extensionDetails?.name}</div>
              </div>
            </div>
            <div className='row'>
              <div className='client-id'>
                <div className='label'>client id</div>
                <div className='value'>{extensionDetails?.clientId}</div>
              </div>
              <div className='tenant-id'>
                <div className='label'>tenant id</div>
                <div className='value'>{extensionDetails?.tenantId}</div>
              </div>
            </div>
            <div className='row'>
              <div className='client-secret'>
                <div className='label'>client secret</div>
                <div className='value'>{extensionDetails?.username}</div>
              </div>
            </div>

            <div className='row'>
              <div className='business-info'>
                <div className='chip-image'></div>
                <div className=''>
                  <div className='label'>business</div>
                  <div className='value'>{extensionDetails.business}</div>
                </div>
              </div>
              <div
                className='connect-button'
                onClick={installExtension}
              >
                {isExtensionInstalled
                  ? 'chrome extension installed'
                  : ' install chrome extension'}
                <i className='bx bxs-chevron-right-circle'></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    )
  }

  return (
    <>
      <section>
        <div className='titles-container'>
          <h2>
            <span>Connect your providers</span>
          </h2>
          <h4>
            When it comes to your business workspaces, we make it really easy to
            integrate your data. <br />
            Choose your provider.
          </h4>
        </div>
        <div className='providers-container'>
          <div className='providers-cards-container'>
            <div className='providers-cards card empty-card'>
              {cards.map((card, index) => {
                return (
                  <div
                    className={`provider-card ${
                      selectedProvider.id === card.id || card.isConnected
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => handleProviderClick(card.id)}
                    key={index}
                  >
                    <div
                      className='card-image'
                      style={{
                        backgroundImage: `url(${card.imageURL})`,
                      }}
                    />
                    <div className='card-info'>
                      <span>{card.service}</span>
                      <div className='card-title'>{card.name}</div>
                      <div className='connect-button'>
                        {(card.isConnected && 'Connected') ||
                          (selectedProvider.id === card.id && 'Selected') ||
                          'Connect'}{' '}
                        <i className='bx bxs-chevron-right-circle'></i>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='selected-provider empty-card'>
              <div className='row'>
                <div
                  className='provider-image'
                  style={{
                    backgroundImage: `url(${selectedProvider.imageURL})`,
                  }}
                ></div>
                <div className='selected-service'>
                  <div className='label'>selected service</div>
                  <div className='value'>{selectedProvider.name}</div>
                </div>
              </div>

              {!selectedProvider.isConnected && (
                <>
                  <div className='row'>
                    <div className='client-id'>
                      <div className='label'>client id</div>
                      <div className='value'>{selectedProvider?.clientId}</div>
                    </div>
                    <div className='tenant-id'>
                      <div className='label'>tenant id</div>
                      <div className='value'>{selectedProvider?.tenantId}</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='client-secret'>
                      <div className='label'>client secret</div>
                      <div className='value'>{selectedProvider?.username}</div>
                    </div>
                  </div>
                </>
              )}
              {selectedProvider.isConnected && (
                <>
                  {selectedProvider.id === 'azure' && (
                    <>
                      <div className='row'>
                        <div className='client-id'>
                          <div className='label'>Object ID</div>
                          <div className='value'>
                            {
                              
                              selectedProvider?.objectId?.slice(0, 20) + '..'
                            }
                          </div>
                        </div>
                        <div className='tenant-id'>
                          <div className='label'>tenant ID</div>
                          <div className='value'>
                            {selectedProvider.tenantId?.slice(0, 20) + '..'}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='client-secret'>
                          <div className='label'>username</div>
                          <div className='value'>
                            {selectedProvider.username}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedProvider.id === 'slack' && (
                    <>
                      <div className='row'>
                        <div className='team-name'>
                          <div className='label'>Team Name</div>
                          <div className='value'>
                            {
                              
                              selectedProvider?.team
                            }
                          </div>
                        </div>
                        <div className='enterprise-name'>
                          <div className='label'>Enterprise Name</div>
                          <div className='value'>
                            {
                              
                              selectedProvider?.enterprise
                            }
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='client-secret'>
                          <div className='label'>user Id</div>
                          <div className='value'>
                            {
                              
                              selectedProvider?.userId && (
                                <>

                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {
                    selectedProvider.id === 'extension' &&
                      <>
                        <div className='row'>
                          <div className='client-id'>
                            <div className='label'>Object ID</div>
                            <div className='value'>
                              {
                                
                                selectedProvider?.objectId?.slice(0, 20) + '..'
                              }
                            </div>
                          </div>
                          <div className='tenant-id'>
                            <div className='label'>tenant ID</div>
                            <div className='value'>
                              {selectedProvider.tenantId?.slice(0, 20) + '..'}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='client-secret'>
                            <div className='label'>username</div>
                            <div className='value'>
                              {selectedProvider.username}
                            </div>
                          </div>
                        </div>
                      </>
                  }
                </>
              )}
              <div className='row'>
                <div className='business-info'>
                  <div className='chip-image'></div>
                  <div className=''>
                    <div className='label'>business</div>
                    <div className='value'>{selectedProvider.business}</div>
                  </div>
                </div>
                <div
                  className='connect-button'
                  onClick={handleConnect}
                >
                  {selectedProvider.isConnected ? 'Connected' : 'Connect'}
                  <i className='bx bxs-chevron-right-circle'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {handleExtension()}
    </>
  );
}
