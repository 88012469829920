import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsAction } from './eventsThunks';

const initialState = {
  eventIds: [],
  eventData: {},
  seenEvents: {},
  isLoading: true,
  error: null,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    markNotificationAsSeen: (state, action) => {
      const notificationId = action.payload;

      state.seenEvents[notificationId] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
      const { eventIds, eventData, seenEvents } = action.payload;

      Object.assign(state, { eventIds, eventData, seenEvents });

      state.isLoading = false;

      state.error = null;
    });
    builder.addCase(getNotificationsAction.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { markNotificationAsSeen } = eventsSlice.actions;

export default eventsSlice.reducer;
