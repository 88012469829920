import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/software';

export const getAllSoftware = async () => {
  try {
    const response = await axios.get(`${prefix}/all`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getSoftwareById = async (softwareId) => {
  try {
    const response = await axios.get(`${prefix}/${softwareId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getSoftwareByIdList = async (softwareIds) => {
  try {
    const response = await axios.post(`${prefix}/getSoftwareByIDList`, {
      softwareIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMostPopularSoftwareByLicenceCount = async () => {
  try {
    const response = await axios.get(`${prefix}/mostPopular/licenceCount`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
