import React, { useState } from 'react';
import useHandleChartRender from '../../hooks/useHandleChartRender';
import {
  capitalize,
  getChartName,
  getChartTypes,
} from '../../util/UtilFunctions';

export default function AnalyticsComponent() {
  const { handleChartRender } = useHandleChartRender();

  const [selectedView, setSelectedView] = useState('months');

  const height = 200;

  const handleClick = () => {
    if (selectedView === 'months') {
      setSelectedView('years');
    } else if (selectedView === 'years') {
      setSelectedView('months');
    }
  };

  const chartTypes = getChartTypes();

  return (
    <>
      <section>
        <div className='titles-container'>
          <h2>
            <span>Analytics</span>
          </h2>
          <h4>Dive deeper into your company&apos;s expenses</h4>
        </div>
      </section>
      <section className='analytics-page'>
        <button
          className='button'
          onClick={handleClick}
        >
          {capitalize(selectedView)}
        </button>
        <div className='stats-cards-container'>
          {chartTypes.map((type) => (
            <div
              key={getChartName(type)}
              className='software-card stats-card'
            >
              <div className='title'>{getChartName(type)}</div>
              {handleChartRender(type, selectedView, height)}
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
