import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllInvoices,
  getRecentInvoices,
} from '../../../services/api/invoice/fetch';
import {
  addInvoice,
  addSoftwareInvoice,
  confirmInvoiceAndUpdate,
  deleteInvoice,
  getExistingTeams,
  updateInvoiceDueDate,
  updateInvoiceName,
  updateInvoiceValue,
} from '../../../services/api/invoice/modify';
import { getFormattedInvoices } from '../../../services/invoice/format';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 4;

export const getAllInvoicesAction = createAsyncThunk(
  'invoice/getAllInvoices',
  async (
    
    arg,
    { rejectWithValue }
  ) => {
    
    const { page = 1, limit = itemsPerPage, search, software, folder } = arg;

    try {
      const data = await getAllInvoices(page, limit, search, software, folder);

      const { invoices } = data;

      const formattedInvoices = getFormattedInvoices(invoices);

      return { ...data, invoices: formattedInvoices };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRecentInvoicesAction = createAsyncThunk(
  'invoice/getRecentInvoices',
  
  async ({ limit = 10 } = {}, { rejectWithValue }) => {
    try {
      const data = await getRecentInvoices(limit);

      const { recentInvoices } = data;

      const formattedInvoices = getFormattedInvoices(recentInvoices);

      return { ...data, recentInvoices: formattedInvoices };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addInvoiceAction = createAsyncThunk(
  'invoice/addInvoice',
  
  async ({ formData }, { rejectWithValue }) => {
    try {
      const invoice = await addInvoice(formData);

      return invoice;
    } catch (error) {
      const errorMessage =
        error?.data?.error || // If thrown from Axios or Fetch response
        error?.message || // Generic client-side error message
        'An unknown error occurred'; // Fallback message

      return rejectWithValue({ message: errorMessage, status: error?.status });
    }
  }
);

export const addSoftwareInvoiceAction = createAsyncThunk(
  'invoice/addSoftwareInvoice',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { invoiceId, software } = arg;

      const invoice = await addSoftwareInvoice(invoiceId, software);

      return invoice;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInvoiceAction = createAsyncThunk(
  'invoice/deleteInvoice',
  async (arg, { rejectWithValue }) => {
    try {
      
      const { invoiceId } = arg;

      const response = await deleteInvoice(invoiceId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoiceNameAction = createAsyncThunk(
  'invoice/updateInvoiceName',
  
  async ({ invoiceId, name }, { rejectWithValue }) => {
    try {
      const response = await updateInvoiceName({ invoiceId, name });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoiceValueAction = createAsyncThunk(
  'invoice/updateInvoiceValue',
  
  async (arg, { rejectWithValue }) => {
    try {
      
      const { invoiceId, value } = arg;

      const response = await updateInvoiceValue({ invoiceId, value });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoiceDueDateAction = createAsyncThunk(
  'invoice/updateInvoiceDueDate',
  
  async (arg, { rejectWithValue }) => {
    try {
      
      const { invoiceId, dueDate } = arg;

      const response = await updateInvoiceDueDate({ invoiceId, dueDate });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmInvoiceAndUpdateAction = createAsyncThunk(
  'invoice/confirmInvoiceAndUpdate',
  
  async (arg, { rejectWithValue }) => {
    try {
      
      const { invoiceId, invoiceData } = arg;
      const response = await confirmInvoiceAndUpdate( invoiceId, invoiceData );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getExistingTeamsAction = createAsyncThunk(
  'invoice/getexistingteams',
  
  async (arg, { rejectWithValue }) => {
    try {
      
      const { softwareId } = arg;

      const response = await getExistingTeams( softwareId );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

