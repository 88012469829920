import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamCostsAndLicenceCountByYearAction } from '../../../redux/features/analytics/analyticsThunks';
import { addTeamAction } from '../../../redux/features/response/responseThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../../redux/features/teams/teamsThunks';
import {
  getAddTeamBody,
  haveSelectedUsers,
} from '../../../services/teams/addTeam';
import TeamCard from '../../Teams/TeamCard';
import AddUsersStep from './AddUsersStep';
import SetManagerStep from './SetManagerStep';
import TeamDetailsStep from './TeamDetailsStep';
import { TokenService } from '../../../services/TokenService';

export default function AddTeamModal({ toggleModal }) {
  const dispatch = useDispatch();

  
  const { user } = useSelector((state) => state.user);

  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [teamDetails, setTeamDetails] = useState({
    name: '',
    email: '',
    description: '',
  });
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedManager, setSelectedManager] = useState(null);

  const isNotValidState = (change) => {
    if (isSubmitting) {
      return true;
    }

    if (step <= 1 && change < 0) {
      return true;
    }

    if (!teamDetails.name) {
      return true;
    }

    /*if (step >= 2 && change > 0 && !haveSelectedUsers(selectedUsers)) {
      return true;
    }*/

    return false;
  };

  const handleStepChange = (change) => {
    if (isNotValidState(change)) {
      return;
    }

    if (change < 0 && step <= 1) {
      toggleModal(false);
      return;
    }

    if (change > 0 && step >= 3) {
      setIsSubmitting(true);

      const body = getAddTeamBody(
        teamDetails,
        selectedUsers,
        selectedManager,
        user?.company,
        TokenService.getToken("azure"),
      ); 

      
      dispatch(addTeamAction(body))
        
        .then(() => {
          setSelectedUsers({});
          setSelectedManager(null);
          setTeamDetails({
            name: '',
            email: '',
            description: '',
          });
        })
        .finally(() => {
          const year = new Date().getFullYear();
          
          dispatch(getTeamCostsAndLicenceCountByYearAction(year));
          
          dispatch(getManagedTeamsAndMonthlyCostsAction());
          setIsSubmitting(false);
          toggleModal(false);
        });
      return;
    }

    setStep(step + change);
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = {
        ...prevSelectedUsers,
        [userId]: !prevSelectedUsers[userId],
      };

      if (!newSelectedUsers[userId] && selectedManager === userId) {
        setSelectedManager(null);
      }

      return newSelectedUsers;
    });
  };

  const renderStepButton = (visible) => {
    if (visible) {
      return (
        <div className='next-btn-container'>
          {!isSubmitting && step > 1 && (
            <button
              className='next-btn previous'
              onClick={() => handleStepChange(-1)}
            >
              Previous
            </button>
          )}
          {!isNotValidState(1) && (
            <button
              className='next-btn'
              onClick={() => handleStepChange(1)}
              disabled={isNotValidState(1)}
            >
              Next
            </button>
          )}
        </div>
      );
    }
    return null;
  };

  const renderSteps = () => {
    const steps = [1, 2, 3];

    return (
      <div className='steps-container'>
        {steps.map((stepNumber) => (
          <div
            key={stepNumber}
            className={'step' + (step === stepNumber ? ' active' : '')}
            onClick={() => handleStepChange(stepNumber - step)}
          >
            {stepNumber}
          </div>
        ))}
      </div>
    );
  };

  const renderStepsContent = () => {
    const renderFirstStepOrDefault = () => (
      <>
        <TeamDetailsStep
          teamDetails={teamDetails}
          setTeamDetails={setTeamDetails}
        />
        <TeamCard
          team={teamDetails}
          index={1}
        />
      </>
    );

    switch (step) {
      case 1:
        return renderFirstStepOrDefault();
      case 2:
        return (
          <AddUsersStep
            selectedUsers={selectedUsers}
            toggleUserSelection={toggleUserSelection}
          />
        );
      case 3:
        return (
          <SetManagerStep
            selectedUsers={selectedUsers}
            selectedManager={selectedManager}
            setSelectedManager={setSelectedManager}
          />
        );
      default:
        return renderFirstStepOrDefault();
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container'>
        <div className='modal'>
          <div className='titles'>
            <h3>New Team</h3>
            <p>Add and setup a new team to your organisation</p>
          </div>

          <div
            className={'modal-content' + (step === 1 ? ' add-team-modal' : '')}
            key={step}
          >
            {renderStepsContent()}
          </div>
          {renderStepButton(!!teamDetails.name)}
        </div>

        {renderSteps()}
      </div>
    </div>
  );
}
