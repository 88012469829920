const getWheelTeamMemberStyles = () => [
  { left: '-40px' },
  { right: 0, top: '-10%' },
  { bottom: 0, left: '-40px' },
  { bottom: '-25px', right: '-7%' },
  { bottom: '-47%', right: '40%' },
  { top: '-45%', right: '40%' },
  { bottom: '50%', left: '-55%', width: '35px', height: '35px' },
  { bottom: '50%', right: '-42%', width: '35px', height: '35px' },
];

export default getWheelTeamMemberStyles;
