import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { formatDate } from '../../util/formatDate';

export default function FolderComponent({
  folder,
  isEditing,
  editValue,
  handleEdit,
  handleSave,
  toggleDropdown,
  handleDelete,
  visibleDropdown,
  setEditValue,
}) {
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        toggleDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  const renderFolderContent = () => {
    return (
      <div className='folder-content'>
        {!isEditing || isEditing !== folder?._id ? (
          folder?.name ? (
            folder.name
          ) : (
            <Skeleton
              width={150}
              baseColor='#333'
              highlightColor='#444'
            />
          )
        ) : (
          <input
            type='text'
            className='text-input folder-input'
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={() => handleSave(folder?._id, false)}
            autoFocus
          />
        )}
        {folder?.createdAt ? (
          <p>
            {folder?.files?.length} Files • {formatDate(folder?.createdAt)}
          </p>
        ) : (
          <Skeleton
            width={100}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </div>
    );
  };

  return (
    <Link
      to={`/dashboard/invoices/folder/${folder?._id}`}
      className='folder-container'
    >
      <div className='preview'>
        {folder ? (
          <i className='icon invoices' />
        ) : (
          <Skeleton
            circle={true}
            height={40}
            width={40}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </div>
      {renderFolderContent()}
      <div className='options-container'>
        {folder ? (
          <i
            ref={iconRef}
            className='bx bx-dots-vertical-rounded'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleDropdown(folder._id);
            }}
          />
        ) : (
          <Skeleton
            width={20}
            height={20}
            baseColor='#333'
            highlightColor='#444'
          />
        )}
      </div>
      {visibleDropdown === folder?._id && folder && (
        <div
          className='dropdown-menu'
          ref={dropdownRef}
        >
          <ul>
            <li onClick={() => handleDelete(folder._id, true)}>
              <i className='bx bx-trash' /> Delete
            </li>
            <li onClick={() => handleEdit(folder._id, folder.name, true)}>
              <i className='bx bx-edit' /> Edit
            </li>
          </ul>
        </div>
      )}
    </Link>
  );
}
