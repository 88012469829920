export const formatToSlug = (str) => {
  if (!str) {
    return '';
  }

  return str
    .trim() // Remove leading and trailing whitespace
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w-]+/g, ''); // Remove all non-word characters except hyphens
};

export default formatToSlug;
