import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicTrackedSoftwareDataByTeamAction } from '../../../redux/features/software/softwareThunks';
import {
  addUsersToTeamAction,
  getManagedTeamsAndMonthlyCostsAction,
} from '../../../redux/features/teams/teamsThunks';
import { haveSelectedUsers } from '../../../services/teams/addTeam';
import AddUsersStep from '../Add Team/AddUsersStep';

export default function AddUsersModal(props) {
  const dispatch = useDispatch();

  const { toggleModal, teamId } = props;

  
  const prevState = useSelector((state) => state.teams);

  const [step, setStep] = useState(1);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});

  const isNotValidState = (change) => {
    if (step <= 1 && change < 0) {
      return true;
    }

    if (step >= 1 && change > 0 && !haveSelectedUsers(selectedUsers)) {
      return true;
    }

    return false;
  };

  const handleStepChange = (change) => {
    if (isNotValidState(change)) {
      return;
    }

    if (change < 0 && step <= 1) {
      toggleModal(false);
      return;
    }

    if (change > 0 && step === 1) {
      setIsSubmitting(true);

      const userIds = Object.keys(selectedUsers).filter(
        (userId) => selectedUsers[userId]
      );

      toggleModal(false);

      dispatch(
        
        addUsersToTeamAction({ teamId, userIds, prevState })
        
      ).finally(() => {
        setIsSubmitting(false);
        
        dispatch(getDynamicTrackedSoftwareDataByTeamAction(teamId));
        
        dispatch(getManagedTeamsAndMonthlyCostsAction());
      });
      return;
    }
    setStep(step + change);
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = {
        ...prevSelectedUsers,
        [userId]: !prevSelectedUsers[userId],
      };

      return newSelectedUsers;
    });
  };

  const renderStepButton = (visible) => {
    if (visible) {
      return (
        <div className='next-btn-container'>
          {!isSubmitting && (
            <button
              className='next-btn'
              onClick={() => handleStepChange(1)}
              disabled={isNotValidState(1)}
            >
              Next
            </button>
          )}
        </div>
      );
    }
    return null;
  };

  const renderSteps = () => {
    const steps = [1];

    return (
      <div className='steps-container'>
        {steps.map((stepNumber) => (
          <div
            key={stepNumber}
            className={'step' + (step === stepNumber ? ' active' : '')}
            onClick={() => handleStepChange(stepNumber - step)}
          >
            {stepNumber}
          </div>
        ))}
      </div>
    );
  };

  const renderStepsContent = () => {
    const renderFirstStepAndDefault = () => (
      <AddUsersStep
        selectedUsers={selectedUsers}
        toggleUserSelection={toggleUserSelection}
        teamId={teamId}
      />
    );

    switch (step) {
      case 1:
        return renderFirstStepAndDefault();
      default:
        return renderFirstStepAndDefault();
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container'>
        <div className='modal'>
          <div className='titles scroll-shadows'>
            <h3>New Users</h3>
            <p>Add new users to your team</p>
          </div>

          <div
            className='modal-content'
            key={step}
          >
            {renderStepsContent()}
          </div>
          {renderStepButton(haveSelectedUsers(selectedUsers))}
        </div>

        {renderSteps()}
      </div>
    </div>
  );
}
