import React from 'react';

export default function LoginErrorMessage(props) {
  const { showNotFoundError, errorAnimationKey, errorMessage } = props;

  return (
    showNotFoundError && (
      <div
        key={errorAnimationKey}
        className='error-message'
      >
        {errorMessage || 'Invalid credentials. Please try again.'}
      </div>
    )
  );
}
