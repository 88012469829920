import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Header(props) {
  const {
    isMenuOpen,
    setMenuOpen,
    isNotificationMenuOpen,
    setIsNotificationMenuOpen,
  } = props;

  
  const { company } = useSelector((state) => state.company);

  
  const { seenEvents } = useSelector((state) => state.events);

  const [hasClickedBell, setHasClickedBell] = useState(false);

  const hasUnseenNotifications = Object.values(seenEvents).some(
    (value) => !value
  );

  const handleNotificationClick = () => {
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
    setHasClickedBell(true);
  };

  return (
    <nav className='header'>
      <div
        className='menu-toggle'
        onClick={() => setMenuOpen(!isMenuOpen)}
      >
        <i className='bx bx-menu' />
      </div>
      <div
        className='notifications'
        onClick={handleNotificationClick}
      >
        <i className='icon bell'></i>
        {hasUnseenNotifications && !hasClickedBell && (
          <span className='notification-dot'></span>
        )}
      </div>
      <div className='company'>
        <div
          className='company-image'
          style={{ backgroundImage: company?.imageUrl ? `url(${company?.imageUrl})` : '' }}
        ></div>
        <div className='company-info'>
          <div className='company-name'>{company?.name}</div>
          <div className='company-account'>Main company account</div>
        </div>
        <i className='icon options'></i>
      </div>
    </nav>
  );
}
