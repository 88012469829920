import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AnalyticsComponent from '../components/Analytics/AnalyticsComponent';
import ApplicationComponent from '../components/Application/ApplicationComponent';
import ApplicationsListComponent from '../components/ApplicationsListComponent';
import DashboardComponent from '../components/DashboardComponent';
import Header from '../components/Header';
import FolderPageComponent from '../components/Invoices/FolderPageComponent';
import InvoicesComponent from '../components/Invoices/InvoicesComponent';
import NotificationsComponent from '../components/Notifications/NotificationsComponent';
import ProfileComponent from '../components/Profile/ProfileComponent';
import ProvidersComponent from '../components/ProvidersComponent';
import SideMenu from '../components/SideMenu';
import ManagedTeamsComponent from '../components/Teams/ManagedTeamsComponent';
import TeamComponent from '../components/Teams/TeamComponent';
import useAuthRedirect from '../hooks/useAuthRedirect';
import { getCompanyDataAction } from '../redux/features/company/companyThunks';
import { getNotificationsAction } from '../redux/features/events/eventsThunks';
import { getAllUserWidgetsAction } from '../redux/features/widgets/widgetThunks';
import { getAchievementData } from '../util/UtilFunctions';
import isValidToken from '../util/isValidToken';

export default function DashboardContainer(props) {
  const dispatch = useDispatch();

  const { page } = props;

  const params = useParams();
  const name = params.teamName || params.softwareName || '';

  
  const { user, token } = useSelector((state) => state.user);

  const achievementMenuData = getAchievementData();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);

  useAuthRedirect();

  useEffect(() => {
    if (isValidToken(token)) {
      const companyId = user?.company;
      
      dispatch(getCompanyDataAction(companyId));
    }
  }, [token, user?.company, dispatch]);

  useEffect(() => {
    if (isValidToken(token)) {
      
      dispatch(getAllUserWidgetsAction());
    }
  }, [token, dispatch]);

  useEffect(() => {
    const fetchNotifications = () => {
      
      dispatch(getNotificationsAction({}));
    };

    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const handlePageRender = (page) => {
    switch (page) {
      case '/':
        return <DashboardComponent achievementMenuData={achievementMenuData} />;
      case 'invoices':
        return <InvoicesComponent />;
      case 'folder':
        return <FolderPageComponent />;
      case 'analytics':
        return <AnalyticsComponent />;
      case 'application':
        return <ApplicationComponent softwareName={name} />;
      case 'applications':
        return <ApplicationsListComponent />;
      case 'team':
        return <TeamComponent teamName={name} />;
      case 'teams':
        return <ManagedTeamsComponent />;
      case 'connect-to-providers':
        return <ProvidersComponent />;
      case 'profile':
        return <ProfileComponent />;
      default:
        return <DashboardComponent achievementMenuData={achievementMenuData} />;
    }
  };

  if (isValidToken(token) && user?.company) {
    return (
      <div className='dashboard-container'>
        <SideMenu
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
        <div
          className='page-container'
          onClick={() => {
            isMenuOpen && setMenuOpen(false);
          }}
        >
          <Header
            isMenuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
            isNotificationMenuOpen={isNotificationMenuOpen}
            setIsNotificationMenuOpen={setIsNotificationMenuOpen}
          />
          <NotificationsComponent
            isNotificationMenuOpen={isNotificationMenuOpen}
            setIsNotificationMenuOpen={setIsNotificationMenuOpen}
          />
          <div
            className='content'
            id='scroll-content'
          >
            {handlePageRender(page)}
          </div>
        </div>
      </div>
    );
  }
}
