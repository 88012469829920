import { useDispatch } from 'react-redux';
import { useAlert } from '../providers/AlertProvider';

export const useActionHandler = () => {
  const { showAlert } = useAlert();
  const dispatch = useDispatch();

  console.log('here')
  return async (action, successMessage, errorMessage, onSuccess = null) => {
    console.log('here called', action)
    const result = await dispatch(action);
    console.log('result', result)
    if (result.meta.rejectedWithValue) {
      showAlert(errorMessage || `Error: ${result.payload.message}`, 'error');
    } else {
      showAlert(successMessage, 'success');
      if (onSuccess) {
        onSuccess(result);
      }
    }
  };
};
