export function formatDate(timestamp) {
  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    if (typeof timestamp === 'string' && timestamp.trim() !== '') {
      return timestamp;
    }
    return 'No Date Yet';
  }

  const currentYear = new Date().getFullYear();
  const dateYear = date.getFullYear();

  if (dateYear < currentYear - 5) {
    return 'No Date Yet';
  }

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  
  return date.toLocaleDateString('en-US', options);
}

export function formatDateTime(dateString) {
  if (!dateString)
    return ''
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; 
}
