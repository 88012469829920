import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/company';

//TODO: Handle image uploads
export const addCompany = async (name, users) => {
  try {
    const response = await axios.post(`${prefix}`, {
      name,
      users,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const createCompanyWithFirstTeam = async ({ name, team }) => {
  try {
    const response = await axios.post(`${prefix}/createWithFirstTeam`, {
      name,
      team,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateCompany = async (companyId, { name }) => {
  try {
    const response = await axios.put(`${prefix}/${companyId}`, {
      name,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const setCompanyManager = async (companyId, managerId) => {
  try {
    const body = {
      managerId,
    };

    const response = await axios.post(
      `${prefix}/${companyId}/setCompanyManager`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addTeamsToCompany = async (companyId, teams) => {
  try {
    const body = {
      teams,
    };

    const response = await axios.post(`${prefix}/${companyId}/addTeams`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addUsersToCompany = async (companyId, userIds) => {
  try {
    const body = {
      userIds,
    };

    const response = await axios.post(`${prefix}/${companyId}/addUsers`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateMultipleTeamLicences = async (companyId, teamIds) => {
  try {
    const body = {
      teamIds,
    };

    const response = await axios.post(
      `${prefix}/${companyId}/deactivateLicences`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteCompany = async (companyId) => {
  try {
    const response = await axios.delete(`${prefix}/${companyId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
