import React from 'react';
import { useSelector } from 'react-redux';
import TeamsOverviewContainer from '../containers/TeamsOverviewContainer';
import BudgetAnalysis from './Sections/BudgetAnalysis';
import MostUsedSoftware from './Sections/MostUsedSoftware';
import TrackedSoftware from './Tracked Software/TrackedSoftware';
import WidgetsList from './Widgets/WidgetsList';

export default function DashboardComponent(props) {
  const { achievementMenuData } = props;
  
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <section>
        <div className='titles-container'>
          <h2>
            Welcome, <span> {user?.name}</span>
          </h2>
          <h4>Here is your Dashboard Overview </h4>
        </div>
        <WidgetsList achievementMenuData={achievementMenuData} />
      </section>
      <TrackedSoftware displayTitle={true} />
      <TeamsOverviewContainer displayTitle={true} />
      <BudgetAnalysis />
      <MostUsedSoftware />
    </>
  );
}
