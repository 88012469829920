import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCheckedUsers,
  usePaymentDetails,
  useSoftwareData,
  useTeamsData,
  useUserToTeams,
} from '../../../hooks/addSoftwareModal';
import {
  getTeamCostsAndLicenceCountByYearAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
} from '../../../redux/features/analytics/analyticsThunks';
import { addMultipleSoftwareToMultipleTeamsAction } from '../../../redux/features/response/responseThunks';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getMultipleTeamsAvailableSoftwareAction,
  getTrackedSoftwareDataAction,
} from '../../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../../redux/features/teams/teamsThunks';
import getAddMultipleSoftwareToMultipleTeamsBody from '../../../services/teams/addSoftwareModal';
import {
  getLocalStorageState,
  updateLocalStorageState,
} from '../../../util/localStorage';
import AddSoftwareResultStep from './AddSoftwareResultStep';
import ConfirmationStep from './ConfirmationStep';
import SearchSoftware from './SearchSoftwareStep';
import TeamSelection from './TeamSelectionStep';

const localStorageKey =
  process.env.REACT_APP_ADD_SOFTWARE_MODAL_LOCAL_STORAGE_KEY ||
  'addSoftwareModal';

export default function AddSoftwareModal(props) {
  const dispatch = useDispatch();

  const { toggleModal, teamId } = props;

  const { softwareList, existingSoftwareList } = useSoftwareData();

  const { teams, teamData, teamIds } = useTeamsData();
  const {
    paymentDetails,
    setPaymentDetails,
    handleTierChange,
    handlePaymentTypeChange,
  } = usePaymentDetails();
  const { checkedUsers, toggleUserCheck } = useCheckedUsers();
  const { userToTeams, handleTeamChange } = useUserToTeams();

  const localStorageState = getLocalStorageState(localStorageKey);
  const [step, setStep] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedSoftwareTracker, setSelectedSoftwareTracker] = useState(
    localStorageState.selectedSoftwareTracker || {}
  );
  const [selectedTeamsTracker, setSelectedTeamsTracker] = useState(
    localStorageState.selectedTeamsTracker || {}
  );

  useEffect(() => {
    if (teamIds.length > 0) {
      
      dispatch(getMultipleTeamsAvailableSoftwareAction(teamIds)).then(() =>
        setIsReady(true)
      );
    }
  }, [dispatch, teamIds]);

  useEffect(() => {
    if (teamId) {
      setSelectedTeamsTracker({ [teamId]: true });
    }
  }, [teamId, setSelectedTeamsTracker]);

  useEffect(() => {
    updateLocalStorageState(localStorageKey, {
      selectedSoftwareTracker,
      selectedTeamsTracker,
    });
  }, [selectedSoftwareTracker, selectedTeamsTracker]);

  const isNotValidState = (change) => {
    if (isSubmitting) {
      return true;
    }

    if (step <= 1 && change < 0) {
      return true;
    }

    if (
      change > 0 &&
      !Object.values(selectedSoftwareTracker).some((val) => val === true)
    ) {
      return true;
    }

    if (
      step >= 2 &&
      change > 0 &&
      !Object.values(selectedTeamsTracker).some((val) => val === true)
    ) {
      return true;
    }

    return false;
  };

  const handleStepChange = (change) => {
    if (isNotValidState(change)) {
      return;
    }

    if (change < 0 && step <= 1) {
      toggleModal(false);
      return;
    }

    if (change > 0 && step >= 3) {
      setIsSubmitting(true);

      const body = getAddMultipleSoftwareToMultipleTeamsBody(
        selectedSoftwareTracker,
        checkedUsers,
        userToTeams,
        paymentDetails,
        Object.keys(selectedTeamsTracker).filter(
          (teamId) => selectedTeamsTracker[teamId]
        )
      );

      
      dispatch(addMultipleSoftwareToMultipleTeamsAction(body))
        
        .then(() => {
          setSelectedSoftwareTracker({});
          setSelectedTeamsTracker({});
          localStorage.removeItem(localStorageKey);
        })
        .finally(() => {
          setIsSubmitting(false);
          setStep(4);
          
          dispatch(getTrackedSoftwareDataAction());
          
          dispatch(getDynamicTrackedSoftwareDataByTeamAction());
          
          dispatch(getManagedTeamsAndMonthlyCostsAction());
          
          dispatch(getTeamCostsAndLicenceCountByYearAction());
          
          dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction());
        });
      return;
    }

    setStep(step + change);
  };

  const renderStepButton = (visible) => {
    if (visible) {
      return (
        <div className='next-btn-container'>
          {!isSubmitting && step > 1 && (
            <button
              className='next-btn previous'
              onClick={() => handleStepChange(-1)}
            >
              Previous
            </button>
          )}
          {!isSubmitting && (
            <button
              className='next-btn'
              onClick={() => handleStepChange(1)}
              disabled={isNotValidState(1)}
            >
              Next
            </button>
          )}
        </div>
      );
    }
    return null;
  };

  const renderSteps = () => {
    const steps = [1, 2, 3, 4];

    return (
      <div className='steps-container'>
        {steps.map((stepNumber) => (
          <div
            key={stepNumber}
            className={'step' + (step === stepNumber ? ' active' : '')}
            onClick={() => handleStepChange(stepNumber - step)}
          >
            {stepNumber}
          </div>
        ))}
      </div>
    );
  };

  const renderStepsContent = () => {
    const renderFirstStepAndDefault = () => (
      <SearchSoftware
        softwareList={softwareList}
        existingSoftwareList={existingSoftwareList}
        selectedSoftwareTracker={selectedSoftwareTracker}
        setSelectedSoftwareTracker={setSelectedSoftwareTracker}
      />
    );

    switch (step) {
      case 1:
        return renderFirstStepAndDefault();
      case 2:
        return (
          <TeamSelection
            teams={teams}
            selectedTeamsTracker={selectedTeamsTracker}
            setSelectedTeamsTracker={setSelectedTeamsTracker}
          />
        );
      case 3:
        return (
          <ConfirmationStep
            softwareList={softwareList}
            selectedSoftwareTracker={selectedSoftwareTracker}
            selectedTeamsTracker={selectedTeamsTracker}
            teams={teams}
            teamData={teamData}
            userToTeams={userToTeams}
            handleTeamChange={handleTeamChange}
            checkedUsers={checkedUsers}
            toggleUserCheck={toggleUserCheck}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            handleTierChange={handleTierChange}
            handlePaymentTypeChange={handlePaymentTypeChange}
          />
        );
      case 4:
        return <AddSoftwareResultStep />;
      default:
        return renderFirstStepAndDefault();
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container'>
        <div className='modal'>
          <div className='titles'>
            <h3>New Software</h3>
            <p>
              Add and configure new software subscriptions to your organisation
            </p>
          </div>

          <div
            className='modal-content'
            key={step}
          >
            {renderStepsContent()}
          </div>
          {renderStepButton(
            isReady &&
              Object.values(selectedSoftwareTracker).some((val) => val === true)
          )}
        </div>

        {renderSteps()}
      </div>
    </div>
  );
}
