import React from 'react';
import { useSelector } from 'react-redux';
import SoftwareRow from './SoftwareRow';

export default function TableDataSoftware(props) {
  const {
    softwareIds,
    dynamicSoftwareData,
    selectedCard,
    setSelectedCard,
    currentPage,
    itemsPerPage,
    teamId,
  } = props;

  const { staticSoftwareData } = useSelector(
    
    (state) => state.software
  );

  const combinedSoftwareData = Object.keys(staticSoftwareData).reduce(
    (acc, key) => {
      acc[key] = {
        ...staticSoftwareData[key],
        ...dynamicSoftwareData[key],
      };
      return acc;
    },
    {}
  );

  if (!softwareIds?.length) {
    return null;
  }

  const offset = currentPage * itemsPerPage;
  const currentPageSoftwareIds = softwareIds.slice(
    offset,
    offset + itemsPerPage
  );

  return currentPageSoftwareIds.map((softwareId) => {
    const softwareData = combinedSoftwareData[softwareId];

    return (
      <SoftwareRow
        key={softwareId}
        softwareData={softwareData}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        teamId={teamId}
      />
    );
  });
}
