import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addMultipleSoftwareToMultipleTeamsAction } from '../../../../redux/features/response/responseThunks';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
} from '../../../../redux/features/software/softwareThunks';
import {
  formatTierName,
  getPaymentTypeValue,
} from '../../../../services/software/format';
import getAddMultipleSoftwareToMultipleTeamsBody from '../../../../services/teams/addSoftwareModal';

export default function AddSoftwareApplication(props) {
  const dispatch = useDispatch();

  const { team, softwareData, toggleModal } = props;

  const { prices, tierToName } = softwareData;

  const [details, setDetails] = useState({
    tier: '0',
    paymentType: softwareData?.paymentData?.[0]?.paymentType,
  });

  const onCancel = () => {
    toggleModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const addSoftwareApplication = () => {
    const body = getAddMultipleSoftwareToMultipleTeamsBody(
      { [softwareData?._id]: true },
      [],
      {},
      { [softwareData?._id]: { [team?._id]: details } },
      [team?._id]
    );

    
    dispatch(addMultipleSoftwareToMultipleTeamsAction(body)).finally(() => {
      toggleModal(false);
      
      dispatch(getTrackedSoftwareDataAction());
      
      dispatch(getDynamicTrackedSoftwareDataByTeamAction());
    });
  };

  const renderContent = () => {
    return (
      <form>
        <div className='titles'>
          <h3>
            Add Software {softwareData?.name} to {team?.name} team
          </h3>
        </div>
        <div>
          <label htmlFor='tier'>Tier</label>
          <select
            id='tier'
            name='tier'
            value={details.tier}
            onChange={handleChange}
          >
            {Object.keys(prices).map((tier) => (
              <option
                key={tier}
                value={tier}
              >
                {formatTierName(tierToName[tier])}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor='paymentType'>Payment Type</label>
          <select
            id='paymentType'
            name='paymentType'
            value={details.paymentType}
            onChange={handleChange}
          >
            {Object.keys(prices[details.tier] || {}).map((paymentType) => (
              <option
                key={paymentType}
                value={paymentType}
              >
                {getPaymentTypeValue(paymentType)}
              </option>
            ))}
          </select>
        </div>
      </form>
    );
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container confirmation'>
        <div className='modal'>
          {renderContent()}
          <div className='next-btn-container'>
            <button
              className='next-btn previous'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='next-btn'
              onClick={() => addSoftwareApplication()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
