import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  googleLoginAction,
  registerAction,
} from '../../redux/features/user/userThunks';
import SignupPopup from './SignupPopup';
import { AuthService, AzureSignin } from '../../services/api/AuthService';
import { API_URLS } from '../../util/Constants';
import isValidToken from '../../util/isValidToken';
import { useAlert } from '../../providers/AlertProvider';
import axios from 'axios';

export default function Signup() {
  const azureEndpoint = `${process.env.REACT_APP_BACK_API_URL}${API_URLS.AZURE.SIGN_IN}`;
  
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [animationKey, setAnimationKey] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const { showAlert } = useAlert();

  const handleSubmit = () => {
    dispatch(

      registerAction({
        name: formData?.name,
        email: formData?.email,
        password: formData?.password,
      })

    ).finally(() => {
      setRegistrationSuccess(true);
      setAnimationKey((prevKey) => prevKey + 1);
    });
  };

  const handleGoogleSuccess = async (response) => {

    dispatch(googleLoginAction(response));
  };

  const handleGoogleError = (error) => {
    console.error(error);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckPassword = (e) => {
    setPasswordMatch(e.target.value === formData?.password);
  };

  const renderPasswordError = () => {
    if (!passwordMatch) {
      return (
        <label className='error-alert'>
          <i className='bx bx-error'></i>
          Passwords are not matching
        </label>
      );
    }
  };

  const handleAzureConnect = async () => {
    const response = await axios.get(azureEndpoint);

    console.log('response', response)
    const { user, accessToken: token } = response;

      if (!isValidToken(token)) {
        showAlert('Signin error, please retry', 'error')
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
  };

  return (
    <>
      <div className='auth-text-container'>
        <h3 className='welcome-text'>Welcome !</h3>
        <h4 className='signup-text'>
          Already have an account?{' '}
          <Link
            className='signup-link'
            to='/start/login'
          >
            Login
          </Link>
        </h4>
      </div>
      <div className='social-buttons'>
        <div className='social-button'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 16 16'
            className='page_socialButtonIcon__kPbNF'
          >
            <path
              fill='currentColor'
              d='M12.665 15.358c-.905.844-1.893.711-2.843.311-1.006-.409-1.93-.427-2.991 0-1.33.551-2.03.391-2.825-.31C-.498 10.886.166 4.078 5.28 3.83c1.246.062 2.114.657 2.843.71 1.09-.213 2.133-.826 3.296-.746 1.393.107 2.446.64 3.138 1.6-2.88 1.662-2.197 5.315.443 6.337-.526 1.333-1.21 2.657-2.345 3.635zM8.03 3.778C7.892 1.794 9.563.16 11.483 0c.268 2.293-2.16 4-3.452 3.777'
            ></path>
          </svg>
        </div>
        <div className='social-button azure' onClick={handleAzureConnect} />
        <div
          className='social-button google'

          onClick={handleGoogleLogin}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 16 16'
            className='page_socialButtonIcon__kPbNF'
          >
            <path
              fill='#4285F4'
              d='M15.483 8.176c0-.519-.042-1.04-.132-1.551h-7.2v2.94h4.123a3.53 3.53 0 0 1-1.526 2.32v1.907h2.46c1.445-1.33 2.275-3.293 2.275-5.616'
            ></path>
            <path
              fill='#34A853'
              d='M8.15 15.632c2.058 0 3.794-.676 5.06-1.843l-2.46-1.907c-.685.465-1.569.729-2.598.729-1.992 0-3.68-1.344-4.286-3.15H1.327v1.966a7.64 7.64 0 0 0 6.822 4.205'
            ></path>
            <path
              fill='#FBBC04'
              d='M3.863 9.462a4.57 4.57 0 0 1 0-2.923V4.572H1.328a7.64 7.64 0 0 0 0 6.856z'
            ></path>
            <path
              fill='#EA4335'
              d='M8.15 3.387a4.15 4.15 0 0 1 2.928 1.144l2.18-2.18A7.34 7.34 0 0 0 8.148.367a7.63 7.63 0 0 0-6.822 4.207L3.863 6.54c.603-1.81 2.294-3.153 4.286-3.153'
            ></path>
          </svg>
          {/* <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
            /> */}
        </div>
      </div>
      <div className='separator'></div>
      <div className='input-container'>
        <input
          type='text'
          name='name'
          placeholder='Full name'
          value={formData?.name}
          onChange={(e) => handleInputChange(e)}
          autoFocus
        />
        <input
          type='email'
          name='email'
          placeholder='Email address'
          value={formData?.email}
          onChange={(e) => handleInputChange(e)}
        />
        <input
          type='password'
          name='password'
          placeholder='Password'
          value={formData?.password}
          onChange={(e) => handleInputChange(e)}
        />
        <input
          type='password'
          placeholder='Password Confirmation'
          onChange={(e) => handleCheckPassword(e)}
        />
        {renderPasswordError()}
      </div>
      <div>
        <button
          className='button'
          onClick={handleSubmit}
        >
          Create Account
          <div className='chevron'></div>
        </button>
      </div>

      <SignupPopup
        registrationSuccess={registrationSuccess}
        animationKey={animationKey}
        message='An invite has been sent to your email address. Please check your
          inbox.'
      />

      <div className='agreement-text'>
        By signing up, you agree to the{' '}
        <span className='highlight'>Terms of Service</span> and{' '}
        <span className='highlight'>Privacy Policy</span>.
      </div>
    </>
  );
}
