import React, { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the CSS for the skeleton
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
} from '../../redux/features/software/softwareThunks';
import { getMultipleUserDataAction } from '../../redux/features/user/userThunks';
import AddSoftwareModal from '../Modals/Add Software/AddSoftwareModal';
import SoftwareCard from './SoftwareCard';
import TableContainer from './TableContainer';
import { useMouseDragScroll } from '../../hooks/useMouseDragScroll';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 1;

export default function TrackedSoftware(props) {
  const dispatch = useDispatch();

  const { displayTitle, withoutCards, teamId } = props;
  const refs = useRef({});
  const { containerRef, attachDragHandlers, isDragging } = useMouseDragScroll();

  const {
    trackedSoftwareIds,
    userIds,
    allDynamicSoftwareData,
    trackedSoftwareDataByTeam,
    trackedSoftwareIdsByTeam,
  } =
    useSelector((state) => state.software);

  const softwareIds = useMemo(() => {
    if (teamId) {
      return trackedSoftwareIdsByTeam[teamId] || [];
    }
    return trackedSoftwareIds;
  }, [teamId, trackedSoftwareIdsByTeam, trackedSoftwareIds]);

  const dynamicSoftwareData = useMemo(() => {
    if (teamId) {
      return trackedSoftwareDataByTeam[teamId] || {};
    }

    return allDynamicSoftwareData;
  }, [teamId, trackedSoftwareDataByTeam, allDynamicSoftwareData]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(softwareIds.length === 0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(getTrackedSoftwareDataAction()).then(() => setIsLoading(false));
    dispatch(getDynamicTrackedSoftwareDataByTeamAction());
  }, [dispatch]);

  useEffect(() => {
    if (userIds?.length) {
      dispatch(getMultipleUserDataAction(userIds));
    }
  }, [dispatch, userIds]);

  useEffect(() => {
    if (selectedCard?._id) {
      if (refs.current[selectedCard._id]) {
        refs.current[selectedCard._id].scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center", 
        });
      }
    }
  }, [selectedCard]);

  useEffect(() => {
    softwareIds.forEach((softwareId) => {
      if (!refs.current[softwareId]) {
        refs.current[softwareId] = null;
      }
    });
  }, [softwareIds]);

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex.selected);
  };

  const renderSoftwareCards = () => {
    if (isLoading) {
      return Array(3)
        .fill()
        .map(() => (
          <div
            key={crypto.randomUUID()}
            className='software-card'
          >
            <Skeleton
              height={200}
              baseColor='#333'
              highlightColor='#444'
            />
          </div>
        ));
    }

    if (!softwareIds?.length) {
      return (
        <div style={{ width: '98%' }}>
          <div className='empty-data-message'>
            <i class='bx bxs-carousel' style={{ marginBottom: "10px" }} />
            <p>No software data to display yet...</p>
          </div>
        </div>
      )
    }

    return softwareIds.map((softwareId) => (
      <SoftwareCard
        key={softwareId}
        ref={(el) => (refs.current[softwareId] = el)}
        selectedSoftwareId={softwareId}
        dynamicSoftwareData={dynamicSoftwareData}
        isSelected={selectedCard?._id === softwareId}
        setSelectedCard={setSelectedCard}
      />
    ));
  };

  const toggleAddSoftwareModal = (value) => {
    setIsModalOpen(value);
  };

  return (
    <section>
      {displayTitle && (
        <div className='titles-container'>
          <h3>Tracked Software</h3>
          <h4>
            Software subscriptions that are managed by you ({softwareIds.length})
          </h4>
        </div>
      )}

      {!withoutCards && (
        <div className='dashboard-grid most-used-software' ref={containerRef}
        style={{cursor: isDragging ? "grabbing" : "pointer"}}
        {...attachDragHandlers()}>
          <div className='most-used-software-scroll' 
            style={{ width: !isLoading && !softwareIds?.length && '100%', cursor: isDragging ? "grabbing" : "grab",
            }} 
            
          >
            {renderSoftwareCards()}
          </div>
        </div>
      )}

      <div className='dashboard-element no-top'>
        {isLoading ? (
          <Skeleton
            height={400}
            baseColor='#333'
            highlightColor='#444'
          />
        ) : (
          <TableContainer
            headerData={[
              'Software',
              'Active Users',
              'Owner',
              'Expenses (last 31 days)',
              'Total Expenses',
              'Billing',
            ]}
            softwareIds={softwareIds}
            dynamicSoftwareData={dynamicSoftwareData}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            addFunction={toggleAddSoftwareModal}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            teamId={teamId}
          />
        )}
      </div>

      <div className='pagination-container padding'>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          pageRangeDisplayed={2}
          pageCount={Math.ceil(softwareIds.length / itemsPerPage)}
          previousLabel='<'
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
        />
      </div>

      {isModalOpen && (
        <AddSoftwareModal
          toggleModal={toggleAddSoftwareModal}
          teamId={teamId}
        />
      )}
    </section>
  );
}
