import { formatSpendingWidgetData } from './formatSpendingWidgetData';
import { formatSubscriptionWidgetData } from './formatSubscriptionWidgetData';

export const formatWidgetData = (widgetData) => {
  switch (widgetData.type) {
    case 'spending':
      return formatSpendingWidgetData(widgetData);
    case 'subscription':
      console.log('widgetData subscription', widgetData);
      return formatSubscriptionWidgetData(widgetData);
    default:
      return widgetData;
  }
};
