import React from 'react'

export default function PreviewFile(props) {
    const { toggleModal, selectedFile } = props;

    return (
        <div className='modal-group'>
            <div
                className='modal-overlay'
                onClick={() => toggleModal()}
            />

            <div className='modal-container preview'>
                <div className='modal'>
                    <div className='titles scroll-shadows'>
                        <h3>{selectedFile?.name}</h3>
                        <p>Preview your invoice file, either pdf or image</p>
                    </div>

                    <div
                        className='modal-content'
                    >
                        <img src={selectedFile?.preview} />
                    </div>
                </div>

            </div>
        </div>
    )
}