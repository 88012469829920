import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCostDifferenceMonthlySeries,
  getCostDifferenceYearlySeries,
  getTeamCostsAndLicenceCountByYearSeries,
  getTeamCostsEvolution,
  getTeamYearlyCostsAndLicenceCountSeries,
  getTrackedSoftwareCostsAndLicenceCountByYearSeries,
  getTrackedSoftwareYearlyCostsAndLicenceCountSeries,
} from '../../../services/analytics/get';
import {
  getTeamCostsAndLicenceCountByYear,
  getTeamYearlyCostsAndLicenceCount,
} from '../../../services/api/team/fetch';
import {
  getAllTrackedSoftwareYearlyCostsAndLicenceCount,
  getMonthlyCostsByDate,
  getTrackedSoftwareCostsAndLicenceCountByYear,
} from '../../../services/api/teamsoftware/fetch';

export const getTrackedSoftwareCostsAndLicenceCountByYearAction =
  createAsyncThunk(
    'analytics/getTrackedSoftwareCostsAndLicenceCountByYear',
    async (year, { rejectWithValue }) => {
      try {
        
        year ||= new Date().getFullYear();

        const trackedSoftwareCostsAndLicenceCountByYear =
          await getTrackedSoftwareCostsAndLicenceCountByYear(year);

        const {
          costHistoryMonthlySeries,
          nbLicencesMonthlySeries,
          allSaasCostsMonthlySeries,
          seatsLicencesGlobalMonthlySeries,
        } = await getTrackedSoftwareCostsAndLicenceCountByYearSeries(
          trackedSoftwareCostsAndLicenceCountByYear,
          year
        );

        const costDifferenceMonthlySeries = getCostDifferenceMonthlySeries(
          allSaasCostsMonthlySeries
        );

        return {
          costHistoryMonthlySeries,
          nbLicencesMonthlySeries,
          allSaasCostsMonthlySeries,
          seatsLicencesGlobalMonthlySeries,
          costDifferenceMonthlySeries,
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const getAllTrackedSoftwareYearlyCostsAndLicenceCountAction =
  createAsyncThunk(
    'analytics/getAllTrackedSoftwareYearlyCostsAndLicenceCount',
    async (lastXYears, { rejectWithValue }) => {
      try {
        const trackedSoftwareYearlyCostsAndLicenceCount =
          await getAllTrackedSoftwareYearlyCostsAndLicenceCount(lastXYears);

        const {
          costHistoryYearlySeries,
          nbLicencesYearlySeries,
          allSaasCostsYearlySeries,
          seatsLicencesGlobalYearlySeries,
        } = await getTrackedSoftwareYearlyCostsAndLicenceCountSeries(
          trackedSoftwareYearlyCostsAndLicenceCount
        );

        const costDifferenceYearlySeries = getCostDifferenceYearlySeries(
          allSaasCostsYearlySeries
        );

        return {
          costHistoryYearlySeries,
          nbLicencesYearlySeries,
          allSaasCostsYearlySeries,
          seatsLicencesGlobalYearlySeries,
          costDifferenceYearlySeries,
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const getTeamCostsAndLicenceCountByYearAction = createAsyncThunk(
  'analytics/getTeamCostsAndLicenceCountByYear',
  async (year, { rejectWithValue }) => {
    try {
      
      year ||= new Date().getFullYear();

      const teamCostsAndLicenceCountByYear =
        await getTeamCostsAndLicenceCountByYear(year);

      const teamCostsEvolution = await getTeamCostsEvolution(
        teamCostsAndLicenceCountByYear
      );

      const {
        saasCostsPerTeamMonthlySeries,
        seatsLicencesPerTeamMonthlySeries,
      } = getTeamCostsAndLicenceCountByYearSeries(
        teamCostsAndLicenceCountByYear,
        year
      );

      return {
        saasCostsPerTeamMonthlySeries,
        seatsLicencesPerTeamMonthlySeries,
        teamCostsEvolution,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTeamYearlyCostsAndLicenceCountAction = createAsyncThunk(
  'analytics/getTeamYearlyCostsAndLicenceCount',
  async (lastXYears, { rejectWithValue }) => {
    try {
      const teamYearlyCostsAndLicenceCount =
        await getTeamYearlyCostsAndLicenceCount(lastXYears);

      const { saasCostsPerTeamYearlySeries, seatsLicencesPerTeamYearlySeries } =
        getTeamYearlyCostsAndLicenceCountSeries(teamYearlyCostsAndLicenceCount);

      return {
        saasCostsPerTeamYearlySeries,
        seatsLicencesPerTeamYearlySeries,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTrackedTeamMonthlyCostsByDateAction = createAsyncThunk(
  'analytics/getTrackedTeamMonthlyCostsByDate',
  async (date, { rejectWithValue }) => {
    try {
      const trackedTeamMonthlyCosts = await getMonthlyCostsByDate(date);
      const { costs } = trackedTeamMonthlyCosts;

      return { date, costs };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
