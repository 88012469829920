import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/team';

export const getAllTeams = async () => {
  try {
    const response = await axios.get(`${prefix}/all`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamById = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/${teamId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamLicences = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/${teamId}/licences`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamLicencesInPeriod = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };
    const params = { from, to };

    const response = await axios.post(
      `${prefix}/${teamId}/licencesInPeriod`,
      body,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getPendingUsersByTeam = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/${teamId}/pending`);

    return response.data;
  } catch (error) {
    console.log(error);
    handleErrorResponse(error);
  }
};

export const getTeamSoftware = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/${teamId}/teamSoftware`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamSoftwareInPeriod = async (teamId, from, to) => {
  try {
    const params = { from, to };

    const response = await axios.post(
      `${prefix}/${teamId}/teamSoftwareInPeriod`,
      null,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getAvailableSoftware = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/${teamId}/availableSoftware`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMultipleTeamsAvailableSoftware = async (teamIds) => {
  try {
    const response = await axios.post(
      `${prefix}/multipleTeamsAvailableSoftware`,
      { teamIds }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getNewSoftwareForLicences = async (teamId, userIds) => {
  try {
    const body = { userIds };

    const response = await axios.post(
      `${prefix}/${teamId}/addUsers/getSoftwareForLicences`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamExpenses = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { from, to };

    const response = await axios.post(`${prefix}/${teamId}/getExpenses`, body, {
      params,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamMonthlyCosts = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${teamId}/getMonthlyCosts`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamMonthlyCostsByDate = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  date
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };
    const params = { date };

    const response = await axios.post(
      `${prefix}/${teamId}/getMonthlyCostsByDate`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamCostsAndLicenceCountByYear = async (year) => {
  try {
    const response = await axios.get(
      `${prefix}/getTeamCostsAndLicenceCountByYear`,
      {
        params: { year },
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamYearlyCostsAndLicenceCount = async (lastXYears) => {
  try {
    const response = await axios.get(
      `${prefix}/getTeamYearlyCostsAndLicenceCount`,
      {
        params: { lastXYears },
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamMonthlyCostsDifference = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  date1,
  date2
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };
    const params = { date1, date2 };

    const response = await axios.post(
      `${prefix}/${teamId}/getMonthlyCostsDifference`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamMonthlyCostsDifferenceByYear = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  year1,
  year2
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { year1, year2 };

    const response = await axios.post(
      `${prefix}/${teamId}/getMonthlyCostsDifferenceByYear`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamCostsForecast = async (
  teamId,
  excludedTeamSoftwareIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      excludedTeamSoftwareIds,
      excludedSoftwareIds,
    };

    const params = { from, to };

    const response = await axios.post(
      `${prefix}/${teamId}/getCostsForecast`,
      body,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
