import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { markNotificationAsSeen } from '../../redux/features/events/eventsSlice';
import AddSoftwareModal from '../Modals/Add Software/AddSoftwareModal';
import NotificationActions from './Rendering/NotificationActions';
import NotificationDescription from './Rendering/NotificationDescription';

export default function NotificationRenderer(props) {
  const dispatch = useDispatch();

  const { notification, isMock, setIsNotificationMenuOpen } = props;

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewTeamClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const teamId = notification?.metaData?.team?._id;
    if (teamId) {
      navigate(notification?.link);
    }
  };

  const toggleAddSoftwareModal = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(value);
  };

  const handleNotificationClick = () => {
    dispatch(markNotificationAsSeen(notification?._id));
    setIsNotificationMenuOpen(false); // Close the notification panel
  };

  return (
    <Link
      to={notification?.link || '#'}
      className='notification-link'
      onClick={handleNotificationClick}
    >
      <div className='notification'>
        {!notification?.isSeen && <span className='notification-dot' />}
        <div
          className='user-image'
          style={{ backgroundImage: notification.thumbnail ? `url(${notification.thumbnail})` : '' }}
        >
          {isMock && (
            <Skeleton
              circle={true}
              height={40}
              width={40}
              baseColor='#333'
              highlightColor='#444'
            />
          )}
        </div>
        <div className='notification-content'>
          {isMock ? (
            <>
              <Skeleton
                width={200}
                baseColor='#333'
                highlightColor='#444'
              />
              <Skeleton
                width={150}
                baseColor='#333'
                highlightColor='#444'
              />
            </>
          ) : (
            <>
              <NotificationDescription notification={notification} />
              <div className='timestamp'>{notification?.timestamp}</div>
              <div className='actions'>
                <NotificationActions
                  notification={notification}
                  handleViewTeamClick={handleViewTeamClick}
                  toggleAddSoftwareModal={toggleAddSoftwareModal}
                />
                {isModalOpen && (
                  <AddSoftwareModal toggleModal={toggleAddSoftwareModal} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
