import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MemberCard from '../../UI/MemberCard';

export default function SetManagerStep({
  selectedUsers,
  selectedManager,
  setSelectedManager,
}) {
  
  const { company } = useSelector((state) => state.company);

  const users = useMemo(() => {
    return company?.users
      .filter((user) => selectedUsers[user._id])
      .map((user) => {
        return {
          ...user,
        };
      });
  }, [company, selectedUsers]);

  useEffect(() => {
    if (users.length === 1) {
      setSelectedManager(users[0]._id);
    }
  }, [users, setSelectedManager]);

  const handleManagerClick = (userId) => {
    setSelectedManager(userId);
  };

  const renderMemberCards = () => {
    return users.map((user) => (
      <MemberCard
        key={user?._id}
        member={user}
        onClick={() => handleManagerClick(user?._id)}
        isSelected={selectedManager === user?._id}
      />
    ));
  };

  return (
    <>
      <div className='titles'>
        <p>Select Team Manager</p>
      </div>
      <div className='members-cards-container'>{renderMemberCards()}</div>
    </>
  );
}
