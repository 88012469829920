import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamCostsAndLicenceCountByYearAction,
  getTrackedSoftwareCostsAndLicenceCountByYearAction,
} from '../../redux/features/analytics/analyticsThunks';
import {
  deleteLicenceAction,
  getDynamicTrackedSoftwareDataByTeamAction,
  getTrackedSoftwareDataAction,
} from '../../redux/features/software/softwareThunks';
import { getManagedTeamsAndMonthlyCostsAction } from '../../redux/features/teams/teamsThunks';

export const useApplicationUserDropdown = (user) => {
  const dispatch = useDispatch();

  
  const prevState = useSelector((state) => state.software);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => setIsModalOpen(false));

  const toggleModal = useCallback((value) => {
    setIsModalOpen(value);
  }, []);

  const onDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setMessage('Are you sure you want to delete this licence?');
      setValue('delete');

      const onConfirm = () => {
        dispatch(
          
          deleteLicenceAction({
            softwareId: user?.software,
            teamId: user?.team,
            licenceId: user?.licenceId,
            prevState,
          })
          
        ).finally(() => {
          
          dispatch(getTrackedSoftwareDataAction());
          
          dispatch(getDynamicTrackedSoftwareDataByTeamAction());
          
          dispatch(getManagedTeamsAndMonthlyCostsAction());
          
          dispatch(getTeamCostsAndLicenceCountByYearAction());
          
          dispatch(getTrackedSoftwareCostsAndLicenceCountByYearAction());
        });
      };

      setOnConfirm(() => onConfirm);

      toggleModal(true);
    },
    [dispatch, user, prevState, toggleModal]
  );

  const dropdownOptions = [
    {
      value: 'delete',
      onClick: (e) => onDelete(e),
      icon: 'bx bx-trash',
    },
  ];

  return {
    dropdownOpen,
    isModalOpen,
    message,
    value,
    onConfirm,
    setDropdownOpen,
    dropdownOptions,
    toggleModal,
  };
};
