import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/team';

export const addTeam = async (body) => {
  try {
    const response = await axios.post(`${prefix}`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const createAndAssignTeamsToCompany = async (companyId, teams) => {
  try {
    const body = {
      teams,
    };

    const response = await axios.post(
      `${prefix}/assignTeams/${companyId}`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateTeam = async (teamId, { name, email, description }) => {
  try {
    const response = await axios.put(`${prefix}/${teamId}`, {
      name,
      email,
      description,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const setTeamManager = async (teamId, managerId) => {
  try {
    const body = {
      managerId,
    };

    const response = await axios.put(`${prefix}/${teamId}/manager`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addTeamSoftware = async (teamId, softwareId, paymentDetails) => {
  try {
    const body = { softwareId, paymentDetails };

    const response = await axios.post(
      `${prefix}/${teamId}/addTeamSoftware`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addMultipleSoftwareToMultipleTeams = async (body) => {
  try {
    const response = await axios.post(
      `${prefix}/addMultipleSoftwareToMultipleTeams`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const addUsersToTeam = async (teamId, userIds) => {
  try {
    const response = await axios.post(`${prefix}/${teamId}/addUsers`, {
      userIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateTeamLicences = async (teamId, teamSoftwareIds) => {
  try {
    const body = {
      teamSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${teamId}/deactivateLicences`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const renewTeamLicences = async (teamId, teamSoftwareIds) => {
  try {
    const body = {
      teamSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${teamId}/renewLicences`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deactivateTeamSoftware = async (teamId, teamSoftwareIds) => {
  try {
    const body = {
      teamSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${teamId}/deactivateTeamSoftware`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const activateTeamSoftware = async (teamId, teamSoftwareIds) => {
  try {
    const body = {
      teamSoftwareIds,
    };

    const response = await axios.post(
      `${prefix}/${teamId}/activateTeamSoftware`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const removeUsers = async (teamId, userIds) => {
  try {
    const response = await axios.post(`${prefix}/${teamId}/removeUsers`, {
      userIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const removeUsersFromMultipleTeams = async (userIds) => {
  try {
    const response = await axios.post(`${prefix}/multiple/removeUsers`, {
      userIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const deleteTeam = async (teamId) => {
  try {
    const response = await axios.delete(`${prefix}/${teamId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
