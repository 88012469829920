import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/teamSoftware';

export const getAllTeamSoftware = async () => {
  try {
    const response = await axios.get(`${prefix}/all`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamSoftwareById = async (teamSoftwareId) => {
  try {
    const response = await axios.get(`${prefix}/${teamSoftwareId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTrackedSoftwareData = async () => {
  try {
    const response = await axios.get(`${prefix}/getTrackedSoftwareData`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTrackedSoftwareCostsAndLicenceCountByYear = async (year) => {
  try {
    const response = await axios.get(
      `${prefix}/getTrackedSoftwareCostsAndLicenceCountByYear`,
      {
        params: { year },
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getAllTrackedSoftwareYearlyCostsAndLicenceCount = async (
  lastXYears
) => {
  try {
    const response = await axios.get(
      `${prefix}/getAllTrackedSoftwareYearlyCostsAndLicenceCount`,
      {
        params: { lastXYears },
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMonthlyCostsByDate = async (date) => {
  try {
    const response = await axios.get(`${prefix}/getMonthlyCostsByDate`, {
      params: { date },
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getDynamicTrackedSoftwareDataByTeam = async () => {
  try {
    const response = await axios.get(
      `${prefix}/getDynamicTrackedSoftwareDataByTeam`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
