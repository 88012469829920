import React, { useEffect, useRef, useState } from 'react';

export default function SelectInput({ options, placeholder, onChange, value, name }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value || placeholder || 'Select an option');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(prevState => !prevState);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        if (onChange) handleOnChange(option);
    };

    const handleOnChange = (option) => {
        if (name) {
            onChange({ name: name, value: option?.value || option });
        } else {
            onChange(option);
        }
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
    
      useEffect(() => {
        getInitialValue()
        
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        setIsOpen((prevState) => {
            return false;
        });
    }, [selectedOption]);

    const getIsSelected = (option) => {
        if (option?.value) {
            return selectedOption?.value === option?.value
        } else {
            return selectedOption === option;
        }
    }

    const getInitialValue = () => {
        if (value) {
            const selected = options.find(option => option?.value === value);
            if (selected) {
                setSelectedOption(selected);
            }
        }
    }

    return (
        <div className={"text-input selection" + (isOpen ? ' open' : '')} onClick={toggleDropdown} ref={dropdownRef}>
            <div className="selected-option">{selectedOption?.name || selectedOption}</div>
            <div className='icon'>
                <i className='bx bx-chevron-down' />
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={"dropdown-item" + (getIsSelected(option) ? ' selected' : '')}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option?.name || option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
