import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getLocalStorageState,
  updateLocalStorageState,
} from '../util/localStorage';

const localStorageKey =
  process.env.REACT_APP_ADD_SOFTWARE_MODAL_LOCAL_STORAGE_KEY ||
  'addSoftwareModal';

export const useSoftwareData = () => {
  const {
    availableSoftwareIds,
    excludedSoftwareIds,
    staticSoftwareData,
    allDynamicSoftwareData,
  } = useSelector(
    
    (state) => state.software
  );

  const combinedSoftwareData = useMemo(() => {
    return Object.keys(staticSoftwareData).reduce((acc, key) => {
      acc[key] = {
        ...staticSoftwareData[key],
        ...allDynamicSoftwareData[key],
      };

      return acc;
    }, {});
  }, [staticSoftwareData, allDynamicSoftwareData]);

  const existingSoftwareList = useMemo(() => {
    return excludedSoftwareIds.map(
      (excludedSoftwareId) => combinedSoftwareData[excludedSoftwareId]
    );
  }, [excludedSoftwareIds, combinedSoftwareData]);

  const softwareList = useMemo(() => {
    return [
      ...availableSoftwareIds.map(
        (softwareId) => combinedSoftwareData[softwareId]
      ),
      ...existingSoftwareList,
    ];
  }, [availableSoftwareIds, combinedSoftwareData, existingSoftwareList]);

  return { softwareList, existingSoftwareList };
};

export const useTeamsData = () => {
  
  const { teamIds, teamData } = useSelector((state) => state.teams);

  const teams = useMemo(() => {
    return teamIds.map((id) => teamData[id]).filter((team) => team.name !== '_providerSoftware');
  }, [teamIds, teamData]);

  return { teams, teamData, teamIds };
};

export const usePaymentDetails = () => {
  const localStorageState = getLocalStorageState(localStorageKey);
  const [paymentDetails, setPaymentDetails] = useState(
    localStorageState.paymentDetails || {}
  );

  useEffect(() => {
    updateLocalStorageState(localStorageKey, { paymentDetails });
  }, [paymentDetails]);

  const handleTierChange = (softwareId, teamId, tier) => {
    setPaymentDetails((prevState) => ({
      ...prevState,
      [softwareId]: {
        ...prevState[softwareId],
        [teamId]: {
          ...prevState[softwareId]?.[teamId],
          tier,
        },
      },
    }));
  };

  const handlePaymentTypeChange = (softwareId, teamId, paymentType) => {
    setPaymentDetails((prevState) => ({
      ...prevState,
      [softwareId]: {
        ...prevState[softwareId],
        [teamId]: {
          ...prevState[softwareId]?.[teamId],
          paymentType,
        },
      },
    }));
  };

  return {
    paymentDetails,
    setPaymentDetails,
    handleTierChange,
    handlePaymentTypeChange,
  };
};

export const useCheckedUsers = () => {
  const localStorageState = getLocalStorageState(localStorageKey);
  const [checkedUsers, setCheckedUsers] = useState(
    localStorageState.checkedUsers || {}
  );

  useEffect(() => {
    updateLocalStorageState(localStorageKey, { checkedUsers });
  }, [checkedUsers]);

  const toggleUserCheck = (softwareId, userId) => {
    setCheckedUsers((prevState) => ({
      ...prevState,
      [softwareId]: {
        ...prevState[softwareId],
        [userId]: !prevState[softwareId]?.[userId],
      },
    }));
  };

  return { checkedUsers, toggleUserCheck };
};

export const useUserToTeams = () => {
  const localStorageState = getLocalStorageState(localStorageKey);
  const [userToTeams, setUserToTeams] = useState(
    localStorageState.userToTeams || {}
  );

  useEffect(() => {
    updateLocalStorageState(localStorageKey, { userToTeams });
  }, [userToTeams]);

  const handleTeamChange = (softwareId, userId, teamId) => {
    setUserToTeams((prevState) => ({
      ...prevState,
      [softwareId]: {
        ...prevState[softwareId],
        [userId]: teamId,
      },
    }));
  };

  return { userToTeams, handleTeamChange };
};
