import React from 'react';

export default function NotificationReactions({ notification }) {
  if (!notification?.reactions?.length) {
    return null;
  }

  return notification?.reactions?.map((reaction, index) => (
    <div
      key={index}
      className='reaction'
    >
      <div
        className='reaction-icon'
        style={{ backgroundImage: `url(${reaction?.icon})` }}
      />
      <div>{reaction?.users?.length}</div>
    </div>
  ));
}
