import handleErrorResponse from '../../../util/handleErrorResponse';
import axios from '../axiosConfig';

const prefix = '/user';

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${prefix}/${userId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getMultipleUserData = async (userIds) => {
  try {
    const response = await axios.post(`${prefix}/getMultipleUserData`, {
      userIds,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserWidgetArray = async () => {
  try {
    const response = await axios.get(`${prefix}/widgets`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamsByUser = async (userId) => {
  try {
    const response = await axios.get(`${prefix}/teams/${userId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getTeamData = async (teamId) => {
  try {
    const response = await axios.get(`${prefix}/teams/getData/${teamId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getManagedTeamsByUser = async (userId) => {
  try {
    const response = await axios.get(`${prefix}/managedTeams/${userId}`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getManagedTeamsAndMonthlyCosts = async () => {
  try {
    const response = await axios.get(
      `${prefix}/getManagedTeamsAndMonthlyCosts`
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getSoftwareForLicences = async (userId, teamIds) => {
  try {
    const body = {
      teamIds,
    };

    const response = await axios.post(
      `${prefix}/${userId}/joinTeams/getSoftwareForLicences/admin`,
      body
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserLicencesInPeriod = async (userIds, from, to) => {
  try {
    const params = { from, to };

    const response = await axios.post(
      `${prefix}/licencesInPeriod`,
      { userIds },
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserExpenses = async (
  userIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };
    const params = { from, to };

    const response = await axios.post(`${prefix}/getExpenses`, body, {
      params,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserMonthlyCosts = async (userIds, excludedSoftwareIds) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };

    const response = await axios.post(`${prefix}/getMonthlyCosts`, body);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserMonthlyCostsByDate = async (
  userIds,
  excludedSoftwareIds,
  date
) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };
    const params = { date };

    const response = await axios.post(`${prefix}/getMonthlyCostsByDate`, body, {
      params,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserMonthlyCostsDifference = async (
  userIds,
  excludedSoftwareIds,
  date1,
  date2
) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };
    const params = { date1, date2 };

    const response = await axios.post(
      `${prefix}/getMonthlyCostsDifference`,
      body,
      { params }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserMonthlyCostsDifferenceByYear = async (
  userIds,
  excludedSoftwareIds,
  year1,
  year2
) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };
    const params = { year1, year2 };

    const response = await axios.post(
      `${prefix}/getMonthlyCostsDifferenceByYear`,
      body,
      { params }
    );

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserCostsForecast = async (
  userIds,
  excludedSoftwareIds,
  from,
  to
) => {
  try {
    const body = {
      userIds,
      excludedSoftwareIds,
    };
    const params = { from, to };

    const response = await axios.post(`${prefix}/getCostsForecast`, body, {
      params,
    });

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getUserProfileData = async (userId) => {
  try {
    const response = await axios.get(`${prefix}/${userId}/getUserProfileData`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const updateToken = async () => {
  try {
    const response = await axios.post(`${prefix}/updateToken`);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
