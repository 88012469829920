import { createSlice } from '@reduxjs/toolkit';
import updateTeamDataObject from '../../../services/teams/updateTeamDataObject';
import updateTeamStateData from '../../../services/teams/updateTeamStateData';
import formateLinkName from '../../../util/formatToSlug';
import { getTeamCostsAndLicenceCountByYearAction } from '../analytics/analyticsThunks';
import {
  addUsersToTeamAction,
  createUserFromInviteAction,
  deleteTeamAction,
  getManagedTeamsAndMonthlyCostsAction,
  getPendingUsersByTeamAction,
  removeUsersAction,
  removeUsersFromMultipleTeamsAction,
  updateTeamAction,
} from './teamsThunks';

const initialState = {
  teamIds: [],
  teamSlugToId: {},
  teamData: {},
  userIds: [],
  pendingUsersByTeam: {},
  error: null,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        getManagedTeamsAndMonthlyCostsAction.fulfilled,
        (state, action) => {
          updateTeamStateData(state, action.payload);
        }
      )
      .addCase(
        getTeamCostsAndLicenceCountByYearAction.fulfilled,
        (state, action) => {
          const { teamCostsEvolution } = action.payload;
          updateTeamDataObject(state, teamCostsEvolution);
        }
      )
      .addCase(addUsersToTeamAction.pending, (state, action) => {
        
        const { teamId, userIds } = action.meta.arg;

        const addedUsers = userIds.map((userId) => ({
          userId,
          isActive: true,
        }));

        state.teamData[teamId].users = [
          ...state.teamData[teamId].users,
          ...addedUsers,
        ];

        state.userIds = [...state.userIds, ...userIds];
      })
      .addCase(addUsersToTeamAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.teamData = prevState.teamData;
      })
      .addCase(deleteTeamAction.pending, (state, action) => {
        
        const { teamId } = action.meta.arg;

        delete state.teamSlugToId[formateLinkName(state.teamData[teamId].name)];
        delete state.teamData[teamId];

        state.teamIds = state.teamIds.filter((id) => id !== teamId);
      })
      .addCase(deleteTeamAction.rejected, (state, action) => {
        
        const { prevTeamsState } = action.meta.arg || '';

        state.teamData = prevTeamsState.teamData;
        state.teamIds = prevTeamsState.teamIds;
        state.teamSlugToId = prevTeamsState.teamSlugToId;

        state.error = action.payload;
      })
      .addCase(updateTeamAction.pending, (state, action) => {
        
        const { teamId, name, email, description } = action.meta.arg;

        state.teamData[teamId] = {
          ...state.teamData[teamId],
          name,
          email,
          description,
        };

        delete state.teamSlugToId[formateLinkName(state.teamData[teamId].name)];

        state.teamSlugToId[formateLinkName(name)] = teamId;
      })
      .addCase(updateTeamAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.teamData = prevState.teamData;
        state.teamSlugToId = prevState.teamSlugToId;

        state.error = action.payload;
      })
      .addCase(removeUsersAction.pending, (state, action) => {
        
        const { teamId, userIds } = action.meta.arg;

        const userIdsSet = new Set(userIds);

        state.teamData[teamId].users = state.teamData[teamId]?.users?.filter(
          (user) => !userIdsSet.has(user.userId)
        );
      })
      .addCase(removeUsersAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.teamData = prevState.teamData;
      })
      .addCase(removeUsersFromMultipleTeamsAction.pending, (state, action) => {
        
        const { userIds } = action.meta.arg;

        const userIdsSet = new Set(userIds);

        Object.keys(state.teamData).forEach((teamId) => {
          state.teamData[teamId].users = state.teamData[teamId]?.users?.filter(
            (user) => !userIdsSet.has(user.userId)
          );
        });

        state.userIds = state.userIds.filter(
          (userId) => !userIdsSet.has(userId)
        );
      })
      .addCase(removeUsersFromMultipleTeamsAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.teamData = prevState.teamData;

        state.userIds = prevState.userIds;
      })
      .addCase(getPendingUsersByTeamAction.fulfilled, (state, action) => {
        
        const teamId = action.meta.arg || '';
        const { pendingUsers } = action.payload;

        if (!state.pendingUsersByTeam[teamId]) {
          state.pendingUsersByTeam[teamId] = [];
        }

        state.pendingUsersByTeam[teamId] = pendingUsers;
      })
      .addCase(createUserFromInviteAction.pending, (state, action) => {
        
        const { userId, teamId } = action.meta.arg;

        if (!state.pendingUsersByTeam[teamId]) {
          state.pendingUsersByTeam[teamId] = [];
        }

        state.pendingUsersByTeam[teamId].push(userId);
      })
      .addCase(createUserFromInviteAction.rejected, (state, action) => {
        
        const { prevState } = action.meta.arg;

        state.pendingUsersByTeam = prevState.pendingUsersByTeam;
      }),
});

export default teamsSlice.reducer;
