export const getFormattedInvoices = (invoices) => {
  const formattedInvoices = invoices.map((invoice) => {
    let preview = invoice.file;

    if (invoice?.file) {
      const fileExtension = invoice.file.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        preview = invoice.file.replace(
          '/upload/',
          '/upload/pg_1,f_auto,q_auto/'
        );
      }
    }

    return {
      ...invoice,
      preview,
    };
  });

  return formattedInvoices;
};

export const getPreviewInvoice = (invoice) => {
  if (invoice?.file) {
    let preview = invoice.file;

    const fileExtension = invoice.file.split('.').pop().toLowerCase();
    if (fileExtension === 'pdf') {
      preview = invoice.file.replace(
        '/upload/',
        '/upload/pg_1,f_auto,q_auto/'
      );
    }

    return preview
  }
};
