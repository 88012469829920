import React, { useMemo, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPaginate from 'react-paginate';
import AddUsersModal from '../Modals/Add User/AddUsersModal';
import AddUsersToTeamModal from '../Modals/Add User/AddUsersToTeam';
import UserRow from './UserRow';

const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 10;

const fakeUsers = Array.from({ length: itemsPerPage }, (_, index) => ({
  id: index,
}));

export default function UsersTable(props) {
  const { users, teamId, isLoading } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddUsersToTeamModalOpen, setIsAddUsersToTeamModalOpen] =
    useState(false);

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex.selected);
  };

  const toggleModal = (value) => {
    if (teamId) {
      setIsAddUserModalOpen(value);
    } else {
      setIsAddUsersToTeamModalOpen(value);
    }
  };

  const startIndex = currentPage * itemsPerPage;

  const displayedUsers = useMemo(() => {
    return isLoading
      ? fakeUsers
      : users?.slice(startIndex, startIndex + itemsPerPage) || [];
  }, [isLoading, users, startIndex]);

  return (
    <div className='table-container'>
      <div className='table'>
        <div className='table-header'>
          <div>
            Name{''}
            <i className='bx bx-sort-alt-2'></i>
          </div>
          <div>
            Email{''}
            <i className='bx bx-sort-alt-2' />
          </div>
          <div>
            Monthly cost{''}
            <i className='bx bx-sort-alt-2' />
          </div>
          <div>
            Applications activity{''}
            <i className='bx bx-sort-alt-2' />
          </div>
          <div>
            Extension{''}
            <i className='bx bx-sort-alt-2' />
          </div>
        </div>
        <div className='body'>
          {displayedUsers.map((user, index) => (
            <UserRow
              key={user.userId}
              user={user}
              teamId={teamId}
              index={index}
            />
          ))}

          <div
            className='row add'
            onClick={() => toggleModal(true)}
          >
            <div
              className='element plus-sign'
              style={{ width: '30px' }}
            >
              <i className='bx bx-plus'></i>
            </div>
          </div>
        </div>
      </div>

      <div className='pagination-container'>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          pageRangeDisplayed={2}
          pageCount={Math.ceil(users?.length / itemsPerPage)}
          previousLabel='<'
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
        />
      </div>

      {isAddUserModalOpen && (
        <AddUsersModal
          toggleModal={setIsAddUserModalOpen}
          teamId={teamId}
        />
      )}

      {isAddUsersToTeamModalOpen && (
        <AddUsersToTeamModal toggleModal={setIsAddUsersToTeamModalOpen} />
      )}
    </div>
  );
}
