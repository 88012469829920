import React, { useState } from 'react';
import {
  formatTierName,
  getPaymentTypeValue,
} from '../../../services/software/format';

export default function UpdateTeamModal(props) {
  const { message, value, onConfirm, toggleModal, initialDetails, pricesData } =
    props;
  const [details, setDetails] = useState(initialDetails);

  const onCancel = () => {
    toggleModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const renderContent = () => {
    let prices, tierToName;

    if (pricesData) {
      prices = pricesData.prices;
      tierToName = pricesData.tierToName;
    }

    switch (value) {
      case 'delete software':
        return <p className='titles'>{message}</p>;

      case 'edit software':
        return (
          <form>
            <div className='titles'>
              <h3>Edit Software Details</h3>
            </div>
            <div>
              <label htmlFor='tier'>Tier</label>
              <select
                id='tier'
                name='tier'
                value={details.tier}
                onChange={handleChange}
              >
                {Object.keys(prices).map((tier) => (
                  <option
                    key={tier}
                    value={tier}
                  >
                    {formatTierName(tierToName[tier])}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor='paymentType'>Payment Type</label>
              <select
                id='paymentType'
                name='paymentType'
                value={details.paymentType}
                onChange={handleChange}
              >
                {Object.keys(prices[details.tier] || {}).map((paymentType) => (
                  <option
                    key={paymentType}
                    value={paymentType}
                  >
                    {getPaymentTypeValue(paymentType)}
                  </option>
                ))}
              </select>
            </div>
          </form>
        );

      default:
        return;
    }
  };

  return (
    <div className='modal-group'>
      <div
        className='modal-overlay'
        onClick={() => toggleModal(false)}
      />

      <div className='modal-container confirmation'>
        <div className='modal'>
          {renderContent()}
          <div className='next-btn-container'>
            <button
              className='next-btn previous'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='next-btn'
              onClick={() => onConfirm(details)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
