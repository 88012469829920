import React from 'react';

function EmptyCard({ toggleAddWidgetModal, emptyCardIndex }) {
  return (
    <div
      className='empty-card'
      onClick={() => toggleAddWidgetModal(true, emptyCardIndex)}
    >
      <div className='add-button'>
        <div className='plus-sign'>
          <i className='bx bx-plus'></i>
        </div>
        <p>Add widget</p>
      </div>
    </div>
  );
}

export default React.memo(EmptyCard);
