import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { commonOptions } from '../../../util/UtilFunctions';
import DropdownMenu from '../DropdownMenu';

export default function SeatsLicencesPerTeam({
  series,
  teams,
  selectedView,
  height,
}) {
  const [selectedItem, setSelectedItem] = useState(teams[0]);

  useEffect(() => {
    if (teams.length > 0) {
      setSelectedItem(teams[0]);
    }
  }, [teams]);

  const seriesData = useMemo(() => {
    return series?.[selectedView]?.[selectedItem?._id] ?? [];
  }, [series, selectedView, selectedItem]);

  const isLoading = useMemo(() => {
    return !series?.[selectedView];
  }, [series, selectedView]);

  const options = {
    ...commonOptions,
    legend: {
      ...commonOptions.legend,
      itemMargin: {
        horizontal: 10,
      },
      offsetY: 15,
    },
    chart: {
      ...commonOptions.chart,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: () => 'Total Cost',
        },
        formatter: function (_, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ].tooltip;
        },
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 5,
    },
    plotOptions: {
      offsetX: -15,
      bar: {
        borderRadiusApplication: 'end',
        columnWidth: '30%',
        borderRadius: '3',
        barWidth: 10,
      },
    },
    yaxis: {
      ...commonOptions.yaxis,
      labels: {
        style: {
          fontSize: '10px',
          fontFamily: 'Inter',
          fontWeight: 400,
        },
        offsetX: -13,
      },
    },
    grid: {
      ...commonOptions.grid,
      strokeDashArray: 12,
      padding: {
        left: 15,
      },
    },
  };

  if (isLoading) {
    return (
      <Skeleton
        height={height}
        baseColor='#333'
        highlightColor='#444'
      />
    );
  }

  return (
    <>
      <DropdownMenu
        dropdownItems={teams}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <ReactApexChart
        
        options={options}
        series={seriesData}
        type='bar'
        height={height}
      />
    </>
  );
}
