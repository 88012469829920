export const rehydrateUser = () => {
  const user = localStorage.getItem('user');

  return user ? JSON.parse(user) : null;
};

export const rehydrateUserData = () => {
  const userData = localStorage.getItem('userData');

  return userData ? JSON.parse(userData) : {};
};

export const rehydrateUserSlugToId = () => {
  const userSlugToId = localStorage.getItem('userSlugToId');

  return userSlugToId ? JSON.parse(userSlugToId) : {};
};

export const rehydrateToken = () => {
  return localStorage.getItem('token') ?? '';
};
